@font-face {
  font-family: 'SF-Pro-Display-Regular'; /* Имя шрифта, которое будет использоваться в приложении */
  src: url('./fonts/SF-Pro-Display-Regular.otf') format('truetype'); /* Путь к файлу шрифта и его формат */
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'SF-Pro-Display-RegularItalic'; /* Имя шрифта, которое будет использоваться в приложении */
  src: url('./fonts/SF-Pro-Display-RegularItalic.otf') format('truetype'); /* Путь к файлу шрифта и его формат */
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'SF-Pro-Display-UltralightItalic'; /* Имя шрифта, которое будет использоваться в приложении */
  src: url('./fonts/SF-Pro-Display-UltralightItalic.otf') format('truetype'); /* Путь к файлу шрифта и его формат */
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'SF-Pro-Display-Ultralight'; /* Имя шрифта, которое будет использоваться в приложении */
  src: url('./fonts/SF-Pro-Display-Ultralight.otf') format('truetype'); /* Путь к файлу шрифта и его формат */
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'SF-Pro-Display-ThinItalic'; /* Имя шрифта, которое будет использоваться в приложении */
  src: url('./fonts/SF-Pro-Display-ThinItalic.otf') format('truetype'); /* Путь к файлу шрифта и его формат */
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'SF-Pro-Display-Thin'; /* Имя шрифта, которое будет использоваться в приложении */
  src: url('./fonts/SF-Pro-Display-Thin.otf') format('truetype'); /* Путь к файлу шрифта и его формат */
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'SF-Pro-Display-SemiboldItalic'; /* Имя шрифта, которое будет использоваться в приложении */
  src: url('./fonts/SF-Pro-Display-SemiboldItalic.otf') format('truetype'); /* Путь к файлу шрифта и его формат */
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'SF-Pro-Display-Semibold'; /* Имя шрифта, которое будет использоваться в приложении */
  src: url('./fonts/SF-Pro-Display-Semibold.otf') format('truetype'); /* Путь к файлу шрифта и его формат */
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'SF-Pro-Display-MediumItalic'; /* Имя шрифта, которое будет использоваться в приложении */
  src: url('./fonts/SF-Pro-Display-MediumItalic.otf') format('truetype'); /* Путь к файлу шрифта и его формат */
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'SF-Pro-Display-Medium'; /* Имя шрифта, которое будет использоваться в приложении */
  src: url('./fonts/SF-Pro-Display-Medium.otf') format('truetype'); /* Путь к файлу шрифта и его формат */
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'SF-Pro-Display-LightItalic'; /* Имя шрифта, которое будет использоваться в приложении */
  src: url('./fonts/SF-Pro-Display-LightItalic.otf') format('truetype'); /* Путь к файлу шрифта и его формат */
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'SF-Pro-Display-Light'; /* Имя шрифта, которое будет использоваться в приложении */
  src: url('./fonts/SF-Pro-Display-Light.otf') format('truetype'); /* Путь к файлу шрифта и его формат */
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'SF-Pro-Display-HeavyItalic'; /* Имя шрифта, которое будет использоваться в приложении */
  src: url('./fonts/SF-Pro-Display-HeavyItalic.otf') format('truetype'); /* Путь к файлу шрифта и его формат */
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'SF-Pro-Display-Heavy'; /* Имя шрифта, которое будет использоваться в приложении */
  src: url('./fonts/SF-Pro-Display-Heavy.otf') format('truetype'); /* Путь к файлу шрифта и его формат */
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'SF-Pro-Display-BoldItalic'; /* Имя шрифта, которое будет использоваться в приложении */
  src: url('./fonts/SF-Pro-Display-BoldItalic.otf') format('truetype'); /* Путь к файлу шрифта и его формат */
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'SF-Pro-Display-Bold'; /* Имя шрифта, которое будет использоваться в приложении */
  src: url('./fonts/SF-Pro-Display-Bold.otf') format('truetype'); /* Путь к файлу шрифта и его формат */
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'SF-Pro-Display-BlackItalic'; /* Имя шрифта, которое будет использоваться в приложении */
  src: url('./fonts/SF-Pro-Display-BlackItalic.otf') format('truetype'); /* Путь к файлу шрифта и его формат */
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'SF-Pro-Display-Black'; /* Имя шрифта, которое будет использоваться в приложении */
  src: url('./fonts/SF-Pro-Display-Black.otf') format('truetype'); /* Путь к файлу шрифта и его формат */
  font-weight: 900;
  font-style: normal;
}
:root {
  --main-color: #364ed4;
  --border-radius-btn: 0.3125rem;
  --footer-color-nav: #3f5057;
  --footer-color-copy: #939a9f;
}
* {
  outline: 0 !important;
}
* {
  margin: 0;
  padding: 0;
}
*, *::before, *::after {
	 box-sizing: border-box;
}
html,
body {
  height: 100%;
}
body{
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: 'SF-Pro-Display-Regular', sans-serif;
  background-color: #fff;
}
body.preview{
  background-color: #f2f4f5;
}
a{
  text-decoration: none;
}
button{
  cursor: pointer;
  font-family: 'SF-Pro-Display-Regular', sans-serif;
}
ul, ol{
  padding: 0;
  margin: 0;
}
strong{
  font-family: 'SF-Pro-Display-Bold', sans-serif;
}
#root{
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
main{
  flex: 1 0 auto;
}
.wrapper{
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .wrapper {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .wrapper {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .wrapper {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .wrapper {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .wrapper {
    max-width: 1320px;
  }
}
@media (min-width: 1600px) {
  .wrapper {
    max-width: 1500px;
  }
}
.btn-edit{
  width: 44px;
  height: 44px;
  border-radius: 5px;
  background-color: #f1f3ff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}
.btn-edit_theme_remove{
  background-color: #fae6ed;
}
.btn-edit__icon{
  stroke: #364ed4;
  display: block;
  width: 17px;
  height: 18px;
}
.btn-edit__transparent{
  background: none;
  border: none;
  padding: 0;
  height: auto;
  margin-bottom: 3px;
}
.loader{
  margin: 0 0 2em;
  height: 100px;
  width: 100%;
  text-align: center;
  padding: 1em;
  margin: 0 auto 1em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: top;
}
.loader svg path,
.loader svg rect{
  fill: #364ed4;
}
.team-table__nav-btn .loader,
.catalog-table__nav-btn .loader,
.modal-status__btn .loader{
  width:  17px;
  height: 17px;
  padding: 0;
  margin: 0;
  vertical-align: unset;
}
.team-table__name .tooltips-wrap{
  display: inline-block;
}
@media(max-width: 991px){
  .team-table__name{
    padding-left: 0 !important;
  }
}
.edit-user-form-wrap{
  padding: 5px 10px;
}
@media (min-width: 992px){
  .edit-user-form-wrap{
    padding: 5px 30px;
  }
}
.edit-user-form__radio-item label{
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
}
.edit-user-form__radio-item .check{
  display: block;
  position: absolute;
  border: 1px solid #AAAAAA;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  top: 0;
  left: 0;
	transition: border .25s linear;
	-webkit-transition: border .25s linear;
}

.edit-user-form__radio-item{
  position: relative;
}

.edit-user-form__radio-item input[type=radio]{
  position: absolute;
  visibility: hidden;
}

.edit-user-form__radio-item label{
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
  padding-left: 30px;
  padding-top: 2px;
  font-size: 14px;
}
.activation-verify-btn{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.activation-verify-btn .loader{

}
.activation-verify-btn .loader svg path,
.activation-verify-btn .loader svg rect{
  fill: #364ed4;
}
.activation-verify-btn .loader{
  width:  17px;
  height: 17px;
  padding: 0;
  margin: 0;
  vertical-align: unset;
  margin-left: 10px;
}
.edit-user-form__send{
  color: var(--main-color);
  background: none;
  border: none;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}
.check {
  border: 1px solid #AAAAAA;
}

.check::before {
  display: block;
  position: absolute;
	content: '';
  border-radius: 100%;
  height: 8px;
  width: 8px;
  top: 50%;
	left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
}

.edit-user-form__radio-item input[type=radio]:checked ~ .check {
  border: 1px solid #000;
}

.edit-user-form__radio-item input[type=radio]:checked ~ .check::before{
  background: #000;
}
.field-wrap__date-icon{
  width: 20px;
  height: 20px;
  stroke: #afafaf;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  z-index: 1;
}
#birthday{
  padding-left: 35px;
}
.extraClass {
 font-size: 14px !important;
 pointer-events: auto !important;
 font-weight: normal !important;
 padding: 2px 5px !important;
}
.extraClass:hover {
visibility: visible !important;
opacity: 1 !important;
}
.form-nav{
  margin: 0 -7px;
  display: flex;
  align-items: center;
}
.form-nav__col{
  width: 50%;
  padding: 0 7px;
}
.form-nav__submit{
  position: relative;
}
.form-nav__submit .loader{
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  height: 37px;
  width: 60px;
  text-align: center;
  padding: 1em;
  margin: 0 auto 0;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  vertical-align: top;
}
.form-nav__submit svg path,
.form-nav__submit svg rect{
  fill: #fff;
}
.btn{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px 20px;
  background-color: var(--main-color);
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  border: none;
  font-family: 'SF-Pro-Display-Regular', sans-serif;
}
.btn__icon-add{
  width: 13px;
  height: 13px;
  display: inline-block;
  margin-right: 10px;
  fill: #fff;
}
.btn.btn__border{
  border: 1px solid var(--main-color);
}
.btn_theme_gray{
  background-color: #cecece;
  color: #000;
}
.btn_theme_secondary{
  background-color: #F1F3FF;
  color: #364ED4;
}
.btn_theme_secondary .btn__icon-add{
  fill: #364ED4;
}
.btn_theme_remove{
  background-color: #FAE6ED;
  color: #D01A36;
}
.btn_theme_white{
  background-color: #fff;
  color: #000;
}
.btn_theme_white-transparent{
  border: 1px solid #fff;
  background: none;
  color: #fff;
}
.btn_theme_red{
  background-color: #d01a36;
}
.btn_theme_green{
  background-color: #41b347;
}
.btn_theme_transparent{
  background: none;
  color: var(--main-color);
  border: 1px solid var(--main-color);
}
.btn_size_lg{
  padding: 13px 30px;
  font-size: 16px;
}
.btn_width_100{
  width: 100%;
}
.btn_rounded{
  border-radius: 30px;
}
.btn_company_status{
  display: flex;
  align-items: center;
}
.btn_company_status-icon{
  width: 20px;
  height: 20px;
  display: block;
  margin-right: 7px;
  stroke: #d01a36;
}
.field-wrap__hide-btn{
  background: none;
  border: none;
  position: absolute;
  top: 14px;
  right: 10px;
  z-index: 10;
  cursor: pointer;
}
.field-wrap__hide-icon{
  width: 21px;
  height: 18px;
  fill: #000;
  display: block;
}
.field-wrap{
  color: #000;
  padding-bottom: 15px;
  position: relative;
  font-weight: 500;
}
.field-wrap__select{
  z-index: 11;
}
.field-wrap__smile{
  display: flex;
  align-items: flex-start;
}
.field-wrap__smile .tooltip-info__content{
  left: auto;
  right: -30px;
}
.field-wrap__smile__inner{
  flex: 1;
}
.field-wrap-smile{
  width: 42px;
  height: 42px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  margin-left: 15px;
  position: relative;
}
.field-wrap-smile .tooltip-info{
  position: absolute;
  top: -12px;
  right: -12px;
  z-index: 101;
}
.field-wrap-smile__error{
  border-color: #ff0000;
}
.field-wrap-smile__inner{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  font-size: 30px;
}
.field-wrap-smile img{
  width: 80%;
  height: auto;
}
.emoji-content{
  top: calc(100% + 10px);
  left: auto;
  right: 0;
  position: absolute;
  z-index: 100;
  border: 1px solid #dde1e4;
  border-radius: 5px;
  padding: 5px;
  width: 355px;
  background-color: #fff;
}
.emoji-content__inner{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.emoji-content__item{
  width: 45px;
  height: 45px;
  flex: 0 0 auto;
  border: 1px solid #dde1e4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1px;
  cursor: pointer;
  font-size: 30px;
}
.field-wrap__inner{
  position: relative;
}
.field-wrap__label{
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #808A8E;
}
.field-wrap__desc{
  padding-bottom: 10px;
  font-size: 16px;
  color: #000;
}
.field-wrap__label_theme_gray{
  color: #808a8e;
}
.field-wrap__label ~ .tooltip-info{
  margin-top: -8px;
}
.field-wrap__label-wrap{
  display: flex;
  align-items: center;
  position: relative;
}
.field-wrap__input{
  display: block;
  width: 100%;
  height: 44px;
  border: 1px solid #e1e1e1;
  background-color: #fff;
  padding: 10px;
  font-size: 16px;
  color: #000;
  border-radius: 5px;
  font-family: sans-serif;
  font-weight: 500;
  box-shadow: inset 30px 30px 0px 30px #fff;
}
.field-wrap__input-icon{
  padding-right: 37px;
}
@media(min-width: 768px){
  .field-wrap__input_width_75{
    max-width: 75%;
  }
  .field-wrap__input_width_66{
    max-width: 66%;
  }
  .field-wrap__input_width_50{
    max-width: 50%;
  }
}
::placeholder {
  color: #A7A7A7;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #A7A7A7;
}

::-ms-input-placeholder {
  color: #A7A7A7;
}
.field-wrap__address{
  flex: auto;
}
.field-wrap__input_bg{
  background-color: #f0f0f0;
  border-radius: 20px;
  border: none;
  padding: 0 15px;
}
.field-wrap__input:disabled{
  background-color: #f2f2f2;
  box-shadow: none;
}
.field-wrap__select{
  font-weight: 500;
}
.field-wrap__submit{
  padding-top: 15px;
}
.field-wrap__submit-padding-none{
  padding-top: 0;
}
.field-wrap__submit button,
.login-form__submit button{
  position: relative;
}
@media(max-width: 767px){
  .field-wrap__submit button{
    width: 100%;
  }
}
.field-wrap__submit .loader,
.login-form__submit .loader{
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  height: 37px;
  width: 60px;
  text-align: center;
  padding: 1em;
  margin: 0 auto 0;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  vertical-align: top;
}
.field-wrap__submit svg path,
.field-wrap__submit svg rect,
.login-form__submit svg path,
.login-form__submit svg rect{
  fill: #fff;
}
.field-wrap__submit .field-wrap__submit-send .loader{
  padding-left: 0;
  padding-right: 0;
  width: 25px;
}
.header{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px 0;
  z-index: 99999;
}
.header_theme_transparent{
  background: none;
}
.header__website{
  border-bottom: 1px solid #fff;
}
.header__website .header__inner{
  padding-left: 15px;
  padding-right: 15px;
}
.header__website-overlay{
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  backdrop-filter: blur(10px);
}
.header__account{
  border-bottom: 1px solid #dde1e4;
  background-image: none;
  background-color: #fff;
}
.header__inner{
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 100;
}
.header.header__account .wrapper{
  width: 100%;
  max-width: 100%;
}
.header__logo img{
  display: block;
  height: 24px;
}
.header__nav{
  display: flex;
  align-items: center;
}
.header__language{
  color: #011626;
  font-size: 15px;
  font-weight: 500;
  margin-left: 15px;
  display: none;
  background: none;
  border: none;
  padding: 0;
}
.header__account .header__nav img{
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.login-btn{
  display: flex;
  align-items: center;
  margin-right: 6px;
}
.header__account .login-btn{
  background: none;
}
@media(min-width: 768px){
  .login-btn{
    background-color: #e5e5e5;
  }
}
.login-btn svg{
  width: 25px;
  height: 25px;
  stroke: #000;
  fill: none;
}
.login-btn__name{
  display: none;
  color: #000;
}
.registration-btn{
  display: none;
}
@media(min-width: 992px){
  .registration-btn{
    display: flex;
    align-items: center;
  }
}
.registration-btn svg{
  width: 25px;
  height: 25px;
  stroke: #fff;
  fill: none;
}
.registration-btn__photo{
  width: 25px;
  height: 25px;
  display: block;
  border-radius: 50%;
  margin-right: 10px;
}
.registration-btn__name{
  display: none;
}
.header__collapse-btn{
  background: none;
  border: none;
}
.header__collapse-btn svg{
  width: 23px;
  height: 17px;
}
.mobile-menu__close-btn{
  background: none;
  border: none;
}
.mobile-menu__log-out svg,
.mobile-menu__close-icon{
  width: 20px;
  height: 20px;
  stroke: #000;
  display: block;
}
@keyframes mobileMenu {
 from { transform: translateX(-100%); }
 to { transform: translateX(0); }
}
@keyframes mobileMenuDis {
 from { transform: translateX(0); }
 to { transform: translateX(-100%); }
}

.home-banner{
  background-color: #e6e9f1;
  padding-top: 60px;
  padding-bottom: 15px;
}

.home-banner__headline-wrap{
  padding-bottom: 15px;
}
.home-banner__headline{
  font-size: 24px;
  color: #000;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  font-family: 'SF-Pro-Display-Black';
}
.home-banner__description-wrap{
  padding-bottom: 15px;
}
.home-banner__image{
  max-height: 480px;
  margin: 0 auto;
}
.home-banner__images_position_mob{
  padding-bottom: 15px;
  position: relative;
}
.home-banner__description{
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #000;
}
.home-banner__nav-col:first-child{
  padding-bottom: 10px;
}
.home-banner__btn{
  padding: 15px 0;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #364ed4;
  display: block;
  width: 100%;
}
.home-banner__btn_theme_add-company{
  background-color: #364ed4;
  color: #fff;
}
.home-banner__btn_theme_add-demo{
  background: none;
  color: #364ed4;
}
.home-banner__image{
  max-width: 100%;
  height: auto;
  display: block;
}
.home-banner__images_position_desctop{
  display: none;
  position: relative;
}

.home-banner-wgt{
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 32px 24px 0 rgba(0, 0, 0, 0.08);
  position: absolute;
  padding: 10px 12px;
  opacity: 0;
}
.home-banner-wgt__headline{
  font-size: 16px;
  font-weight: 500;
}
.home-banner-wgt__headline_theme_green{
  color: #00c059;
}
.home-banner-wgt__description{
  font-size: 14px;
  color: #989dae;
  padding-top: 3px;
}
.home-banner-wgt_v2 .home-banner-wgt__img{
  max-width: 125px;
  margin-bottom: 3px;
}
.home-banner-wgt_v2 .home-banner-wgt__headline{
  margin-bottom: 3px;
}
.home-banner-wgt_v2 .home-banner-wgt__description{
  font-size: 14px;
}
.home-banner-wgt_v3{
  text-align: center;
  max-width: 120px;
}
.home-banner-wgt_v3 .home-banner-wgt__headline{
  font-size: 36px;
  color: #00c059;
  font-weight: 400;
}
.home-banner-wgt_v3 .home-banner-wgt__description{
  font-size: 16px;
  font-weight: 500;
  color: #000;
}
.home-banner-wgt__icon{
  margin-left: 20px;
  width: auto;
  height: 40px;
}
.home-banner-wgt__icon.home-banner-wgt__icon_first{
  margin-right: 20px;
  margin-left: 0;
}
.home-banner-wgt__icon_reverse{
  margin-left: 0;
  margin-right: 15px;
}
.home-banner-wgt__inner{
  display: flex;
  align-items: center;
}
.home-banner-wgt_theme_first{
  top: -35px;
  left: -30px;
  animation: animation-first-wgt 1.2s ease forwards;
}
.home-banner-wgt_theme_second{
  top: 25px;
  right: -20px;
  animation: animation-second-wgt 1.2s ease forwards;
}
.home-banner-wgt_theme_third{
  bottom: 25px;
  left: 30px;
  animation: animation-third-wgt 1.2s ease forwards;
}
.home-banner-wgt_theme_four{
  bottom: 0;
  right: 50px;
  animation: animation-four-wgt 1.2s ease forwards;
}
.home-banner-wgt_theme_five{
  top: 180px;
  left: 250px;
  animation: animation-five-wgt 1.2s ease forwards;
}
@media(max-width: 767px){
  .home-banner__images_position_mob{
    padding: 25px;
  }
  .home-banner__images_position_mob .home-banner-wgt{
    padding: 5px;
    border-radius: 5px;
  }
  .home-banner__images_position_mob .home-banner-wgt .home-banner-wgt__inner{
    align-items: flex-start;
  }
  .home-banner__images_position_mob .home-banner-wgt_theme_first{
    top: 10px;
    left: 5px;
    max-width: 130px;
  }
  .home-banner__images_position_mob .home-banner-wgt_theme_second{
    top: 15px;
    right: 10px;
  }
  .home-banner__images_position_mob .home-banner-wgt_theme_third{
    bottom: 30px;
    left: -3px;
  }
  .home-banner__images_position_mob  .home-banner-wgt_theme_four{
    bottom: 50px;
    right: 20px;
  }
  .home-banner__images_position_mob .home-banner-wgt_theme_five{
    top: 130px;
    left: 120px;
  }
  .home-banner__images_position_mob .home-banner-wgt__headline{
    font-size: 14px;
  }
  .home-banner__images_position_mob .home-banner-wgt__description{
    font-size: 12px;
  }
  .home-banner__images_position_mob .home-banner-wgt__icon{
    height: 25px;
  }
  .home-banner__images_position_mob .home-banner-wgt_v3 .home-banner-wgt__headline{
    font-size: 24px;
  }
  .home-banner__images_position_mob .home-banner-wgt_v3 .home-banner-wgt__description{
    font-size: 14px;
  }
  .home-banner__images_position_mob .home-banner-wgt_v2{
    max-width: 85px;
  }
  .home-banner__images_position_mob .home-banner-wgt_v2 .home-banner-wgt__img{
    max-width: 100%;
  }
}

@media(min-width: 768px){
  .home-banner-wgt_theme_first{
    top: 0px;
    left: 0px;
  }
  .home-banner-wgt_theme_second{
    top: 25px;
    right: -50px;
  }
  .home-banner-wgt_theme_third{
    bottom: 15px;
    left: -25px;
  }
  .home-banner-wgt_theme_four{
    bottom: 20px;
    right: 30px;
  }
  .home-banner-wgt_theme_five{
    top: 150px;
    left: 200px;
  }
}

@media(min-width: 992px){
  .home-banner-wgt_theme_first{
    top: -15px;
    left: 0px;
  }
  .home-banner-wgt_theme_second{
    top: 25px;
    right: -50px;
  }
  .home-banner-wgt_theme_third{
    bottom: -45px;
    left: -15px;
  }
  .home-banner-wgt_theme_four{
    bottom: -20px;
    right: -30px;
  }
  .home-banner-wgt_theme_five{
    top: 130px;
    left: 150px;
  }
}

@media(min-width: 1440px){
  .home-banner-wgt_theme_first{
    top: -15px;
    left: 70px;
  }
  .home-banner-wgt_theme_second{
    top: 15px;
    right: 30px;
  }
  .home-banner-wgt_theme_third{
    bottom: -45px;
    left: 70px;
  }
  .home-banner-wgt_theme_four{
    bottom: 20px;
    right: 50px;
  }
  .home-banner-wgt_theme_five{
    top: 180px;
    left: 250px;
  }
}

.out .home-banner-wgt_theme_first{
  animation: animation-first-wgt-out 0.5s ease forwards;
}
.out .home-banner-wgt_theme_second{
  animation: animation-second-wgt-out 0.5s ease forwards;
}
.out .home-banner-wgt_theme_third{
  animation: animation-third-wgt-out 0.5s ease forwards;
}
.out .home-banner-wgt_theme_four{
  animation: animation-four-wgt-out 0.5s ease forwards;
}
.out .home-banner-wgt_theme_five{
  animation: animation-five-wgt-out 0.5s ease forwards;
}
@keyframes animation-first-wgt {
  0% {
    transform: translate(-35px, -20px);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes animation-first-wgt-out {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(-35px, -20px);

    opacity: 0;
  }
}
@keyframes animation-second-wgt {
  0% {
    transform: translate(35px, -20px);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes animation-second-wgt-out {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(35px, -20px);

    opacity: 0;
  }
}
@keyframes animation-third-wgt {
  0% {
    transform: translate(-50px, 30px);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes animation-third-wgt-out {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(-50px, 30px);

    opacity: 0;
  }
}
@keyframes animation-four-wgt {
  0% {
    transform: translate(50px, 30px);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes animation-four-wgt-out {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(50px, 30px);

    opacity: 0;
  }
}
@keyframes animation-five-wgt {
  0% {
    transform: translate(-200px, 0);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes animation-five-wgt-out {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(-200px, 0);
    opacity: 0;
  }
}

@media(min-width: 768px){
  .home-banner{
    padding-top: 100px;
    padding-bottom: 30px;
  }
}

@media(min-width: 992px){
  .home-banner__inner{
    display: flex;
    align-items: center;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .home-banner__col{
    width: 50%;
    flex: 0 0 auto;
  }
  .home-banner__info{
    padding-right: 80px;
  }
  .home-banner__images{
    padding-left: 25px;
  }
  .home-banner__images_position_mob{
    display: none;
  }
  .home-banner__images_position_desctop{
    display: block;
  }
  .home-banner__headline-wrap{
    padding-bottom: 20px;
  }
  .home-banner__description-wrap{
    padding-bottom: 30px;
  }
  .home-banner__nav{
    display: flex;
    align-items: center;
    margin-left: -5px;
    margin-right: -5px;
  }
  .home-banner__nav-col{
    width: 50%;
    flex: 0 0 auto;
    padding-left: 5px;
    padding-right: 5px;
  }
  .home-banner__nav-col:first-child{
    padding-bottom: 0;
  }
}

@media(min-width: 1200px){
  .home-banner__headline{
    font-size: 45px;
  }
  .home-banner__info{
    padding-right: 165px;
  }
  .home-banner__btn{
    padding: 20px 0;
  }
}

.main-headline{
  font-size: 24px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  font-family: 'SF-Pro-Display-Black';
}
@media(min-width: 992px){
  .main-headline{
      font-size: 40px;
  }
}

.main-headline-wrap{
  padding-bottom: 20px;
}
.home-wgts-info{
  padding-top: 50px;
  background-color: #fff;
}
.home-wgts-info__wgt-icon-wrap{
  padding-bottom: 15px;
}
.home-wgts-info__wgt-icon{
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.home-wgts-info__wgt-headline{
  font-size: 18px;
  text-align: center;
  font-weight: 600;
}
.home-wgts-info__wgt{
  padding-bottom: 12px;
}
.home-wgts-info__wgt:last-child{
  padding-bottom: 0;
}
.home-wgts-info__wgt-inner{
  border: 1px solid #ececec;
  padding: 20px 15px;
  border-radius: 5px;
}
@media(min-width: 992px){
  .home-wgts-info .main-headline-wrap{
    text-align: center;
  }
  .home-wgts-info__wgts{
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
  }
  .home-wgts-info__wgt{
    width: 33.333333%;
    flex: 0 0 auto;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 0;
  }
  .home-wgts-info__wgt-inner{
    height: 100%;
  }
}
@media(min-width: 1200px){
  .home-wgts-info__inner{
    padding-left: 140px;
    padding-right: 140px;
  }
}
.home-bonuses{
  padding-top: 60px;
}
.home-bonuses__wgt{
  padding-top: 30px;
  position: relative;
}
.home-bonuses__wgt-footer{
  padding-left: 15px;
  padding-right: 15px;
}
@media(min-width: 992px){
  .home-bonuses__inner{
    padding-left: 140px;
    padding-right: 140px;
  }
  .home-bonuses__wgt{
    padding-top: 70px;
  }
  .home-bonuses__wgt-footer{
    padding-left: 50px;
    padding-right: 50px;
  }
}
.home-bonuses__wgt-card{
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  background-color: #364ed4;
  border-radius: 20px;
  width: calc(100% - 20px);
  padding: 15px 20px;
  box-shadow: 0px 25px 28px 10px rgba(54,78,212,0.2);
}
.home-bonuses__wgt-card-headline{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-bonuses__wgt-card-name{
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}
.home-bonuses__wgt-card-photo{
  min-width: 35px;
  min-height: 35px;
  max-width: 35px;
  max-height: 35px;
  border-radius: 50%;
  display: block;
}
.home-bonuses__wgt-card-content{
  padding: 20px 0;
}
.home-bonuses__wgt-card-position{
  font-size: 14px;
  color: #fff;
  padding-bottom: 10px;
}
.home-bonuses__wgt-card-team{
  font-size: 13px;
  color: #fff;
  opacity: 0.5;
}
.home-bonuses__wgt-card-footer{
  display: flex;
  align-items: flex-start;
}
.home-bonuses__wgt-card-price{
  font-size: 30px;
  font-weight: 900;
  color: #fff;
  padding-right: 10px;
  line-height: 0.8;
}
.home-bonuses__wgt-card-currency{
  font-size: 12px;
  font-weight: bold;
  color: #fff;
}
@media(min-width: 768px){
  .home-bonuses__wgt-card{
    width: 260px;
  }
}
@media(min-width: 992px){
  .home-bonuses__wgt-card{
    width: 420px;
    padding: 27px 37px;
  }
  .home-bonuses__wgt-card-name{
    font-size: 36px;
  }
  .home-bonuses__wgt-card-photo{
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
    max-height: 50px;
  }
  .home-bonuses__wgt-card-content{
    padding: 30px 0;
  }
  .home-bonuses__wgt-card-position{
    font-size: 21px;
  }
  .home-bonuses__wgt-card-team{
    font-size: 18px;
  }
  .home-bonuses__wgt-card-price{
    font-size: 49px;
  }
  .home-bonuses__wgt-card-currency{
    font-size: 18px;
  }
}
.home-bonuses__wgt-inner{
  padding-top: 40px;
  padding-bottom: 50px;
  background-color: #dde5ff;
  border-radius: 15px;
  overflow: hidden;
}
.home-bonuses__wgt-points{
  display: none;
  transform: translateX(-60px);
}
@media(min-width: 992px){
  .home-bonuses__wgt-inner{
    border-radius: 40px;
  }
  .home-bonuses__wgt-points{
    display: flex;
    align-items: stretch;
  }
}
.home-bonuses__wgt-points_second{
  transform: translateX(20px);
}
.home-bonuses__wgt-point{
  padding: 10px 15px;
  white-space: nowrap;
  min-width: 200px;
}
.home-bonuses__wgt-point-inner{
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background-color: #fff;
  border-radius: 8px;
}
.home-bonuses__wgt-point-content{
  flex: 1;
  padding-right: 8px;
}
.home-bonuses__wgt-point-headline{
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 2px;
}
.home-bonuses__wgt-point-headline_theme_green{
  color: #32c26a;
}
.home-bonuses__wgt-point-headline_theme_red{
  color: #eb334d;
}
.home-bonuses__wgt-point-description{
  font-size: 14px;
  color: #808a8e;
}
.home-bonuses__wgt-point-icon {
  min-width: 36px;
  min-height: 36px;
  max-width: 36px;
  max-height: 36px;
}
.home-bonuses__wgt-point-icon img{
  max-width: 36px;
  height: 36px;
  display: block;
}
.home-bonuses__wgt-row{
  padding: 120px 15px 0 15px;
}
.home-bonuses__wgt-col:first-child{
  padding-bottom: 15px;
}
@media(min-width: 992px){
  .home-bonuses__wgt-row{
    display: flex;
    align-items: stretch;
    padding: 60px 30px 0 30px;
  }
  .home-bonuses__wgt-col{
    width: 50%;
    flex: 0 0 auto;
    padding-left: 20px;
    padding-right: 20px;
  }
  .home-bonuses__wgt-col:first-child{
    padding-bottom: 0;
  }
}
.home-bonuses__wgt-headline-wrap{
  padding-bottom: 10px;
}
.home-bonuses__wgt-headline{
  font-size: 18px;
  font-weight: bold;
}
.home-bonuses__wgt-description{
  font-size: 16px;
  font-weight: 500;
  line-height: 1.44;
  margin-bottom: 10px;
}
@media(min-width: 992px){
  .home-bonuses .main-headline-wrap{
    text-align: center;
  }
  .home-bonuses__wgt-headline{
    font-size: 24px;
  }
  .home-bonuses__wgt-description{
    font-size: 18px;
  }
}
.home-possibilities{
  padding-top: 25px;
  background-color: #fff;
}
.home-possibilities__inner{
  margin: 0 -5px;
}
.home-possibilities__col{
  padding: 0 5px;
}
.home-possibilities__info{
  padding-bottom: 20px;
}
.home-possibilities__description{
  padding-bottom: 15px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.44;
  color: #000;
}
.home-possibilities__tags{
  margin: 0 -6px;
  padding: 20px 0;
  display: none;
}
.home-possibilities__tag{
  padding: 6px;
}
.home-possibilities__tag-inner{
  background-color: #e7f1ff;
  border-radius: 10px;
  padding: 10px 14px;
  font-size: 18px;
  font-weight: 500;
  color: #000;
  text-align: center;
  cursor: pointer;
}
.home-possibilities__tag-inner.active{
  background-color: #364ed4;
  color: #fff;
}
.home-possibilities__apps{
  display: none;
  margin: 0 -10px;
}
.home-possibilities__col-image{
  display: none;
}
.home-possibilities__app{
  padding: 0 10px;
}
.home-possibilities__app img{
  display: block;
  width: auto;
  height: 50px;
}
.home-possibilities__image{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
}
.home-possibilities__image img{
  display: block;
  max-width: 100%;
  height: auto;
}
.main-link{
  display: flex;
  align-items: center;
  color: #364ed4;
  font-size: 16px;
}
.main-link__icon{
  stroke: #364ed4;
  fill: none;
  width: 9px;
  height: 14px;
  display: block;
  margin-left: 7px;
}
.home-possibilities__cards{
  padding: 50px 0 65px 0;
  background-color: #fff;
  display: none;
}
.about-info-app__list-home-possibilities{
  padding-top: 25px;
  padding-bottom: 45px;
}
@media(min-width: 992px){
  .home-possibilities{
    padding-bottom: 80px;
  }
  .about-info-app__list-home-possibilities{
    display: none;
  }
  .home-possibilities__inner{
    display: flex;
    align-items: center;
  }
  .home-possibilities__col{
    width: 50%;
    flex: 0 0 auto;
  }
  .home-possibilities__col-image{
    display: block;
  }
  .home-possibilities__tags{
    display: flex;
    flex-wrap: wrap;
  }
  .home-possibilities__apps{
    display: flex;
    align-items: center;
  }
  .home-possibilities__cards{
    display: block;
  }
}
.home-possibilities__cards-inner{
  display: flex;
  align-items: stretch;
}
.home-possibilities__cards-left{
  width: 70%;
  flex: 0 0 auto;
}
.home-possibilities__cards-right{
  width: 30%;
  flex: 0 0 auto;
}
.home-possibilities-cards-wgt{
  padding: 15px;
}
.home-possibilities-cards-wgt_h_50{
  height: 50%;
}
.home-possibilities-cards-wgt__inner{
  padding: 15px;
  border: 1px solid #d3d9e4;
  border-radius: 8px;
  height: 100%;
}
.home-possibilities-cards-wgt__inner-flex{
  display: flex;
  align-items: center;
}
.home-possibilities-cards-wgt__event{
  height: 100%;
}
.home-possibilities-cards-wgt__event-img{
  max-width: 100%;
  height: auto;
  display: block;
  border-radius: 5px;
}
.home-possibilities-cards-wgt__inner-flex .home-possibilities-cards-wgt__content{
  padding-left: 15px;
}
.home-possibilities-cards-wgt__content{
  padding: 5px 0;
  border-bottom: 1px solid #e3e3e3;
}
.home-possibilities-cards-wgt__content:last-child{
  border-bottom: none;
}
.home-possibilities-cards-wgt__headline{
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  font-family: 'SF-Pro-Display-Medium';
}
.home-possibilities-cards-wgt__headline_lg{
  font-size: 16px;
}
.home-possibilities-cards-wgt__headline-flex{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.home-possibilities-cards-wgt__headline-flex span{
  display: block;
  padding-right: 80px;
}
.home-possibilities-cards-wgt__item-flex{
  display: flex;
  justify-content: space-between;
}
.home-possibilities-cards-wgt__item{
  font-size: 14px;
  padding-bottom: 5px
}
.home-possibilities-cards-wgt__item:last-child{
  padding-bottom: 0;
}
.home-possibilities-cards-wgt__item_gray{
  color: #3f5057;
}
.home-possibilities-cards-wgt__label{
  font-size: 12px;
  color: #a0a0a0;
  padding-bottom: 5px;
}
.{
  list-style-type: none;
  display: flex;
  align-items: center;
}
.home-possibilities-cards-wgt__event-list-users li{
  padding-left: 5px;
  position: relative;
}
.home-possibilities-cards-wgt__event-list-users li:first-child{
  padding-left: 0;
}
.home-possibilities-cards-wgt__event-list-users li img{
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: block;
}
.home-possibilities-cards-wgt__event-list-users-img{
  max-width: 200px;
  max-height: 28px;
  display: block;
  margin-top: 2px;
}
.home-possibilities-cards-wgt__event-list-users-checked{
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #00c059;
  border: 1px solid #fff;
  position: absolute;
  right: -3px;
  bottom: -3px;
}
.home-possibilities-cards-wgt__event-list-users_dis{
  opacity: 0.4;
}
.home-possibilities-cards-wgt__event-list-users_count span{
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: #000;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ebebeb;
}
.home-possibilities__cards-left-top,
.home-possibilities__cards-left-bottom{
  display: flex;
  align-items: stretch;
}
.home-possibilities-cards-wgt__progress-inner{
  display: flex;
  align-items: stretch;
}
.home-possibilities-cards-wgt__progress,
.home-possibilities-cards-wgt__progress-content{
  width: 50%;
  flex: 0 0 auto;
}
.home-possibilities-cards-wgt__progress{
  display: flex;
  justify-content: flex-end;
}
.home-possibilities-cards-wgt__progress-img{
  width: auto;
  max-height: 172px;
  display: block;
}
.home-possibilities-cards-wgt_bottom_20{
  width: 20%;
}
.home-possibilities__cards-left-top-left{
  width: 20%;
  flex: 0 0 auto;
}
.home-possibilities__cards-left-top-left .home-possibilities-cards-wgt{
  height: 100%;
}
.home-possibilities__cards-left-top-left .home-possibilities-cards-wgt__content{
  padding-top: 15px;
}
.home-possibilities__cards-left-top-center{
  width: 50%;
  flex: 0 0 auto;
}
.home-possibilities__cards-left-top-right{
  width: 30%;
  flex: 0 0 auto;
}
.home-possibilities__cards-left-top-right .home-possibilities-cards-wgt{
  height: 100%;
}
.home-possibilities-cards-wgt_bottom_25{
  width: 25%;
  flex: 0 0 auto;
}
.home-possibilities-cards-wgt__survey-header{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.home-possibilities-cards-wgt__survey-img{
  width: 55px;
  height: 55px;
  display: block;
}
.home-possibilities-cards-wgt__survey-list{
  list-style-type: none;
  display: flex;
  align-items: center;
}
.home-possibilities-cards-wgt__survey-list li{
  padding-right: 5px;
}
.home-possibilities-cards-wgt__survey-list li:last-child{
  padding-right: 0;
}
.home-possibilities__cards-left-top-center-bottom{
  display: flex;
  align-items: stretch;
}
.home-possibilities__cards-left-top-center-bottom .home-possibilities-cards-wgt{
  width: 50%;
  flex: 0 0 auto;
}
.home-possibilities-cards-wgt__icon-umbrella{
  width: 55px;
  height: auto;
  display: block;
}
.home-possibilities-cards-wgt__count{
  font-size: 42px;
  color: #00c059;
}
.home-possibilities__cards-left-top-right .home-possibilities-cards-wgt__inner {
  position: relative;
}

.home-possibilities__cards-left-top-right .home-possibilities-cards-wgt__footer {
  position: absolute;
  bottom: 15px;
}
.home-possibilities-cards-wgt__inner .home-possibilities-cards-wgt__progress-content{
  position: relative;
}
.home-possibilities-cards-wgt__progress-content .home-possibilities-cards-wgt__item{
  position: absolute;
  bottom: 0;
}
.home-add-company__inner{
  padding-bottom: 50px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.home-possibilities-cards-wgt_bottom_25-v2 .home-possibilities-cards-wgt__inner{
  position: relative;
}
.home-possibilities-cards-wgt_bottom_25-v2 .home-possibilities-cards-wgt__survey-content{
  position: absolute;
  bottom: 15px;
}
.home-possibilities-cards-wgt__event .home-possibilities-cards-wgt__content:last-child{
  padding-top: 15px;
}
@media(min-width: 576px){
  .home-add-company__inner{
    border-radius: 15px;
  }
}
@media(min-width: 992px){
  .home-add-company__inner{
    border-radius: 40px;
  }
}
@media(max-width: 575px){
  .home-add-company .wrapper{
    padding: 0;
  }
}
.home-add-company__logo-wrap{
  display: flex;
  justify-content: center;
}
.home-add-company__logo{
  max-width: 112px;
  height: auto;
  display: block;
  position: relative;
  top: -35px;
  margin: 0 auto;
}
.home-add-company__content{
  margin: 0 auto;
  padding: 0 22px;
}
.home-add-company__headline-wrap{
  padding-bottom: 40px;
}
.home-add-company__headline{
  font-size: 21px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  line-height: 1.2;
  font-family: 'SF-Pro-Display-Black';
}
.home-add-company__nav{
  margin: 0 -5px;
}
.home-add-company__nav-col{
  padding: 0 5px 17px 5px;
}
.home-add-company__nav-col:last-child{
  padding-bottom: 0;
}
@media(min-width: 992px){
  .home-add-company__logo{
    max-width: 140px;
  }
  .home-add-company__content{
    max-width: 60%;
    padding: 0;
  }
  .home-add-company__headline{
    font-size: 40px;
  }
  .home-add-company__nav{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 40px;
  }
  .home-add-company__nav-col{
    width: 270px;
    flex: 0 0 auto;
    padding: 0 5px;
  }
}
.home-add-company__nav-btn{
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border: 1px solid #fff;
}
.home-add-company__nav-btn_theme_white{
  background-color: #fff;
  color: #364ed4;
}
.home-add-company__nav-btn_theme_border{
  background: none;
  color: #fff;
}
.home-manager{
  background-color: #fff;
  padding: 30px 0 70px 0;
}
.home-manager_description{
  padding-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.44;
  color: #000;
}
.home-manager__tags{
  padding-top: 30px;
}
.home-manager-tags-list{
  list-style-type: none;
  display: none;
  margin: 0 -5px;
}
.home-manager-tags-list__item{
  padding: 5px;
}
.home-manager-tags-list__btn{
  padding: 10px 15px;
  color: #000;
  background-color: #faecde;
  border-radius: 10px;
  font-size: 18px;
  border: none;
  font-weight: 500;
}
.home-manager-tags-list__btn.active{
  background-color: #000;
  color: #fff;
}
.home-manager__img-wrap{
  display: none;
}
@media(min-width: 992px){
  .home-manager{
    padding: 70px 0;
  }
  .home-manager__inner{
    display: flex;
    align-items: center;
  }
  .home-manager-tags-list{
    display: flex;
    flex-wrap: wrap;
  }
  .home-manager__img-wrap{
    display: flex;
    justify-content: center;
  }
  .home-manager__col{
    width: 50%;
    flex: 0 0 auto;
  }
  .about-info-app__list-manager{
    display: none;
  }
}
.home-manager__img{
  max-width: 100%;
  height: auto;
  display: block;
}
.home-try{
  position: relative;
}
.home-try__inner{
  border-radius: 15px;
  background-color: #e1ecd9;
  padding: 60px 0 35px;
}
@media(min-width: 992px){
  .home-try__inner{
    border-radius: 50px;
  }
}
.home-try__content{
  margin: 0 auto;
  padding-top: 230px;
  padding-left: 15px;
  padding-right: 15px;
}
.home-try__description{
  padding-bottom: 25px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.44;
}
.home-try__nav{
  margin: 0 -5px;
}
.home-try__nav-col{
  padding: 0 5px 12px 5px;
}
.home-try__nav-col:last-child{
  padding-bottom: 0;
}
.home-try__nav-btn{
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border: 1px solid #364ed4;
  width: 100%;
}
.home-try__nav-btn_theme_blue{
  background-color: #364ed4;
  color: #fff;
}
.home-try__nav-btn_theme_blue-border{
  background: none;
  color: #364ed4;
}
.home-try__avatarts-wrapper{
  padding-bottom: 75px;
  display: none;
}
.home-try__avatars{
  overflow: hidden;
  padding: 5px 0;
}
.home-try-avatars-list{
  list-style-type: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}
.home-try-avatars-list .home-try-avatars-list__item{
  padding: 0 15px;
}
.home-try-avatars-list .home-try-avatars-list__img{
  width: 60px;
  height: 60px;
  display: block;
  border-radius: 50%;
}
.home-try__phone{
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  max-width: 300px;
}
@media(min-width: 992px){
  .home-try__content{
    width: 80%;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .home-try__avatarts-wrapper{
    display: block;
  }
  .home-try .main-headline-wrap{
    text-align: center;
  }
  .home-try__description{
    text-align: center;
  }
  .home-try__nav{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
  }
  .home-try__nav-col{
    width: 270px;
    flex: 0 0 auto;
    padding: 0 5px;
  }
}
@media(min-width: 1200px){
  .home-try__content{
    width: 60%;
  }
}
.swiper {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  background: #fff;
  display: flex;
  align-items: center;
}
.swiper-pagination-bullet{
  background-color: #d8d8d8;
  width: 3px;
  height: 45px;
  border-radius: 0;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active{
  background-color: var(--main-color);
}
.registration{
  padding-top: 50px;
  padding-bottom: 70px;
}
.auth-form-wgt{
  margin: 0 auto;
  border-radius: 10px;
}
.auth-form-wgt__headline{
  margin-bottom: 15px;
}
.register-wgt-nav{
  display: flex;
  margin: 0 -10px 20px -10px;
}
.register-wgt-nav__btn{
  width: 50%;
  flex: 0 0 auto;
  background-color: #fff;
  color: #c5c5c5;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #e1e1e1;
  height: 35px;
  padding: 5px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
}
.register-wgt-nav__btn.active{
  color: var(--main-color);
  background-color: #f8f9ff;
  border-bottom-color: var(--main-color);
}
.auth-form__content{
  display: none;
}
.auth-form__content.active{
  display: block;
}
.login,
.forgot,
.registration{
  height: 100%;
  display: flex;
  padding-top: 70px;
}
@media(min-width: 768px){
  .login,
  .forgot,
  .registration{
    align-items: center;
  }
}
.login-form__forgot{
  color: #364ed4;
  border-bottom: 1px dashed #364ed4;
  font-size: 14px;
}
.login-form__nav{
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
}
.login-form__remember{
  display: flex;
  align-items: center;
}
.login-form__remember-label{
  margin-right: 10px;
  font-size: 14px;
}
.account-wrap{
  height: 100%;
}
.account-wrap__completed.account-nav{
  top: 36px;
}
.account-nav{
  width: 250px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #22232a;
  transform: translateX(-100%);
  z-index: 99999;
  overflow: hidden;
}
.account-nav-list-headline{
  color: #656670;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  padding: 0 10px;
}
.account-nav__background{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99997;
  background: none;
}
.account-nav__inner{
  display: flex;
  flex-direction: column;
  width: calc(100% + 50px);
  height: 100%;
  overflow: auto;
  padding-bottom: 10px;
  padding-right: 50px;
}
.account-nav__header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
}
.account-nav__close-btn{
  background: none;
  border: none;
}
.account-nav__close-icon{
  width: 18px;
  height: 18px;
  fill: #fff;
}
.account-nav__logo{
  display: block;
  height: 24px;
}
.account-nav__log-out{
  background: none;
  border: none;
}
.account-nav__log-out svg{
  width: 17px;
  height: 17px;
  display: block;
  stroke: #fff;
}
.account-nav-list-wrap{
  padding: 0 5px;
}
.account-nav-list{
  list-style-type: none;
  padding: 10px 0 25px 0;
}
.account-nav-list__arrow{
  width: 12px;
  height: 7px;
  stroke: #fff;
  display: block;
}
.account-nav-list__item.active .account-nav-list__arrow{
  transform: rotate(180deg);
}
.account-nav-list__label{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 23px;
  color: #fff;
  border-radius: 50%;
  background-color: #22ca7b;
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
.account-nav-list__role{
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffe3aa;
  border-radius: 5px;
  background-color: #4e4d4b;
  display: inline-block;
  padding: 2px 5px;
  margin-left: 5px;
}
.account-nav-list__link.account-nav-list__link_role{
  justify-content: flex-start;
}
.account-nav-list__personal{
  position: relative;
}
.account-nav-list__menu::after{
  display: none;
}
.account-nav-list__personal::before{
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  width: 220px;
  height: 1px;
  background-color: #36373e;
}
.account-nav-list__personal::after{
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  width: 220px;
  height: 1px;
  background-color: #36373e;
}
.account-nav-list__link{
  position: relative;
  padding: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  overflow: hidden;
  background: none;
  border: none;
  width: 100%;
  font-family: 'SF-Pro-Display-Regular', sans-serif;
}
.account-nav-list__link:hover,
.account-nav-list__link.active{
  border-radius: 5px;
  background-color: #36394e;
}
.account-nav-list__item{
  position: relative;
}
.account-nav-list__no-login .account-nav-list__item:first-child:after{
  display: none;
}
.account-nav-list__no-login{
  padding: 0;
}
.account-nav-list__no-login .account-nav-list__item:after {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  width: 220px;
  height: 1px;
  background-color: #36373e;
}
.account-nav-list__no-login .account-nav-list__link{
  padding-top: 15px;
  padding-bottom: 15px;
}
.add-team-btn__icon{
  width: 25px;
  height: 25px;
  display: block;
  stroke: #fff;
}
.account-nav-list__name{
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  word-break: break-word;
}
.account-nav-list__sub{
  display: none;
}
.account-nav-list__item.active .account-nav-list__sub{
  display: block;
}
.account-nav-list__sub .account-nav-list__link{
  padding-left: 43px;
}
.account-nav-list__name img{
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
}
.account-nav-list__icon{
  width: 18px;
  height: 18px;
  min-width: 18px;
  max-width: 18px;
  display: block;
  margin-right: 10px;
}
.account-nav__footer{
  padding: 10px 15px;
  position: relative;
}
.account-nav__footer::before{
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  width: 220px;
  height: 1px;
  background-color: #36373e;
}
.account-content{
  height: 100%;
  padding-top: 40px;
}
.account-content__inner{
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
@media(max-width: 991px){
  .account-content__header-user{
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.86;
    letter-spacing: normal;
    color: #000;
    padding: 15px 10px 10px 10px;
  }
}
@media(max-width: 767px){
  .account-content__header_hide{
    display: none;
  }
}
@media(max-width: 991px){
  .account-content__header-catalog-inner,
  .account-content__header-about-inner,
  .account-content__header-category-inner,
  .account-content__header-users-inner{
    justify-content: space-between;
  }
}
.account-content__header-about-inner .edit-category-btn{
  margin-left: 10px;
  margin-top: 2px;
}
.account-content__header-back-link{
  margin-top: 3px;
  margin-right: 20px;
  display: flex;
  align-self: center;
  background: none;
  border: none;
}
.account-content__header-back-icon{
  width: 16px;
  height: 12px;
  stroke: #000;
}
.account-content__headline-wrap{
  padding: 0 15px;
}
.account-content__headline{
  font-size: 21px;
  padding: 5px 0;
  word-break: break-word;
}
.account-content__header-inner{
  display: flex;
  align-items: center;
}
@media(min-width: 992px){
  .account-content__header-inner{
    justify-content: space-between;
  }
  .account-content__headline-wrap{
    padding: 0 30px;
  }
}
.account-content__header-headline{
  display: flex;
  align-items: center;
}
.account-content__wgt.user-statistic{
  padding-bottom: 0;
  border-bottom: 0;
  background-color: #fff;
}
.account-content__content-amount{
  font-size: 14px;
  font-weight: bold;
}
.account-content__wgt{
  padding: 15px 10px;
  border-bottom: 1px solid #dde1e4;
}
.account-content__wgt.account-content__wgt_p_t{
  padding-top: 15px;
}
@media(min-width: 992px){
  .account-content__wgt.account-content__wgt_p_t{
    padding-top: 30px;
  }
}
.account-wgt__inner{
  display: flex;
  flex-wrap: wrap;
}
.account-wgt__header{
  margin-bottom: 18px;
  font-size: 16px;
}
.account-wgt__item{
  width: 50%;
  flex: 0 0 auto;
  padding-bottom: 20px;
}
.account-wgt__item-inner{
  display: flex;
  align-items: center;
  position: relative;
}
.account-wgt__item-inner .tooltip-info{
  margin-top: -4px;
}
.account-item__color{
  position: relative;
  display: flex;
  align-items: center;
}
.account-wgt__edit-btn_color{
  position: relative;
  display: block;
  margin: 0 auto;
  text-align: center;
  left: -15px;
  margin-bottom: 10px;
}
.account-item__headline{
  color: #626e77;
  font-size: 12px;
  margin-bottom: 5px;
}
.account-item__value{
  font-size: 14px;
}
.account-wgt__edit-btn{
  color: var(--main-color);
  background: none;
  border: none;
  font-size: 14px;
}
.account-nav__content{
  flex: 1 0 auto;
}
.account-nav__footer {
  flex: 0 0 auto;
}
.account-nav-footer-list{
  display: none;
  list-style-type: none;
  margin-bottom: 10px;
}
.account-nav-footer-list__item{
  padding-right: 10px;
}
.account-nav-footer-list__item:last-child{
  padding-right: 0;
}
.account-nav-footer-list__link{
  font-size: 14px;
  color: #5b5e6c;
}
.account-nav__copy{
  font-size: 14px;
  color: #5b5e6c;
  display: none;
}
.account-content__header-add{
  margin-left: 15px;
  display: none;
}
.account-content__header-add-mob{
  display: inline-flex;
}
.account-item-splash__icon-wrap{
  position: relative;
}
.account-item-splash__icon-wrap img{
  max-width: 150px;
  display: block;
}
.account-item-splash__icon-wrap svg{
  width: 18px;
  height: 29px;
}
.account-item-splash__icon-inner-wrap{
  z-index: 10;
  padding: 5px;
  border-radius: 7px;
  border: 1px solid #d7d7d7;
  background-color: #fff;
}
@media(max-width: 991px){
  .account-item-splash__icon-inner-wrap{
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}
.account-item-splash__icon{
  display: none;
  cursor: pointer;
}
@media(min-width: 992px){
  .account-item-splash__icon-inner-wrap{
    position: absolute;
    top: 0;
    right: -180px;
    left: auto;
    transform: none;
  }
  .account-item-splash__icon{
    display: block;
  }
  .account-item-splash__icon-mob{
    display: none;
  }
}
.account-item-splash__content{
  display: flex;
}
.account-item-splash__img-wrap{
  padding-right: 10px;
  position: relative;
}
.account-item-company__inner{
  background-color: #f6f4f4;
  display: inline-block;
  border-radius: 4px;
}
.account-item-splash__img-wrap img{
  display: block;
  height: 46px;
  padding: 5px;
}
.account-item-company{
  height: auto;
}
.account-item-splash__img-wrap .account-item-company__logo{
  max-width: 150px;
  height: auto;
  display: block;
}
.account-item-splash__img{
  width: 100%;
  height: 46px;
  display: block;
}


.account-item-color__icon-wrap{
  position: relative;
  padding-left: 10px;
}
.account-item-color__icon-wrap img{
  max-width: 150px;
  display: block;
}
.account-item-color__icon-wrap svg{
  width: 18px;
  height: 29px;
}
.account-item-color__icon-inner-wrap{
  z-index: 10;
  padding: 5px;
  border-radius: 7px;
  border: 1px solid #d7d7d7;
  background-color: #fff;
}
@media(max-width: 991px){
  .account-item-color__icon-inner-wrap{
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}
.account-item-color__icon{
  display: none;
  cursor: pointer;
}
@media(min-width: 992px){
  .account-item-color__icon-inner-wrap{
    position: absolute;
    top: 0;
    right: -180px;
    left: auto;
    transform: none;
  }
  .account-item-color__icon{
    display: block;
  }
  .account-item-color__icon-mob{
    display: none;
  }
}
.account-item-color__content{
  display: flex;
}
.account-item-color__img-wrap{
  padding-right: 10px;
  position: relative;
}
.account-item-color__img-wrap img{
  display: block;
  height: 46px;
  padding: 5px;
}

.account-item-splash__img-empty{
  width: 82px;
  height: 46px;
  background-color: #d8d8d8;
  border-radius: 2px;
}
.account-item-card__img-empty{
  width: 61px;
  height: 46px;
  background-color: #d8d8d8;
  border-radius: 2px;
}
.account-item-card__img-empty{
  width: 61px;
  height: 46px;
  background-color: #d8d8d8;
  border-radius: 2px;
}
.account-item-app__img-empty{
  width: 46px;
  height: 46px;
  background-color: #d8d8d8;
  border-radius: 2px;
}
.account-item-app__img-wrap img{
  display: block;
  height: 46px;
}
.account-item-splash__img-lg{
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
  max-height: 150px;
}
.account-item__icon-size{
  padding: 5px 0 10px 0;
  font-size: 14px;
  color: #808a8e;
}
.account-item-card__content{
  display: flex;
}
.account-item__value_color{
  display: flex;
  margin-bottom: 10px;
}
.account-item__color{
  width: 16px;
  height: 16px;
  border-radius: 2px;
  margin-left: 5px;
}
.account-item-card__img-wrap{
  height: 46px;
  position: relative;
  padding-right: 10px;
}
.account-item-card__img-wrap img{
  display: block;
  height: 46px;
}
.account-item-card__img{
  height: 46px;
  display: block;
}
.account-item-app__content{
  display: flex;
}
.account-item-app__img-wrap{
  height: 46px;
  position: relative;
  padding-right: 10px;
}
.account-item-app__img{
  height: 46px;
  display: block;
}
.team-nav{
  padding-bottom: 15px;
}
.team-nav__between{
  display: flex;
  justify-content: space-between;
  padding-right: 30px;
  flex: 1;
}
.team-nav .team-nav__btn{
  margin-right: 15px;
  font-size: 14px;
  line-height: normal;
  font-weight: normal;
  font-family: sans-serif;
}
.team-nav .team-nav__btn:last-child{
  margin-right: 0;
}
.team-table,
.catalog-table,
.questions-table,
.events-table,
.board-table,
.standart-table,
.table-library{
  width: 100%;
  text-align: left;
  border-spacing: 0px;
  border-collapse: collapse;
}
.team-table tbody tr:hover,
.catalog-table tbody tr:hover,
.questions-table tbody tr:hover,
.events-table tbody tr:hover,
.board-table tbody tr:hover,
.standart-table tbody tr:hover,
.table-library tbody tr:hover{
  background-color: #f4f4f5;
}
.team-table .team-table__choose{
  width: 35px;
}
.team-table__name_position{
  margin-bottom: 5px;
}
.team-table__name_position,
.team-table__name_email{
  font-size: 13px;
  color: #808a8e;
}
.team-table__row,
.catalog-table__row,
.questions-table__row,
.events-table__row,
.board-table__row,
.standart-table__row{
  cursor: pointer;
}
.questions-table__row.disabled td{
  color: #808A8E;
}
.team-table__row.row-disabled,
.catalog-table__row.row-disabled,
.product-wgt__inner.row-disabled,
.standart-table__row.row-disabled,
.events-table__row.row-disabled{
  animation: rowDelete 0.5s ease-in-out forwards;
  background-color: rgba(255, 0, 0, 0.1) !important;
}
.standart-table__row_disabled td{
  opacity: 0.3;
}
.standart-table__row_disabled td:last-child{
  opacity: 1;
}
@keyframes rowDelete {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.team-table__row_status_disabled .team-table__name,
.team-table__row_status_disabled .team-table__email,
.team-table__row_status_disabled .team-table__team,
.team-table__row_status_disabled .team-table__position,
.team-table__row_status_disabled .team-table__rights,
.team-table__row_status_disabled .team-table__balance{
  color: #000;
  opacity: 0.5;
}
.team-table__row_status_disableded .team-table__name-inner,
.team-table__row_status_disableded .team-table__email,
.team-table__row_status_disableded .team-table__team,
.team-table__row_status_disableded .team-table__position,
.team-table__row_status_disableded .team-table__rights,
.team-table__row_status_disableded .team-table__balance{
  opacity: 0.3;
  color: #000;
}
.team-table__rights-tooltip{
  position: relative;
  display: inline-block;
}
.team-table__rights-icon:hover ~ span{
  display: block;
}
.team-table__rights-tooltip span{
  display: none;
      padding: 15px;
      border: 1px solid rgba(0, 0, 0, 0.07);
      position: absolute;
      left: 50%;
      top: 100%;
      transform: translateX(-50%);
      z-index: 10;
      background-color: #fff;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
}
.team-table__rights-icon{
  width: 18px;
  height: 18px;
}
.standart-table__row_status_disabled td{
  color: #000;
  opacity: 0.5;
}
.standart-table__row_status_disabled td:last-child{
  opacity: 1;
}
.team-table__name-flex{
  display: flex;
  align-items: center;
}
.team-table__name-photo-wrap{
  margin-right: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
}
.team-table__name-photo-wrap_overlay{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
  background-color: #364ed4;
  opacity: 0.5;
  border-radius: 50%;
}
.team-table__name-photo{
  width: 20px;
  height: 20px;
  display: block;
  border-radius: 50%;
}
.team-table__name-val{
  font-size: 15px;
  font-weight: bold;
}
.team-table tr th,
.catalog-table tr th,
.questions-table tr th,
.events-table tr th,
.board-table tr th,
.standart-table tr th,
.table-library tr th,
.history-balance tr th{
  color: #626E77;
  font-size: 12px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.team-table tr,
.catalog-table tr,
.questions-table tr,
.events-table tr,
.board-table tr,
.standart-table tr,
.orders-table tr,
.table-library tr,
.history-balance tr{
  position: relative;
}
.team-table tr::after,
.catalog-table tr::after,
.questions-table tr::after,
.events-table tr::after,
.board-table tr::after,
.standart-table tr::after,
.orders-table tr::after,
.table-library tr::after,
.history-balance tr::after{
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 30px);
  height: 1px;
  background-color: #EDEDED;
}
.team-table tr td,
.catalog-table tr td,
.questions-table tr td,
.events-table tr td,
.board-table tr td,
.standart-table tr td,
.table-library tr td,
.history-balance tr td
{
  color: #000;
  font-size: 14px;
}
.team-table tr th,
.team-table tr td{
  padding: 10px 5px;
}
.catalog-table tr th,
.catalog-table tr td,
.standart-table tr th,
.standart-table tr td,
.events-table tr th,
.events-table tr td,
.questions-table tr th,
.questions-table tr td,
.table-library tr th,
.table-library tr td,
.team-table__users tr th,
.team-table__users tr td,
.team-table tr th,
.team-table tr td,
.table-settings tr th,
.table-settings tr td,
.standart-table__bonuses-users tr th,
.standart-table__bonuses-users tr td,
.history-balance tr th,
.history-balance tr td
{
  padding: 11px 0;
}
@media(min-width: 992px){
  .catalog-table tr th,
  .catalog-table tr td,
  .board-table tr th,
  .board-table tr td,
  .standart-table tr th,
  .standart-table tr td,
  .events-table tr th,
  .events-table tr td,
  .questions-table tr th,
  .questions-table tr td,
  .standart-table__library tr th,
  .standart-table__library tr td,
  .team-table__users tr th,
  .team-table__users tr td,
  .team-table tr th,
  .team-table tr td,
  .table-settings tr th,
  .table-settings tr td,
  .standart-table__bonuses-users tr th,
  .standart-table__bonuses-users tr td,
  .history-balance tr th,
  .history-balance tr td
  {
    padding: 10.5px 15px;
  }
}
.team-table__balance{
  vertical-align: top;
}
.team-table__balance,
.questions-table__balance{
  font-weight: bold;
}
.team-table__balance-add{
  color: #17c84f;
  font-weight: normal;
  margin-left: 5px;
}
.team-table__control,
.catalog-table__control,
.team-table__nav-mobile-wrap{
  display: flex;
  align-items: center;
}
.team-table__nav-mobile-wrap{
  justify-content: flex-end;
}
.standart-table__library{
  display: none;
}
.standart-table__library tr{
  cursor: pointer;
}
@media(min-width: 576px){
  .standart-table__library{
    display: table;
  }
  .library-mob{
    display: none;
  }
}
.standart-table .standart-table__status-stock,
.table-library .standart-table__status-stock{
  color: #00ae4c;
  font-weight: 500;
}
.standart-table__status-user{
  color: #364ed4;
  font-weight: 500;
}
@media(min-width: 992px){
  .team-table__balance{
    vertical-align: middle;
  }
  .team-table__nav-mobile-wrap{
    display: none;
  }
}
.questions-table__status,
.events-table__status,
.board-table__status{
  font-weight: 600;
}
.questions-table__status_pending{
  color: #f48a19 !important;
}
.questions-table__status_active,
.events-table__status_active,
.board-table__status_active{
  color: #00ae4c !important;
}
.questions-table__status_ended,
.events-table__status_ended,
.board-table__status_ended{
  color: #757f87 !important;
}
.questions-table__status_delete,
.events-table__status_delete,
.board-table__status_delete{
  color: #d01a36 !important;
}
.questions-table__row_status_disabled .questions-table__name,
.questions-table__row_status_disabled .questions-table__member,
.questions-table__row_status_disabled .questions-table__date,
.questions-table__row_status_disabled .questions-table__progress,
.questions-table__row_status_disabled .questions-table__balance{
  opacity: 0.3;
}
.events-table__row_status_disabled .events-table__name,
.events-table__row_status_disabled .events-table__amount,
.events-table__row_status_disabled .events-table__category,
.events-table__row_status_disabled .events-table__date{
  opacity: 0.3;
}
.board-table__row_status_disabled .board-table__name,
.board-table__row_status_disabled .board-table__author,
.board-table__row_status_disabled .board-table__category,
.board-table__row_status_disabled .board-table__date,
.board-table__row_status_disabled .board-table__type{
  opacity: 0.3;
}
.catalog-table__nav{
  display: flex;
}
.catalog-table__order-num{
  min-width: 110px;
}
.questions-table__result{
  width: 100%;
  text-align: center;
  display: inline-block;
  color: #364ed4;
  padding: 5px 10px;
  border-radius: 13px;
  background-color: rgba(54, 78, 212, 0.12);
}
.questions-table__result_lg{
  padding: 10px;
  border-radius: 30px;
}
.questions-table__result_empty{
  opacity: 0.2;
}
.questions-table__row_standart{
  border-top: 1px solid #e5e5e5 !important;
}
.questions-table__row_repeat td{
  padding-bottom: 10px !important;
}
.questions-table__row_sub{
  background-color: #f4f4f5;
}
.questions-table__row_sub .questions-table__name{
  padding-left: 14px;
}
.questions-table__row_active .questions-table__name{
  padding-left: 14px;
}
.questions-table__name_bold{
  font-weight: bold;
}
.team-table__nav{
  display: flex;
  align-items: center;
}
.team-table__nav-btn,
.catalog-table__nav-btn{
  border: none;
  background: none;
  position: relative;
}
.team-table__nav-btn svg,
.catalog-table__nav-btn svg{
  width: 16px;
  height: 17px;
}
.team-table__nav-btn,
.catalog-table__nav-btn{
  margin-right: 10px;
}
.catalog-table__name{
  word-break: break-word;
}
.catalog-table__name-link{
  color: #000;
}
.catalog-table td.catalog-table__name{
  color: #000;
}
@media(min-width: 992px){
  .catalog-table td.catalog-table__name{
    width: 70%;
  }
  .catalog-table td.catalog-table__amount{
    width: 15%;
  }
}
.user-info-modal{
  position: relative;
}
.user-info-modal .modal-header{
  position: absolute;
  top: 10px;
  right: 30px;
  z-index: 99999;
}
.user-info{
  position: relative;
}
.product-info .tg-list-item{
  position: absolute;
  top: 30px;
}
.user-info__checkbox{
  position: absolute;
  top: 10px;
  left: 0;
}
.user-info__header,
.product-info__header{
  text-align: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #e2e2e2;
}
.user-info__photo{
  width: 125px;
  height: 125px;
  border-radius: 50%;
}
.user-info__photo-wrap,
.product-info__photo-wrap{
  padding-bottom: 15px;
}
.product-info__header-photos{
  position: relative;
}
.product-info__btn-slide{
  background: none;
  border: none;
  position: absolute;
  z-index: 10;  top: 50%;
  transform: translateY(-50%);
}
.product-info__btn-slide_left{
  left: 0;
}
.product-info__btn-slide_right{
  right: 0;
}
.product-info__btn-slide-icon{
  width: 28px;
  height: 28px;
  display: block;
}
.product-info__photo-wrap{
  max-width: 165px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}
.product-info__photo-wrap.active{
  display: block;
}
.product-info__photo{
  display: block;
  width: 100%;
  height: auto;
}
.user-info__name,
.product-info__name{
  margin-bottom: 10px;
}
.user-info__position{
  color: #626570;
}
.user-info__content,
.product-info__content{
  padding: 15px 0;
  border-bottom: 1px solid #e2e2e2;
}
.user-info__content-table,
.product-info__content-table{
  width: 100%;
  font-size: 14px;
  font-weight: 500;
}
.user-info__content-table td{
  padding: 4px 0;
  display: block;
}
.product-info__content-table td{
  padding: 4px 0;
}
.user-info__content-table td:first-child,
.product-info__content-table td:first-child{
  color: #626570;
}
.product-info__content-table td:last-child{
  text-align: right;
  font-weight: bold;
  font-size: 16px;
}
.product-info__price-icon{
  width: 12px;
  height: 11px;
  fill: #000;
}
.product-info__description{
  padding: 15px 0;
  border-bottom: 1px solid #e2e2e2;
  font-size: 14px;
  line-height: 1.5;
  color: #000;
  font-weight: 400;
}
.product-info__content-table .product-info__notes-headline td{
  font-weight: 500;
  width: 100%;
  text-align: left;
  font-size: 14px;
}
.product-info__content-table .product-info__notes-value td{
  font-weight: 500;
  width: 100%;
  text-align: left;
  color: #000;
}
@media(min-width: 768px){
  .user-info__content-table td,
  .product-info__content-table td{
    width: 50%;
    display: table-cell;
  }
}
.user-info__balance-info{
  margin-bottom: 5px;
}
.user-info__balance{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e2e2e2;
  padding: 15px 0;
}
.user-info__balance-headline{
  font-size: 18px;
  font-weight: bold;
  margin-right: 3px;
}
.user-info__balance-icon{
  width: 14px;
  height: 15px;
}
.user-info__balance-history{
  color: #364ed4;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 400;
}
.user-info__hobby{
  padding: 15px 0;
  border-bottom: 1px solid #e2e2e2;
}
.user-info__label{
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
}
.user-info__tags{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}
.user-info__tags span{
  display: block;
  padding: 3px 10px;
  background-color: #e8ecff;
  color: #364ed4;
  margin: 5px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
}
.user-info__nav{
  padding: 15px 0;
  display: flex;
  margin: 0 -5px;
}
.user-info__nav-col{
  width: 50%;
  flex: 0 0 auto;
  padding: 0 5px;
}
.product-info__nav{
  padding-top: 15px;
}
.product-info__nav-col{
  padding: 5px 0;
}
.product-info__confirm{
  padding-top: 10px;
}
.product-info__status_new{
  color: #00ae4c;
}
.product-info__status_accepted{
  color: #f48814;
}
.product-info__status_delivered{
  color: #000;
}
.product-info__message{
  padding-top: 15px;
}
.add-product-form-wrap,
.edit-form-wrap{
  padding: 5px 10px;
}
.add-product-form__inner{
  padding-bottom: 25px;
}
@media(min-width: 768px){
  .add-product-form__submit{
    width: 232px;
  }
}
@media(min-width: 992px){
  .add-product-form-wrap,
  .edit-form-wrap{
    padding: 5px 30px;
  }
}
@media(max-width: 767px){
  .add-product-form .edit-user-form__row:first-child{
    padding-bottom: 0;
  }
}
.unlimited-product{
  display: flex;
  align-items: center;
  height: 100%;
  margin-top: 5px;
}
.unlimited-product span{
  margin-left: 10px;
  font-size: 14px;
}
.search-orders-wrap{
  position: relative;
}
@media(min-width: 768px){
  .search-orders{
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
  }
}
.form-search-orders__field{
  border: 1px solid #E1E1E1;
  border-radius: 5px;
  height: 38px;
  font-family: sans-serif;
  display: block;
  width: 100%;
  padding-left: 35px;
  font-size: 16px;
}
.search-close{
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0;
}
.search-close__icon{
  width: 17px;
  height: 17px;
  fill: #626E77;
  display: block;
}
.order-wgt,
.library-wgt{
  border: 1px solid #dde1e4;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 5px;
}
.order-wgt__header,
.library-wgt__header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}
.order-wgt__name{
  font-size: 16px;
  color: #364ed4;
}
.library-wgt__name{
  color: #000;font-size: 16px;
}
.order-wgt__name .new-order{
  font-weight: bold;
}
.order-wgt__content-row,
.library-wgt__content-row{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.order-wgt__content-row_padding,
.library-wgt__content-row_padding{
  padding-bottom: 10px;
}
.order-wgt__content-wgt:first-child .order-wgt__content-wgt-value,
.library-wgt__content-wgt:first-child .library-wgt__content-wgt-value{
  font-weight: 600;
}
.order-wgt__content-wgt:last-child,
.library-wgt__content-wgt:last-child{
  text-align: right;
}
.order-wgt__content-wgt-label,
.library-wgt__content-wgt-label{
  font-size: 12px;
  color: #626e77;
  margin-bottom: 5px;
}
.order-wgt__content-wgt-value,
.library-wgt__content-wgt-value{
  font-size: 14px;
  color: #000;
}
.order-wgt__content-wgt-value_status_new{
  color: #01ae4c !important;
}
.order-wgt__content-wgt-value_status_accepted{
  color: #f48814 !important;
}
.order-wgt__content-wgt-value_status_delivered{
  color: #000 !important;
}
.order-wgt__content-wgt-value_status_rejected{
  color: #e74426 !important;
}
.order-wgt__nav,
.library-wgt__nav{
  display: flex;
  align-items: center;
}
.order-wgt__nav-btn,
.library-wgt__nav-btn{
  background: none;
  border: none;
}
.order-wgt__nav-btn:first-child,
.library-wgt__nav-btn:first-child{
  margin-right: 15px;
}
.order-wgt__nav-icon,
.library-wgt__nav-icon{
  width: 16px;
  height: 17px;
  stroke: #364ed4;
}
.library-wgt__content-wgt-value_stock{
  color: #00ae4c;
  font-weight: 500;
}
.orders-table{
  display: none;
  width: 100%;
  text-align: left;
  border-spacing: 0px;
  border-collapse: collapse;
}
@media(min-width: 576px){
  .orders-table{
    display: table;
  }
  .orders-mob{
    display: none;
  }
}
.orders-table tbody tr:hover{
  cursor: pointer;
  background-color: #f4f4f5;
}
.orders-table tr th{
  font-size: 12px;
  color: #626e77;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.orders-table tr td{
  font-size: 14px;
}
.orders-table tr th,
.orders-table tr td{
  padding: 10px 15px;
}
.orders-table__name{
  color: #000;
}
.orders-table__status{
  font-weight: 600;
}
.new-order{
  font-weight: 600;
}
.orders-table__status_new{
  color: #00ae4c;
}
.orders-table__status_accepted{
  color: #f48814;
}
.orders-table__status_delivered{
  color: #000;
}
.search-btn{
  background: none;
  border: none;
}
.order-search-icon{
  width: 18px;
  height: 18px;
  stroke: #000;
  margin-left: 15px;
  display: block;
}
.form-search-orders__icon{
  width: 17px;
  height: 17px;
  stroke: #000;
  margin-left: 15px;
  display: block;
}
.account-content__header-inner-order{
  display: block;
}
.account-content__header-order-inner{
  display: flex;
  align-items: center;
}
.search-field-wrap{
  position: relative;
  width: 100%;
}
.search-field{
  border: 1px solid #dde1e4;
  border-radius: 20px;
  height: 32px;
  font-family: sans-serif;
  display: block;
  width: 100%;
  padding-left: 35px;
  font-size: 14px;
  margin-top: 10px;
}
@media(max-width: 767px){
  .search-field{
    font-size: 16px;
  }
}
.search-submit{
  background: none;
  border: none;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 10;
  cursor: default;
}
@media(min-width: 768px){
  .search-field{
    margin-top: 0;
  }
  .search-field-wrap{
    padding-left: 15px;
  }
  .account-content__header-inner-order{
    display: flex;
    align-items: center;
  }
  .search-field-wrap{
    width: 340px;
  }
  .search-submit{
    left: 10px;
  }
}
.edit-info{
  padding-bottom: 15px;
}
.edit-info__label{
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
  color: #626570;
}
.edit-info__value{
  font-size: 16px;
  font-weight: bold;
  color: #000;
}
.edit-order-form__nav{
  max-width: 500px;
  display: flex;
  align-items: center;
  margin: 0 -9px;
}
.edit-order-form__nav_col{
  width: 50%;
  flex: 0 0 auto;
  padding: 0 9px;
}
.account-content__messages{
  padding: 10px;
}
@media(min-width: 992px){
  .account-content__messages{
    padding: 5px 30px 15px 30px;
  }
}
.message-tags-wrap{
  display: flex;
  align-items: center;
}
.message-tags{
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0 -4px 0 -4px;
  padding-right: 8px;
}
.message-tags-wrap .message-tags{
  margin: 0 -4px 0 -4px;
}
.message-tags__item{
  padding: 0 4px;
}
.message-tags__btn{
  background-color: #ecefff;
  border-radius: 5px;
  color: #364ed4;
  border: none;
  padding: 11px 20px;
  font-size: 14px;
}
.message-tags__btn.active{
  background-color: #364ed4;
  color: #fff;
}
.message-tags__btn_message{
  background-color: #fae4d0;
  color: #82491e;
}
.message-tags__btn_message.active{
  background-color: #f0a24e;
  color: #fff;
}
.message-tags__btn_bonus{
  background-color: #cff1df;
  color: #1a5f30;
}
.message-tags__btn_bonus.active{
  background-color: #2da985;
  color: #fff;
}
.account-content__tags{
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
}
.messages-wgt{
  border-bottom: 1px solid #dde8ee;
  padding: 10px 0 5px 0;
  position: relative;
  cursor: pointer;
}
.messages-wgt__overlay{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
}
.messages-wgt__answer-icon{
  width: 15px;
  height: 15px;
  display: block;
  fill: #364ed4;
  margin-left: 5px;
  margin-top: 3px;
}
@media(min-width: 992px){
  .messages-wgt__overlay{
    display: none;
  }
}
.messages-wgt__inner{
  position: relative;
}
.messages-wgt__reply-btn{
  border-radius: 5px;
  background-color: #364ed4;
  padding: 8px 10px;
  color: #fff;
  font-size: 14px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 100;
  border: none;
  display: none;
}
@media(min-width: 992px){
  .account-content__tags{
    padding: 0 30px 15px 30px;
  }
  .messages-wgt{
    padding: 10px 30px 5px 30px;
  }
  .messages-wgt:hover .messages-wgt__reply-btn{
    display: block;
    box-shadow: 0px 5px 5px 0px rgba(54,78,212,0.2);
  }
}
.messages-wgt__read,
.messages-wgt__inner_opened{
  background-color: #f1f4f5;
}
.messages-wgt__read .messages-wgt__content p{
  font-weight: 500;
}
.messages-wgt:first-child{
  border-top: 1px solid #dde8ee;
}
.messages-wgt__inner{
  display: flex;
  align-items: flex-start;
}
.messages-wgt__icon-wrap{
  position: relative;
  display: none;
}
@media(min-width: 992px){
  .messages-wgt__icon-wrap{
    display: block;
  }
}
.messages-wgt__icon-wrap .tooltip-info__content{
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999;
  top: calc(100% + 10px);
  width: auto;
  white-space: nowrap;
}
.messages-wgt .tooltip-info span{
  width: 33px;
  height: 33px;
  min-width: 33px;
}
.messages-wgt__icon{
  width: 33px;
  height: 33px;
  min-width: 33px;
  display: block;
}
.messages-wgt__content{
  flex: 1;
  padding-left: 10px;
}
.messages-wgt__content-txt{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.messages-wgt__content-txt-inner{
  width: 95%;
}
.messages-wgt__content-txt-inner p{
  word-wrap: break-word;
}
.messages-wgt__content-info{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media(min-width: 992px){
  .messages-wgt__content-info{
    align-items: flex-start;
  }
}
.messages-wgt__name{
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
.messages-wgt__mob-icon .messages-wgt__icon{
  width: 22px;
  height: 22px;
  margin-left: -5px;
}
@media(min-width: 992px){
  .messages-wgt__name{
    display: flex;
    font-weight: normal;
  }
  .messages-wgt__mob-icon{
    display: none;
  }
}
.messages-wgt__date{
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #808a8e;
}
.messages-wgt__content p{
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}
.messages-wgt__response{
  padding-top: 10px;
  display: none;
}
.messages-wgt__response.active{
  display: block;
}
.messages-wgt__response-info{
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
}
.messages-wgt__response-name,
.messages-wgt__response-date{
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #808a8e;
}
.messages-wgt__response-inner{
  border: 1px solid #c9d3d8;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}
.messages-wgt__inner_opened .messages-wgt__content p{
  font-weight: 500 !important;
}
.messages-wgt__reply-btn-mob{
  background: none;
  border: none;
  padding: 0;
  color: #364ed4;
  font-size: 14px;
  margin-top: 5px;
  position: relative;
  z-index: 1000;
}
@media(min-width: 992px){
  .messages-wgt__reply-btn-mob{
    display: none;
  }
}
.message-wgt{
  border: 1px solid #e5e5e5 ;
  border-radius: 5px;
  padding: 12px 15px;
  margin-bottom: 15px;
  position: relative;
}
.message-wgt:hover{
  border: 1px solid #364ed4;
}
.message-wgt__inner_close:hover{
  cursor: pointer;
}
.message-wgt__overlay{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: none;
}
.message-wgt-item{
  border: 1px solid #afb2ba;
  border-radius: 5px;
  padding: 12px 15px;
  margin-top: 15px;
}
.message-wgt-item__name{
  font-size: 14px;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;
}
.message-wgt-item__description{
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 5px;
}
.message-wgt__text {
  line-height: 1.5;
}
.message-wgt-item__answer{
  border-radius: 3px;
  background-color: #f0f0f0;
  padding: 10px 15px;
}
.message-wgt-item__answer-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  color: #808a8e;
}
.message-wgt-item__answer-text{
  font-size: 14px;
  line-height: 1.5;
  color: #000;
}
.message-wgt-popup__nav{
  padding-top: 15px;
}
.message-wgt-popup__nav-btn:first-child{
  margin-bottom: 15px;
}
@media(min-width: 768px){
  .message-wgt__overlay{
    display: none !important;
  }
}
.message-wgt__header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}
.message-wgt__info{
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.massage-tag__mob{
  display: flex !important;
  padding-top: 10px;
}
.massage-tag__mob .message-tag__item:first-child{
  padding-left: 0;
}
@media(min-width: 768px){
  .message-wgt__info{
    width: auto;
    justify-content: flex-start;
  }
  .massage-tag__mob{
    display: none !important;
  }
}
.message-wgt__name{
  font-size: 15px;
  font-weight: 500;
  color: #000;
  margin-right: 10px;
}
.message-wgt__name_new{
  font-weight: bold;
}
.message-wgt__date{
  font-size: 13px;
  color: #808a8e;
}
.massage-tag{
  display: none;
  list-style-type: none;
}
@media(min-width: 768px){
  .massage-tag{
    display: flex;
    align-items: center;
  }
}
.message-tag__item{
  padding-left: 5px;
}
.message-tag__label{
  display: block;
  font-size: 12px;
  padding: 3px 10px;
  border-radius: 16px;
}
.message-tag__label_message{
  background-color: #cff1df;
  color: #1a5f30;
}
.message-tag__label_bonus{
  background-color: #fae4d0;
  color: #82491e;
}
.message-tag__label_answer{
  background-color: #ecefff;
  color: #364ed4;
}
.message-wgt__read{
  border-color: #e0e5e6;
  background-color: #f2f4f5;
}
.message-wgt__not-read .message-wgt__name,
.message-wgt__not-read .message-wgt__text{
  font-weight: bold;
}
.message-wgt__text_opened{
  font-weight: 500 !important;
}
.message-wgt__text{
  font-weight: 500;
  padding-bottom: 10px;
  font-size: 14px;
}
.message-wgt__text_new{
  font-weight: bold;
}
.message-wgt__inner_opened .message-wgt__name{
  font-weight: 500 !important;
}
.message-wgt__answer{
  background-color: #e0e5e6;
  border-radius: 3px;
  padding: 10px;
}
.message-wgt__answer-header{
  color: #808a8e;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
}
.message-wgt__answer-text{
  font-size: 14px;
}
.answer-form__label{
  background-color: #f0f0f0;
  font-size: 14px;
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 10px 15px;
  font-weight: 500;
}
.answer-form__nav-col:first-child {
    margin-bottom: 15px;
}
@media(min-width: 768px){
  .answer-form__nav{
    display: flex;
    align-items: center;
    margin: 0 -5px;
  }
  .answer-form__nav-col{
    width: 50%;
    padding: 0 5px;
    margin-bottom: 0;
  }
}
.answer-form__nav .field-wrap__submit{
  padding-top: 0;
}
.faq-accordion .accordion__button{
  background: none;
  font-size: 17px;
  font-weight: bold;
  color: #000;
  position: relative;
  padding: 15px 0 15px 20px;
}
.faq-accordion .accordion__button::before{
  position: absolute;
  top: 16px;
  left: 0;
  margin-right: 5px;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
}
.accordion__button_empty::before{
  color: #d5d5d5;
}
.accordion__button[aria-expanded='true']::before, .accordion__button[aria-selected='true']::before{
    transform: rotate(-135deg);
    top: 22px;
}
.faq-accordion .accordion__item{
  position: relative;
  border-bottom: 1px solid #ededed;
  padding-right: 50px;
}
.faq-accordion .accordion__item:first-child{
  border-top: 1px solid #ededed;
}
.faq-accordion .accordion__item + .accordion__item{
  border-top: none;
}
.faq-accordion .accordion__panel{
  padding: 0;
}
.faq-item{
  border-radius: 5px;
  border: 1px solid #e1e3eb;
  padding: 13px 18px;
  margin-bottom: 10px;
}
.faq-item__headline{
  font-size: 17px;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;
  font-family: 'SF-Pro-Display-Bold', sans-serif;
  display: flex;
  justify-content: space-between;
}
.faq-item__content{
  font-size: 14px;
  line-height: 1.5;
  color: #000;
  font-family: 'SF-Pro-Display-Regular', sans-serif;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.faq-item__content-inner{
  overflow: hidden;
}
.faq-item__content-img{
  padding-left: 10px;
  position: relative;
}
.faq-item__content-img img{
  max-width: 41px;
  height: auto;
  display: block;
  border-radius: 5px;
  cursor: pointer;
}
img.faq-item__content-img-scale{
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
  border-radius: 2px;
  max-width: 134px;
  height: auto;
  display: block;
}
.faq-item__content strong{
  font-family: 'SF-Pro-Display-Bold', sans-serif;
}
.faq-item__content ul,
.faq-item__content ol{
  padding-left: 30px;
}
.faq-item-nav{
  display: flex;
  align-items: center;
  margin: 10px -15px 0 -15px;
  list-style-type: none;
}
.faq-item-nav__item{
  position: relative;
  padding: 0 15px;
}
.faq-item-nav__item_remove{
  display: flex;
  align-items: center;
}
.faq-item-nav__item_remove .loader svg path,
.faq-item-nav__item_remove .loader svg rect{
  fill: #e00b48;
}
.faq-item-nav__item_remove .loader{
  width:  17px;
  height: 17px;
  padding: 0;
  margin: 0;
  vertical-align: unset;
  margin-left: 10px;
}
.faq-item-nav__link{
  font-size: 14px;
  background: none;
  border: none;
  font-family: 'SF-Pro-Display-Regular', sans-serif;
}
.faq-item-nav__link_edit{
  color: #364ed4;
  font-family: 'SF-Pro-Display-Regular', sans-serif;
  font-weight: 400;
}
.faq-item-nav__link_remove{
  color: #e00b48;
}
.edit-category-btn{
  background: none;
  border: none;
  color: #364ed4;
  font-size: 14px;
  margin-right: 10px;
}
.faq-accordion .accordion__button{
  display: flex;
  align-items: center;
}
.faq-accordion .accordion__item:hover .faq-accordion__btn-wrap{
  display: flex;
}
.faq-accordion__btn-wrap{
  position: absolute;
  z-index: 99998;
  top: 15px;
}
.faq-accordion__btn-wrap.active{
  z-index: 99999;
}
.accordion__button-inner{
  display: flex;
  align-items: flex-start;
  font-family: 'SF-Pro-Display-Bold', sans-serif;
}
@media(min-width: 768px){
  .faq-accordion__btn-wrap{
    display: none;
  }
}
@media(max-width: 767px){
  .faq-accordion__btn-wrap{
    top: 20px;
    left: auto !important;
    right: 40px !important;
  }
}
.faq-accordion__btn-nav{
  background-color: #edeff6;
  border: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 7px;
}
.faq-accordion__icon{
  width: 20px;
  height: 17px;
  fill: #000;
  display: block;
}
.faq-accordion__btn-dropdown{
  background: none;
  border: none;
}
.faq-accordion-content{
  position: absolute;
  left: 35px;
  top: 0;
  list-style-type: none;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  padding: 5px 7px;
  z-index: 99999;
}
@media(max-width: 767px){
  .faq-accordion-content{
    top: 100%;
    left: auto;
    right: calc(100% - 30px);
  }
}
.faq-accordion-content__item{
  padding: 3px 0;
}
.categories-faq-wgt__inner{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.categories-faq-wgt .categories-faq-wgt__input-btn{
  background-color: #364ed4;
  border: none;
  width: 34px;
  height: 34px;
  margin-left: 5px;
  padding: 7px;
  border-radius: 2px;
}
.categories-faq-wgt .categories-faq-wgt__input-btn svg{
  fill: #fff;
  width: 100%;
  height: 100%;
  display: block;
}
.categories-faq-wgt__name{
  font-size: 16px;
  color: #000;
  margin: 8px 0 8px 14px;
  font-weight: 500;
  max-width: 75%;
  word-wrap: break-word;
}
.categories-faq-wgt__nav-btn-close{
  width: 12px;
  height: 12px;
  stroke: #364ed4;
  display: block;
}
.faq-accordion-content__btn{
  background: none;
  border: none;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 5px;
}
.faq-accordion-content__btn_remove{
  color: #e00b48;
}
.faq-accordion-content__btn_icon{
  display: block;
  width: 20px;
  height: 20px;
  stroke: #000;
  margin-right: 10px;
}
.faq-accordion-content__btn_remove .faq-accordion-content__btn_icon{
  stroke: #e00b48;
}
.categories-faq-wgt__nav_sort{
  cursor: move;
}
.delete-category-form__description{
  font-size: 14px;
  padding-bottom: 10px;
  font-weight: 500;
  line-height: 1.5;
  word-break: break-word;
}
.categories-faq-wgt{
  padding: 4px;
  margin: 5px 0;
  border-radius: 2px;
  background-color: #f7f7f7;
}
.categories-faq-wgt__input{
  height: 34px;
  border: 1px solid #e1e1e1;
  border-radius: 2px;
  font-size: 16px;
  padding: 0 12px;
  flex: 1;
}
.categories-faq-wgt__input_error{
  border-color: #ff0000;
}
.categories-faq-wgt__nav{
  display: flex;
  align-items: center;
  margin-right: 14px;
  padding-left: 15px;
}
.categories-faq-wgt__nav-btn{
  background: none;
  border: none;
  margin-left: 10px;
}
.categories-faq-wgt__nav-btn:first-child{
  margin-left: 0;
}
.categories-faq-wgt__nav-btn-add{
  background-color: #364ed4;
  border-radius: 2px;
  width: 34px;
  height: 34px;
}
.categories-faq-wgt__nav-btn-add_error{
  background-color: #e74426;
}
.categories-faq-wgt__nav-btn svg{
  width: 16px;
  height: 17px;
  stroke: #364ed4;
}
.categories-faq-wgt__nav-btn-add_error svg{
  stroke: #ff0000;
}
.categories-faq-wgt__nav-btn-add svg{
  fill: #fff;
  width: 18px;
  height: 14px;
}
@media(min-width: 768px){
  .user-info__nav,
  .product-info__nav{
    margin: 0 -15px;
  }
  .user-info__nav-col,
  .product-info__nav-col{
    padding: 0 15px;
  }
  .product-info__nav{
    padding-top: 15px;
    display: flex;
    margin: 0 -5px;
  }
  .product-info__nav-col{
    width: 50%;
    flex: 0 0 auto;
    padding: 0 5px;
  }
  .user-info__nav_decline{
    margin: 0 -5px;
  }
  .user-info__nav-col_decline{
    padding: 0 5px;
  }
}
@media(max-width: 767px){
  .user-info__nav-btn,
  .user-info__balance-add-btn{
    padding-left: 15px;
    padding-right: 15px;
  }
}
.history-balance-wrap{
  width: 100%;
  padding-left: 70px;
}
.history-balance{
  width: 100%;
  max-width: 100%;
  text-align: left;
  border-spacing: 0px;
  border-collapse: collapse;
  overflow-x: auto;
}
.history-balance tr:hover{
  background-color: #f4f4f5;
}
.history-balance tr th{
  color: #626e77;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.history-balance tr td{
  color: #000;
  font-size: 14px;
}
.history-balance tr th:first-child,
.history-balance tr td:first-child{
  position: absolute;
  left: 0;
  background-color: #fff;
  min-width: 70px;
  border-right: 1px solid #e1e1e1;
}
.history-balance tr th{
  background-color: #f6f6f6;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}
.history-balance tr th:first-child{
  background-color: #f6f6f6;
  border-bottom: 1px solid #e1e1e1;
  margin-top: -1px;
}
.history-balance tr:last-child td:first-child{
  border-bottom: 1px solid #e1e1e1;
}
.history-balance__balance{
  font-weight: bold;
}
.history-balance__balance-edit{
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: #808a8e;
}
.history-balance__balance-minus{
  color: #d01a36;
}
.history-balance__balance-plus{
  color: #00ae4c;
}
.history-balance__return-btn{
  background: none;
  border: none;
}
.history-balance__return-icon{
  width: 16px;
  height: 14px;
  stroke: #364ed4;
}
.history-balance-return-label{
  padding-bottom: 20px;
}
.history-balance__icon-wrap{
  display: flex;
  align-items: center;
}
.history-balance__icon{
  width: 18px;
  height: 18px;
  display: block;
  margin-right: 5px;
}
@media(min-width: 576px){
  .history-balance-wrap{
    padding-left: 0;
  }
  .history-balance tr th:first-child,
  .history-balance tr td:first-child{
    position: static;
    border: none;
    background: none;
  }
  .history-balance tr:last-child td:first-child{
    border-bottom: none;
  }
  .history-balance tr th{
    background: none;
    border: none;
  }
}
.accrual-form-wrap{
  padding: 10px;
}
@media(min-width: 992px){
  .accrual-form-wrap{
    padding: 5px 30px 30px 30px;
  }
}
.field-wrap__input_accrual{
  height: 100px;
  resize: none;
  font-family: sans-serif;
}
.field-wrap__amount{
  position: relative;
  max-width: 300px;
}
.field-wrap__balance-icon{
  position: absolute;
  top: 13px;
  right: 10px;
}
.add-user-form__btn{
  background: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px dashed #364ed4;
  color: #364ed4;
  font-size: 14px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
.add-user-form__add-btn{
  background: none;
  border: 1px solid #364ed4;
  border-radius: 20px;
  padding: 10px 15px;
  color: #364ed4;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 20px;
}
.add-user-form__add-icon{
  width: 14px;
  height: 12px;
  fill: #2c2f3f;
  margin-left: 5px;
}
.add-user-form__info{
  padding-top: 15px;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 15px;
}
.add-user-form__info_top{
  border-bottom: none;
  margin-bottom: 0;
}
.success-add-user{
  text-align: center;
}
.success-add-user__icon{
  margin-bottom: 15px;
}
.success-add-user__headline{
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
}
.success-add-user__mail{
  font-size: 18px;
  color: #364ed4;
  font-weight: bold;
}
.register-form-wgt__header{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.register-form-wgt__header img{
  max-width: 80px;
}
.success-wrapper{
  text-align: center;
}
.success-wrapper__inner{
  width: 60%;
  max-width: 570px;
  margin: 0 auto;
}
.success-wrapper__img{
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}
.success-wrapper__headline{
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 15px;
}
.success-wrapper__description{
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 40px;
}
.success-app-links{
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.success-app-links__item{
  padding: 0 10px 20px 10px;
}
.success-app-links__logo{
  width: 180px;
  height: auto;
}
.empty-wrapper{
  text-align: center;
}
.empty-wrapper__inner{
  max-width: 420px;
}
.empty-wrapper__img{
  margin-bottom: 20px;
}
.empty-wrapper__headline{
  margin-bottom: 15px;
  font-size: 24px;
}
.empty-wrapper__description{
  margin-bottom: 25px;
}
.tea,
.tgl {
  display: none;
}
.tgl, .tgl:after, .tgl:before, .tgl *, .tgl *:after, .tgl *:before, .tgl + .tgl-btn {
  box-sizing: border-box;
}
.tgl::selection, .tgl:after::selection, .tgl:before::selection, .tgl *::selection, .tgl *:after::selection, .tgl *:before::selection, .tgl + .tgl-btn::selection {
	 background: none;
}
 .tgl + .tgl-btn {
	 outline: 0;
	 display: block;
	 width: 40px;
	 height: 15px;
	 position: relative;
	 cursor: pointer;
	 user-select: none;
}
 .tgl + .tgl-btn:after, .tgl + .tgl-btn:before {
	 position: relative;
	 display: block;
	 content: "";
	 width: 20px;
	 height: 20px;
   margin-top: -5px;
   border: 1px solid #cecece;
}
 .tgl + .tgl-btn:after {
	 left: -1px;
}
 .tgl + .tgl-btn:before {
	 display: none;
}
 .tgl:checked + .tgl-btn:after {
	 left: 50%;
}
 .tgl-light + .tgl-btn {
	 background: #f0f0f0;
	 border-radius: 2em;
	 padding: 2px;
	 transition: all 0.4s ease;
}
 .tgl-light + .tgl-btn:after {
	 border-radius: 50%;
	 background: #fff;
	 transition: all 0.2s ease;
}
 .tgl-light:checked + .tgl-btn {
	 background: #24ce8b;
}
.styled-checkbox {
	 position: absolute;
	 opacity: 0;
}
.styled-checkbox__icon{
  width: 20px;
  height: 15px;
  display: block;
  margin-left: 5px;
}
 .styled-checkbox + label {
	 position: relative;
	 cursor: pointer;
	 padding: 0;
   display: flex;
   align-items: center;
}
 .styled-checkbox + label:before {
	 content: '';
	 display: inline-block;
	 vertical-align: text-top;
	 width: 20px;
	 height: 20px;
	 background: #fff;
   border: 1px solid #e1e1e1;
   border-radius: 3px;
}
 .styled-checkbox:checked + label:before {
	 background: var(--main-color);
}
 .styled-checkbox:disabled + label {
	 color: #b8b8b8;
	 cursor: auto;
}
 .styled-checkbox:disabled + label:before {
	 box-shadow: none;
	 background: #ddd;
}
 .styled-checkbox:checked + label:after {
	 content: '';
	 position: absolute;
	 left: 5px;
	 top: 9px;
	 background: white;
	 width: 2px;
	 height: 2px;
	 box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
	 transform: rotate(45deg);
}
.account-wgt__catalog,
.account-wgt__orders,
.account-wgt__board,
.account-wgt__bonuses,
.account-wgt__events,
.account-wgt__questions,
.account-wgt__library,
.account-wgt__users,
.account-wgt__history{
  padding: 5px 10px 15px 10px;
}
@media(max-width: 991px){
  .account-wgt__users .data-table thead{
    display: none;
  }
}
.account-wgt__users,
.account-wgt__questions,
.account-wgt__settings,
.account-wgt__library
.account-wgt__history{
  padding: 15px 10px;
}
.account-wgt__library,
.account-wgt__library .account-content__content_empty{
  height: 100%;
}
.account-content__content{
   flex: 1 0 auto;
}
.account-content__content-company{
  background-color: #f2f4f5;
}
.account-content__wgt-company{
  background-color: #fff;
}
.account-content__content strong{
  font-family: 'SF-Pro-Display-Bold', sans-serif;
}
.account-content__content-user{
  background-color: #f2f4f5;
}
.account-content__content-inner{
  padding: 30px;
}
.account-content__content__question{
  padding: 10px;
}
@media(min-width: 992px){
  .account-content__content__question{
    padding: 20px 30px;
  }
}
.account-content__content_empty{
  display: flex;
  align-items: center;
  justify-content: center;
}
.account-content__footer{
  flex: 0 0 auto;
  padding: 30px;
}
.account-content__content-header{
  display: none;
}
.account-content__content-inner-products{
  padding: 0 9px;
}
@media(min-width: 992px){
  .account-content__content-header{
    display: flex;
    justify-content: space-between;
  }
  .account-content__content-inner-products{
    padding: 5px 30px 20px 30px;
  }
}
.dropdown__account-sort .dropdown__btn{
  color: #000;
  font-size: 14px;
  border: 1px solid #d7d9e4;
  padding: 10px 20px;
  border-radius: 5px;
  height: 36px;
}
.dropdown__account-survey .dropdown__btn{
  color: #000;
  font-size: 14px;
  border-radius: 5px;
}
.dropdown__account-sort .dropdown__icon{
  stroke: #000;
  margin-left: 15px;
  margin-top: 2px;
  display: block;
}
.dropdown__account-survey .dropdown__icon{
  margin-left: 5px;
}
.dropdown__account-sort .dropdown-content,
.dropdown__account-survey .dropdown-content {
  width: 100%;
  top: 100%;
  z-index: 1000;
  background: none;
}
.dropdown__account-sort .dropdown-content .dropdown-nav,
.dropdown__account-survey .dropdown-content .dropdown-nav {
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%);
  background-color: #fff;
  padding: 5px 7px;
}
.dropdown__account-sort .dropdown-content .dropdown-nav .dropdown-nav__link,
.dropdown__account-survey .dropdown-content .dropdown-nav .dropdown-nav__link{
  background-color: #fff;
  color: #000;
  font-size: 14px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #dde1e4;
  display: block;
  width: 100%;
  text-align: left;
}
.dropdown__account-sort .dropdown-content .dropdown-nav .dropdown-nav__link:hover,
.dropdown__account-survey .dropdown-content .dropdown-nav .dropdown-nav__link:hover{
  background-color: #f1f2f6;
}
.dropdown__account-sort .dropdown-content .dropdown-nav .dropdown-nav__item:last-child .dropdown-nav__link,
.dropdown__account-survey .dropdown-content .dropdown-nav .dropdown-nav__item:last-child .dropdown-nav__link{
  border-bottom: none;
}
.dropdown__account-sort .dropdown-content{
  padding-right: 0;
  padding-left: 0;
}
.dropdown__questions .dropdown-nav__link.dropdown-nav__link-tooltip{
  display: flex !important;
  align-items: center;
}
.dropdown__questions .dropdown-content{
  min-width: 220px !important;
}
.pagination{
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination__item{
  padding: 0 5px;
}
.pagination__link{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: 1px solid #e1e3eb;
  border-radius: 5px;
  color: #000;
  font-size: 14px;
  background: none;
}
.pagination__link.active{
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
  color: #fff;
}
.pagination__link.pagination__link_prev svg,
.pagination__link.pagination__link_next svg{
  width: 8px;
  height: 13px;
}
.dropdown{
  position: relative;
}
.dropdown__btn{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: none;
  border: none;
  color: #fff;
  font-size: 16px;
}
.dropdown__btn-name{
  display: flex;
  align-items: center;
}
.dropdown__icon{
  width: 10px;
  height: 14px;
  stroke: #fff;
}
.dropdown-content{
  padding: 10px;
  background-color: #3e3f48;
  border-radius: 5px;
  position: absolute;
  bottom: 100%;
  left: auto;
  right: 0;
  margin-bottom: 10px;
  display: none;
  z-index: 10;
}
.dropdown-content.active{
  display: block;
}
.dropdown-content.dropdown-content__main-menu{
  top: 100%;
  bottom: auto;
  right: auto;
  left: 35px;
}
.dropdown-nav{
  list-style-type: none;
}
.dropdown-nav__link{
  display: block;
  padding: 10px;
  color: #fff;
  border-radius: 2px;
  transition: 200ms all;
}
.dropdown-nav__link.active{
  background-color: #50515d;
}
.auth-form__sep{
  border-bottom: 1px solid #e1e1e1;
  margin-top: 10px;
  margin-bottom: 15px;
}
.team-table__balance-icon,
.questions-table__balance-icon{
  width: 11px;
  height: 11px;
  fill: #000;
  margin-left: 5px;
}
.edit-user-form__data-login{
  padding-top: 30px;
}
.edit-user-form .field-wrap__label{
  color: #808a8e;
}
.edit-user-form__radio{
  display: flex;
  padding-top: 10px;
}
.edit-user-form__radio-item{
  padding-right: 30px;
}
.edit-user-form__headline{
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}
.edit-user-form__row{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.edit-user-form__col{
  padding: 0 10px;
  flex: 0 0 auto;
}
.edit-user-form__col_size_1{
  width: 100%;
}
.edit-user-form__col_size_3{
  width: 100%;
}
@media(min-width: 768px){
  .edit-user-form__col_size_3{
    width: 50%;
  }
}
@media(min-width: 1200px){
  .edit-user-form__col_size_3{
    width: 33.33333%;
  }
}
.edit-user-form__activation-wrap{
  display: flex;
  align-items: center;
}
.edit-user-form__activation{
  color: #8e8e8e;
  font-size: 16px;
  padding-top: 10px;
  display: flex;
  align-items: center;
}
.edit-user-form__activation-icon{
  width: 17px;
  height: 17px;
  stroke: #fff;
  vertical-align: middle;
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
  display: block;
}
@media(max-width: 992px){
  .team-table .edit-user-form__activation-icon{
    display: none;
  }
}
@-moz-keyframes spin {
    100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform:rotate(360deg);
    }
}
.edit-user-form__imgs{
  padding-top: 10px;
}
.upload__icon{
  width: 24px;
  height: 24px;
  stroke: #364ed4;
  display: block;
  margin-bottom: 10px;
}
.edit-user-upload input{
  visibility: hidden;
  height: 0;
}
.edit-user-upload label,
.edit-user-upload .edit-user-upload__percent{
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f7f8ff;
  color: #364ed4;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
  top: -20px;
}
.edit-user-upload__rewards label{
  top: 0;
}
.edit-user-form__imgs_rewards{
  padding-top: 0;
}
.edit-user-upload__percent{
  top: 0 !important;
  height: 140px  !important;
}
.edit-user-upload__bar{
  border-radius: 3px;
  height: 5px;
  background-color: #dbdded;
  overflow: hidden;
  width: 90%;
  margin-top: 7px;
}
.edit-user-upload__bar-inner{
  height: 100%;
  background-color: #364ed4;
}
.edit-product-img{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}
.edit-product-img:last-child{
  margin-right: 0;
}
.edit-product-img__remove{
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.32);
  border: 1px solid #dde1e4;
}
.edit-product-img__remove::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
}
@media(min-width: 992px){
  .edit-product-img__remove{
    display: none;
  }
}
.edit-product-img__remove-icon{
  width: 10px;
  height: 10px;
  stroke: #000;
}
.product-items{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -9px;
  position: relative;
}
.edit-product-img div{
  width: 50%;
  height: auto;
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
  cursor: move;
  position: relative;
}
.edit-product-img div:hover .edit-product-img__remove{
  display: flex;
  align-items: center;
  justify-content: center;
}
.edit-product-img-wrap{
  display: flex;
  align-items: center;
}
.edit-product-img-wrap img{
  display: block;
  width: 100%;
}
.edit-product-img__logo div{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
}
.edit-product-img__logo .upload-logo-company{
  max-width: 140px;
  max-height: 140px;
  height: auto;
}
.sort-img{
  max-width: 140px;
  max-height: 140px;
}
@media(max-width: 767px){
  .edit-product-img div:nth-child(odd){
    padding-left: 0;
  }
  .edit-product-img div:nth-child(even){
    padding-right: 0;
  }
}
@media(min-width: 768px){
  .edit-user-form__imgs-inner{
    display: flex;
    flex-wrap: wrap;
  }
  .edit-user-upload{
    width: 140px;
    height: 140px;
    margin-right: 20px;
  }
  .edit-user-upload label{
    width: 140px;
    height: 140px;
  }
  .edit-product-img div{
    width: 140px;
    height: 140px;
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 0;
    margin-right: 10px;
    margin-left: 10px;
    display: flex;
  }
  .edit-product-img div:first-child{
    margin-left: 0;
  }
}
.product-num{
  position: absolute;
  left: 5px;
  bottom: 10px;
  background-color: #fff;
  font-size: 12px;
  color: #000;
  border-radius: 50%;
  width: 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.product-label{
  position: absolute;
  background-color: #000;
  color: #fff;
  font-size: 12px;
  left: 7px;
  bottom: 13px;
  border-radius: 10px;
  display: inline-block;
  padding: 3px 10px 3px 25px;
  z-index: 1;
}
@media(min-width: 992px){
  .product-items{
    padding-top: 10px;
  }
}
.product-wgt{
  width: 50%;
}
.product-wgt__disabled{
  opacity: 0.5;
}
.product-wgt__disabled:hover{
  opacity: 1;
}
.product-wgt__inner{
  padding: 7px;
  border: 1px solid #dadada;
  min-height: 330px;
  flex-direction: column;
  display: flex;
  height: 100%;
}
@media(max-width: 991px){
  .product-wgt:nth-child(even) .product-wgt__inner{
    border-right: 0;
  }
  .product-wgt:nth-child(odd) .product-wgt__inner{
    border-left: 0;
  }
}
@media(max-width: 1199px){
  .product-wgt:nth-child(even) .product-wgt__inner{
    border-left: 0;
  }
  .product-wgt.product-wgt__border-top-none .product-wgt__inner{
    border-top: none;
  }
}
.product-wgt__img-wrap{
  width: 100%;
  overflow: hidden;
}
.product-wgt__img-wrap-inner{
  padding-top: 100%;
  position: relative;
}
.product-wgt__img-wrap-content{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-wgt__img{
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 8px;
  cursor: pointer;
}
.product-wgt__content-wrap{
  flex: 1 0 auto;
  padding-top: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.product-wgt__headline{
  font-size: 14px;
  font-weight: 600;
  color: #000;
  display: block;
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer;
}
.product-wgt__content{
  padding-bottom: 15px;
  text-align: center;
  flex: 1 0 auto;
}
.product-wgt__price{
  font-size: 16px;
  font-weight: 900;
  color: #000;
}
.product-wgt__price-icon{
  width: 12px;
  height: 11px;
  fill: #000;
  margin-left: 5px;
}
.product-wgt__amount{
  font-size: 13px;
  color: #919191;
  display: none;
}
.product-wgt__nav{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;
}
@media(min-width: 1200px){
  .product-wgt{
    width: 25%;
    padding: 9px;
  }
  .product-wgt__inner{
    border-radius: 5px;
  }
  .product-wgt__headline{
    text-align: left;
  }
  .product-wgt__content{
    display: flex;
    justify-content: space-between;
    text-align: left;
  }
  .product-wgt__amount{
    display: block;
  }
}
@media(min-width: 1440px){
  .product-wgt{
    width: 20%;
  }
}
.footer{
  flex: 0 0 auto;
  padding-top: 60px;
  overflow: hidden;
}
.footer__inner{
  border-top: 1px solid #e5e5e5;
  padding-bottom: 20px;
  background: #fff;
}
.footer-wgt__inner {
  max-height: 0;
  overflow: hidden;
  border-bottom: 1px solid #e5e5e5;
}

.footer-wgt__inner.active {
  max-height: 9999px;
}
.footer-wgt__icon-down{
  width: 12px;
  height: 8px;
  display: block;
  stroke: #3f5057;
}
.footer-wgt__icon-down.active{
  transform: rotate(-180deg);
}
  .footer-wgt__headline-mob{
    padding: 15px 0;
    font-size: 15px;
    font-weight: bold;
    color: #3f5057;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
.footer-wgt__headline{
  font-size: 15px;
  font-weight: bold;
  color: #3f5057;
  margin-bottom: 10px;
  line-height: 12px;
  display: none;
}
@media(min-width: 992px){
  .footer-wgt__headline{
    display: block;
  }
  .footer-wgt__headline-mob{
    display: none;
  }
  .footer__inner{
    padding-top: 20px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  }
  .footer-wgt{
    width: 25%;
    flex: 0 0 auto;
  }
  .footer-wgt__inner{
    max-height: 9999px;
    border-bottom: none;
  }
}
.footer-wgt-menu{
  list-style-type: none;
}
.footer-wgt-menu__item{
  padding: 6px 0;
}
.footer-wgt-menu__link{
  font-size: 15px;
  color: #3f5057;
}
.footer-wgt-apps__inner{
  display: flex;
  align-items: center;
  margin: 0 -4px;
  padding-top: 6px;
}
.footer-wgt-apps__inner-mob{
  padding: 0 10px 10px 10px;
}
@media(min-width: 992px){
  .footer-wgt-apps__inner-mob{
    display: none;
  }
}
.footer-wgt-apps__item{
  padding: 0 4px;
}
.footer-wgt-apps__img{
  width: auto;
  height: 37px;
  display: block;
}
.footer__bottom{
  background-color: #f5f5f5;
  padding: 10px 0;
}
.footer__bottom-inner{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.footer__bottom-wgt{
  width: 33.3333333%;
  flex: 0 0 auto;
}
.footer__bottom-wgt-copy{
  font-size: 15px;
  color: #a3aebb;
}
.footer__bottom-wgt__logo{
  display: none;
}
.footer-wgt__apps{
  display: none;
}
@media(min-width: 992px){
  .footer__bottom-wgt__logo{
    display: flex;
    justify-content: center;
  }
  .footer-wgt__apps{
    display: block;
  }
}
.footer__bottom-wgt__logo-img{
  width: auto;
  max-height: 17px;
  display: block;
}
.footer__bottom-wgt__socials{
  display: flex;
  justify-content: flex-end;
}
.footer-socials{
  display: flex;
  align-items: center;
  list-style-type: none;
}
.footer-socials__item{
  padding: 0 10px;
}
.footer-socials__item:first-child{
  padding-left: 0;
}
.footer-socials__item:last-child{
  padding-right: 0;
}
.footer-socials__icon{
  display: block;
  fill: #A3AEBB;
  width: 20px;
  height: 20px;
}
.footer-socials__icon--youtube{
  width: 21px;
  height: 15px;
}
.footer__account .wrapper{
  max-width: 100% !important;
}
.footer-nav{
  display: flex;
  list-style-type: none;
  margin-bottom: 5px;
}
.footer-nav__item{
  padding-right: 1.875rem;
}
.footer-nav__item:last-child{
  padding-right: 0;
}
.footer-nav__link{
  color: var(--footer-color-nav);
}
.footer__copy{
  color: var(--footer-color-copy);
}
.account-content__header{
  color: #000;
  font-stretch: normal;
  font-style: normal;
  font-weight: 700;
  letter-spacing: normal;
  line-height: .86;
  padding: 15px 10px 10px;
}
@media(min-width: 576px){
  .register-wgt-nav{
    margin: 0 0 20px 0;
  }
  .auth-form-wgt{
    padding: 30px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.07);
    border: solid 1px #e0e0e0;
  }
}
@media(min-width: 992px){
  .header__collapse{
    display: none;
  }
  .login-btn__name,
  .registration-btn__name{
    display: block;
  }
  .header__language{
    display: block;
  }
}
@media(min-width: 768px){
  .header.header__account .header__collapse{
    display: block;
  }
  .login-btn svg,
  .registration-btn svg{
    margin-right: 10px;
  }
  .header__language-content{
    display: none;
  }
  .mobile-menu{
    display: none !important;
  }
  .account-content{
    padding-top: 66px;
  }
  .registration{
    padding-top: 70px;
  }
  .footer-nav{
    margin-bottom: 0;
  }
}
@media(min-width: 992px){
  .header__account{
    display: none;
  }
  .header.header__account .header__collapse{
    display: none;
  }
  .header__language-content{
    display: block;
  }
  .account-content__header-add{
    display: inline-flex;
  }
  .account-content__header-add-mob{
    display: none;
  }
  .auth-form-wgt{
    width: 379px;
  }
  .register-form-wgt{
    width: 40%;
  }
  .account-nav{
    transform: translateX(0);
  }
  .account-nav__close-btn{
    display: none;
  }
  .account-content{
    padding-top: 0;
    padding-left: 250px;
  }
  .account-content__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
  }
  .account-content__header-wrap{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .account-content__wgt{
    padding: 5px 30px 30px 30px;
  }
  .account-wgt__item{
    width: 33.33333%;
  }
  .account-wgt__catalog,
  .account-wgt__orders,
  .account-wgt__board,
  .account-wgt__bonuses,
  .account-wgt__events,
  .account-wgt__questions,
  .account-wgt__library,
  .account-wgt__users,
  .account-wgt__settings,
  .account-wgt__history{
    padding: 5px 15px 30px 15px;
  }
  .account-wgt__questions .account-content__content_empty,
  .account-wgt__questions{
    height: 100%;
  }
  .team-nav{
    display: block;
  }
  .team-table__name_info{
    display: none;
  }
  .team-table__name-val{
    font-weight: normal;
    font-size: 14px;
  }
  .team-table__row_status_disabled .team-table__name,
  .team-table__row_status_disabled .team-table__position,
  .team-table__row_status_disabled .team-table__rights,
  .team-table__row_status_disabled .team-table__balance{
    opacity: 0.3;
  }
  .catalog-table__row_status_disabled .catalog-table__name,
  .catalog-table__row_status_disabled .catalog-table__amount{
    opacity: 0.3;
  }
  .pagination{
    justify-content: flex-start;
  }
  .account-nav-footer-list{
    display: flex;
    flex-wrap: wrap;
  }
  .account-nav__copy{
    display: block;
  }
  .dropdown__account-nav{
    display: none;
  }
  .account-nav__footer::before{
    display: none;
  }
  .question-form__submit{
    display: inline-block;
  }
  .footer_fixed{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99997;
  }
  .footer__account{
    display: none;
  }
}
@media(max-width: 767px){
  .login-btn svg,
  .registration-btn svg{
    stroke: #000;
    width: 25px;
    height: 25px;
  }
}
@media(max-width: 991px){
  .login-btn{
    background: none !important;
    padding: 0 !important;
  }
  .account-nav.active{
    animation: mobileMenu 0.5s ease-in-out forwards;
  }
  .account-nav.disabled{
    animation: mobileMenuDis 0.5s ease-in-out forwards;
  }
  .team-table thead {
    display: none;
  }
  .team-table tr{
    border-bottom: 1px solid #dde1e4;
  }
  .team-table__choose{
    display: none;
  }
  .team-table__position,
  .team-table__team,
  .team-table__email{
    display: none;
  }
  .team-table__name_info{
    display: block;
    padding-top: 5px;
  }
  .team-table__name_rights{
    background-color: #d80000;
    color: #fff;
    display: inline-block;
    margin-left: 10px;
    border-radius: 3px;
    padding: 2px 5px;
    font-size: 12px;
  }
  .team-table__rights{
    display: none;
  }
  .team-table__control{
    display: none;
  }
  .team-table__control.team-table__control-block{
    display: flex;
  }
  .team-table__control.team-table__control-pos{
    display: block;
  }
  .team-table__control .control-btn__icon{
    fill: #f1f2f6;
  }
  .team-table__balance{
    text-align: right;
  }
}

/* Terms page */
.section-terms{
  padding-top: 50px;
}
.section-terms p{
  margin: 15px 0;
  font-size: 16px;
  line-height: 1.5;
}
.section-terms__headline{
  font-size: 30px;
  font-weight: 900;
  color: #000;
  margin-bottom: 20px;
}
.terms-content__headline{
  font-size: 21px;
  color: #000;
  font-weight: bold;
}
@media(min-width: 768px){
  .section-terms{
    padding-top: 80px;
  }
  .section-terms__headline{
    margin-bottom: 40px;
  }
  .section-terms p{
    margin: 30px 0;
  }
}
/* Modal */
#modal-container,
#modal-decline {
  position: fixed;
  display: table;
  height: 100%;
  width: 100%;
  transform: scale(0);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  overflow-y: auto;
}
#modal-decline{
  z-index: 100000;
}
@media(min-width: 768px){
  #modal-container,
  #modal-decline {
    background: none;
  }
}
#modal-decline .modal{
  box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.16);
}
#modal-container .modal-background {
  display: table-cell;
  background: rgba(44, 47, 63, .3);
}
.modal-background__product{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99998;
  background: rgba(44, 47, 63, .3);
}
#modal-container .modal,
#modal-decline .modal {
  background: #fff;
  padding: 15px 30px 25px 30px;
  width: 100%;
  max-width: 100%;
  margin: auto;
  position: relative;
  z-index: 99999;
}
#modal-container.two,
#modal-decline.two {
  transform: scale(1);
}
#modal-container.two.modal__product{
  transform: translate(-50%, -50%) scale(1);
  max-width: 500px;
  top: 50%;
  left: 50%;
}
#modal-container.two .modal-background {
  background: rgba(44, 47, 63, .0);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99998;
}
#modal-container.two .modal,
#modal-decline.two .modal {
  height: 100%;
  animation: fadeIn2 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  opacity: 0;
}
#modal-container.two.disabled .modal-background {
  background: rgba(44, 47, 63, .0);
}
#modal-container.two.disabled .modal,
#modal-decline.two.disabled .modal {
  animation: fadeOut2 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  opacity: 0;
}
@media(min-width: 768px){
  #modal-container .modal-background {
    vertical-align: middle;
  }
  #modal-container.two .modal-background {
    animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    background: rgba(44, 47, 63, .3);
  }
  #modal-container.two.disabled .modal-background {
    background: rgba(44, 47, 63, .3);
  }
  #modal-container.two .modal,
  #modal-decline.two .modal {
    border-radius: 10px;
    height: auto;
    max-width: 500px;
    animation: scaleDown 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.two + .content {
    animation: scaleBack 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.two.disabled .modal-background {
    animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.two.disabled .modal {
    animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
  #modal-container.two.disabled + .content {
    animation: scaleIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
}
.modal-container{
  top: 0;
  left: 0;
}
  #modal-container.modal-container .modal{
  width: 380px;
}
.modal-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  position: relative;
}
.modal-header__center{
  justify-content: center;
}
.modal-header__back-btn{
  background: none;
  border: none;
  position: absolute;
  left: 0;
  top: 5px;
}
.modal-header__back-icon{
  width: 16px;
  height: 12px;
  stroke: #000;
}
.modal-headline{
  font-size: 18px;
  font-weight: bold;
  color: #000;
  position: relative;
}
.modal-description{
  padding-bottom: 15px;
  font-weight: 400;
}
.close-modal{
  background: none;
  border: none;
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -10px;
}
.close-modal:hover{
  background-color: #eaeaea;
}
.close-modal__icon{
  width: 16px;
  height: 16px;
  fill: #000;
  display: block;
}
@keyframes fadeIn {
  0% {
    background: rgba(44, 47, 63, .0);
 }
  100% {
    background: rgba(44, 47, 63, .3);
 }
}
@keyframes fadeOut {
  0% {
    background: rgba(44, 47, 63, .3);
 }
  100% {
    background: rgba(44, 47, 63, .0);
 }
}
@keyframes fadeIn2 {
  0% {
    opacity: 0;
 }
  100% {
    opacity: 1;
 }
}
@keyframes fadeOut2 {
  0% {
    opacity: 1;
 }
  100% {
    opacity: 0;
 }
}
@keyframes scaleUp {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 1;
 }
  100% {
    transform: scale(0.8) translateY(-1000px);
    opacity: 0;
 }
}
@keyframes scaleDown {
  0% {
    transform: scale(0.8) translateY(-1000px);
    opacity: 0;
 }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
 }
}
@keyframes scaleIn {
  0% {
    transform: scale(0.85);
 }
  100% {
    transform: scale(1);
 }
}
@keyframes scaleBack {
  0% {
    transform: scale(1);
 }
  100% {
    transform: scale(0.85);
 }
}
.questions-table,
.board-table,
.events-table{
  display: none;
  width: 100%;
  text-align: left;
  border-spacing: 0px;
  border-collapse: collapse;
}
@media(min-width: 576px){
  .questions-table,
  .board-table,
  .events-table{
    display: table;
  }
  .questions-mob{
    display: none;
  }
}
.questions-mob{
  width: 100%;
}
.question-wgt{
  border: 1px solid #dde1e4;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 5px;
}
.question-wgt__header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}
.question-wgt__name{
  font-size: 16px;
  color: #364ed4;
}
.question-wgt__name .new-order{
  font-weight: bold;
}
.question-wgt__content-row{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}
.question-wgt__content-row_padding{
  padding-bottom: 10px;
}
.question-wgt__content-wgt:first-child .question-wgt__content-wgt-value{
  font-weight: 600;
}
.question-wgt__content-wgt:last-child{
  text-align: right;
}
.question-wgt__content-wgt-label{
  font-size: 12px;
  color: #626e77;
  margin-bottom: 5px;
}
.question-wgt__content-wgt-value{
  font-size: 14px;
  color: #000;
}
.question-wgt__content-wgt-value_status_active{
  color: #01ae4c;
}
.question-wgt__content-wgt-value_status_pending{
  color: #f48814;
}
.question-wgt__content-wgt-value_status_ended{
  color: #757f87;
}
.question-wgt__content-wgt-value_status_delete{
  color: #d01a36;
}
.question-wgt__nav{
  display: flex;
  align-items: center;
}
.question-wgt__nav-btn{
  background: none;
  border: none;
}
.question-wgt__nav-btn:first-child{
  margin-right: 15px;
}
.question-wgt__nav-icon{
  width: 16px;
  height: 17px;
  stroke: #364ed4;
}
.questions-form-wrap,
.event-form-wrap,
.board-form-wrap,
.bonuses-form-wrap,
.library-form-wrap{
  padding: 5px 10px 10px 10px;
}
.questions-form__inner{
  border: 2px solid #e1e1e1;
  border-radius: 10px;
  padding: 15px;
}
.questions-form__row,
.event-form__row,
.board-form__row{
  display: flex;
  flex-wrap: wrap;
}
.board-form__row_padding{
  padding-bottom: 30px;
}
.board-form__row .field-wrap__tooltip{
  padding-top: 0;
}
.field-wrap__tooltip-bonuses{
  margin-top: 20px;
  max-height: 65px;
}
.field-wrap__tooltip-rewards{
  margin-top: 0;
  padding-top: 0 !important;
}
.field-wrap__tooltip-bonuses .tooltip-info__content{
  top: calc(100% - 20px);
}
@media(min-width: 1200px){
  .questions-form__row,
  .event-form__row,
  .board-form__row{
    margin: 0 -7px;
  }
}
.questions-form__row .field-wrap,
.event-form__row .field-wrap,
.board-form__row .field-wrap{
  width: 100%;
}
.questions-form__type{
  display: flex;
  align-items: center;
  padding: 10px 0 20px 0;
}
.questions-form__type label{
  margin-right: 10px;
}
.rewards-form__inner{
  padding: 5px 10px 10px 10px;
  border-bottom: 1px solid #ededed;
}
.rewards-form__inner_border_none{
  border-bottom: none;
}
@media(min-width: 992px){
  .questions-form__inner{
    padding: 25px;
  }
  .questions-form__inner-edit{
    margin-bottom: 20px;
  }
  .questions-form-wrap,
  .event-form-wrap,
  .board-form-wrap,
  .bonuses-form-wrap,
  .library-form-wrap{
    padding: 5px 30px 30px 30px;
  }
  .questions-form__row,
  .board-form__row{
    margin: 0 -7px;
  }
  .rewards-form__inner{
    padding: 20px 30px 10px 30px;
  }
  .rewards-form__inner_border_top{
    padding-top: 5px;
  }
}
@media(min-width: 1200px){
  .questions-form__row .field-wrap,
  .event-form__row .field-wrap,
  .board-form__row .field-wrap{
    width: 33.33333%;
    padding-left: 7px;
    padding-right: 7px;
  }
}
.field-wrap__input_questions{
  height: 80px;
  resize: none;
  font-family: sans-serif;
}
.questions-form__date-wrap{
  display: flex;
  align-items: center;
}
.questions-form__date-wrap .questions-form__date-inner{
  width: 75%;
  margin-right: 5px;
}
.questions-form__date-wrap .questions-form__date-inner ~ div{
  flex: 1;
}
.questions-form__date-inner,
.questions-form__bonus-inner{
  position: relative;
}
.questions-form__date-icon{
  stroke: #afafaf;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}
.questions-form__date-field{
  padding-left: 40px;
}
.questions-form__bonus-icon{
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
.questions-form__bonus-field{
  padding-right: 40px;
}
.questions-form__add-wrap{
  padding-top: 15px;
}
.questions-form__add{
  width: 100%;
  display: block;
  border-radius: 10px;
  border: 2px solid #364ed4;
  background-color: #fff;
  color: #364ed4;
  font-weight: 600;
  padding: 15px;
  font-size: 15px;
}
.questions-form__footer{
  padding-top: 15px;
}
.questions-result{
  padding: 5px 0 10px 0;
}
@media(min-width: 992px){
  .questions-result{
    padding: 5px 0 30px 0;
  }
}
.questions-result__info{
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dde1e4;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}
@media(min-width: 992px){
  .questions-result__info{
    padding-left: 30px;
    padding-right: 30px;
  }
}
.questions-result__info-dropdown{
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}
.questions-result__info-dropdown-headline{
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  margin-right: 5px;
}
.questions-result-dropdown{
  position: relative;
}
.questions-result-dropdown__btn{
  background: none;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #364ed4;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px dashed #364ed4;
}
.questions-result-dropdown__content{
  position: absolute;
  z-index: 100;
  top: 100%;
  left: 0;
  right: auto;
  background-color: #fff;
  display: none;
  min-width: 150px;
}
.questions-result-dropdown__content.active{
  display: block;
}
.questions-result-dropdown__content ul{
  list-style-type: none;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%);
  background-color: #fff;
  padding: 5px 7px;
}
.questions-result-dropdown__content ul li a{
  color: #000;
  display: block;
  padding: 5px 10px;
  font-size: 14px;
}
.questions-result-dropdown__content ul li a:hover{
  background-color: #f1f3ff;
  color: #364ed4;
}
.questions-result__info-headline-mob{
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  padding: 0 10px;
}
.questions-result__info-headline{
  display: none;
  font-size: 18px;
  font-weight: 600;
}
@media(min-width: 992px) {
  .questions-result__info-headline{
    margin-bottom: 10px;
    display: block;
  }
  .questions-result__info-headline-mob{
    display: none;
  }
}
@media(min-width: 992px){
  .questions-result__info-question{
    display: flex;
    align-items: center;
  }
}
.questions-result__users{
  display: flex;
  align-items: center;
  list-style: none;
  margin-right: 10px;
}
.questions-result__users li{
  position: relative;
  z-index: 10;
}
.questions-result__users li img{
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.questions-result__users li:nth-child(2) img{
  left: -10px;
}
.questions-result__users li:nth-child(3) img{
  left: -20px;
}
.questions-result__users li:nth-child(4) img{
  left: -30px;
}
.questions-result__users li:nth-child(5) img{
  left: -40px;
}
.questions-result__users li:nth-child(6) img{
  left: -50px;
}
.questions-result__type{
  font-size: 16px;
  color: #808a8e;
  display: none;
}
.questions-result__share-txt{
  display: none;
}
.questions-result-wgt__headline{
  font-size: 16px;
}
@media(min-width: 992px){
  .questions-result-wgt__headline{
    font-size: 18px;
  }
  .questions-result__type{
    display: block;
  }
  .questions-result__share-txt{
    display: block;
  }
  .questions-result__share-txt-mob{
    display: none;
  }
}
.questions-result__share{
  margin-left: auto;
  display: none;
}
.questions-result-wgt{
  padding: 10px;
  border-bottom: 1px solid #dde1e4;
}
@media(min-width: 992px){
  .questions-result-wgt{
    padding: 30px;
  }
}
.questions-result-wgt:last-child{
  border-bottom: none;
}
.questions-result-wgt__header{
  font-size: 18px;
  font-weight: 600;
  color: #000;
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}
.questions-result-wgt__number{
  margin-right: 20px;
}
.questions-result-option{
  padding: 10px 0;
}
.questions-result-option:last-child{
  padding-bottom: 0;
}
.questions-result-option__header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color: #000;
  padding-bottom: 5px;
}
.questions-result-option__header-inner{
  display: flex;
  align-items: center;
}
.questions-result-option__percent{
  text-align: right;
  color: #000;
  font-size: 16px;
  font-weight: bold;
  width: 40px;
  margin-right: 7px;
}
.questions-result-option__info{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.questions-result-option__amount{
  color: #808a8e;
  border-bottom: 1px dashed #364ed4;
  cursor: pointer;
  font-size: 14px;
}
.questions-result-option__amount-empty{
  color: #808a8e;
  border-bottom: 1px dashed #808a8e;
  font-size: 14px;
}
.questions-result-option__progress-wrap{
  padding-left: 49px;
}
.questions-result-option__progress{
  height: 5px;
  background-color: #e1e1e1;
  border-radius: 5px;
  overflow: hidden;
}
.questions-result-option__progress-inner{
  height: 100%;
  background-color: #364ed4;
}
.questions-result-option__other{
  padding: 5px 0;
}
.questions-result-option__other-avatar img{
  border: 1px solid #fff;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: block;
}
.questions-result-option__other-point{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ededed;
  padding: 5px 15px;
  border-radius: 2px;
  margin: 2px 0;
}
.questions-result-option__free-wrapper{
  padding-left: 49px;
}
.questions-result-option__free-wrapper-quick{
  padding-left: 0;
}
.questions-result-option__free-wgt{
  border-radius: 5px;
  border: solid 2px #ededed;
  padding: 10px 15px;
  margin-top: 10px;
  font-size: 14px;
}
.questions-result-option__free-wgt-photo{
  width: 26px;
  height: 26px;
  display: none;
  border-radius: 50%;
}
.questions-result-option__free-wgt-username{
  display: block;
  color: #808a8e;
  font-size: 14px;
  margin-top: 5px;
}
.questions-result-option__headline{
  font-size: 14px;
}
.questions-result-option__headline ul{
  display: flex;
  list-style-type: none;
}
.questions-result-option__headline ul li{
  padding-right: 10px;
}
.questions-result-option__headline ul li img{
  display: block;
}
@media(min-width: 992px){
  .questions-result-option__free-wgt{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
  }
  .questions-result-option__free-wgt-photo{
    display: block;
  }
  .questions-result-option__free-wgt-username{
    display: none;
  }
  .questions-result-option__headline{
    font-size: 16px;
  }
}
.field-wrap__input_event{
  height: 100px;
  resize: none;
  font-family: sans-serif;
}
.event-form__inner,
.board-form__inner{
  margin-bottom: 5px;
}
.event-edit-category{
  color: #364ed4;
  font-size: 14px;
  background: none;
  border: none;
  margin-top: 10px;
}
.event-form__nav{
  margin: 0 -5px;
}
.event-form__nav button{
  margin: 0 5px;
}
@media(max-width: 768px){
  .event-form__nav button{
    display: block;
    width: 100%;
    margin-bottom: 15px;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
.date-modal{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
}
.date-modal__overlay{
  background-color: rgba(44, 47, 63, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
}
.date-modal__header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}
.date-modal__headline{
  font-size: 18px;
  font-weight: bold;
  color: #000;
  position: relative;
}
.date-modal__close-modal{
  background: none;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -10px;
}
.date-modal__close-modal:hover{
  background-color: #eaeaea;
}
.date-modal__close-modal-icon{
  width: 16px;
  height: 16px;
  fill: #000;
  display: block;
}
.date-modal__content{
  background-color: #fff;
  padding: 25px 30px;
  border-radius: 11px;
  position: fixed;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 450px;
  z-index: 200;
}
.calendar__info{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #eee;
  padding: 5px;
  border-radius: 30px;
  margin-bottom: 20px;
}
.calendar__month-year{
  font-size: 16px;
  font-weight: bold;
}
.calendar__month-nav{
  background-color: #000;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.calendar__month-nav-icon{
  width: 7px;
  height: 14px;
  stroke: #fff;
}
.calendar-table{
  width: 100%;
  border-spacing: 10px;
}
.calendar-table thead tr th{
  font-size: 14px;
  font-weight: bold;
  color: #000;
  padding: 0 5px;
  text-align: center;
}
.calendar-table__dates tr td{
  width: 39px;
  height: 39px;
  background-color: #eee;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: 200ms all;
}
.calendar-table__dates tr td:first-child{
  padding: 0 !important;
}
.calendar-table__dates tr td:hover{
  background-color: #364ed4;
  color: #fff;
}
.calendar-table__dates tr td.ended{
  color: #b7b7b7 !important;
  cursor: default;
}
.calendar-table__dates tr td.ended:first-child{
  color: #b7b7b7 !important;
}
.calendar-table__dates tr td.ended:hover{
  color: #b7b7b7;
  background-color: #eee;
}
.calendar-table__dates tr td.checked{
  background-color: #364ed4;
  color: #fff;
}
.date-modal__nav{
  padding-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.date-modal__nav-item{
  width: 50%;
  flex: 0 0 auto;
  padding: 0 10px;
}
.date-fields{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -15px;
  padding-bottom: 15px;
}
.date-fields__simple .date-field__date{
  width: 100%;
  border-right: 1px solid #e1e1e1;
  text-align: center;
}
.date-field{
  width: 50%;
  flex: 0 0 auto;
  padding: 0 15px;
  position: relative;
  display: flex;
  align-items: center;
}
.date-field__border input{
  border-color: #364ed4 !important;
}
.date-field:first-child::after{
  content: '-';
  position: absolute;
  top: 50%;
  right: -3px;
  transform: translateY(-50%);
}
.date-field__date{
  display: block;
  width: 63%;
  height: 42px;
  border-top: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  border-right: none;
  border-radius: 2px 0 0 2px;
  padding: 12px;
  font-size: 16px;
}
.date-field__time{
  display: block;
  width: 37%;
  height: 42px;
  border: 1px solid #e1e1e1;
  border-radius: 0 2px 2px 0;
  padding: 12px;
  font-size: 16px;
}
.question-info{
  background-color: #f0f1f5;
  padding: 10px 15px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.question-info__headline{
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}
.question-info__content{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.question-info__wgt{
  padding-right: 15px;
}
.question-info__wgt:last-child{
  padding-right: 0;
}
.question-wgt-item{
  border-radius: 10px;
  background-color: #f0f1f5;
  padding: 12px 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.question-wgt-item__name{
  font-size: 18px;
  font-weight: 600;
}
.question-wgt-item__nav{
  display: flex;
  align-items: center;
  margin: 0 -7px;
}
.question-wgt-item__nav-btn{
  margin: 0 7px;
  background: none;
  border: none;
}
.question-wgt-item__nav-icon{
  width: 16px;
  height: 17px;
  display: block;
}
.question-wgt-item__nav-icon_delete{
  fill: #364ed4;
}
.question-wgt-item__nav-icon_sort{
  stroke: #364ed4;
}
.question-form{
  margin-bottom: 10px;
}
.question-form__border{
  padding: 15px;
  border: 2px solid #e1e1e1;
  border-radius: 10px;
}
@media(min-width: 992px){
  .question-form__border{
    padding: 25px;
  }
}
.question-form__header{
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
}
.question-form__num{
  font-size: 18px;
  font-weight: 600;
}
.question-form__btn{
  padding: 5px 15px;
  background-color: #e8ecff;
  border-radius: 15px;
  color: #364ed4;
  border: none;
  font-size: 14px;
}
.question-form__points{
  padding-top: 25px;
}
.question-form__points-header-inner{
  display: flex;
  align-items: center;
}
.field-wrap__search-members{
  position: relative;
  padding-bottom: 0;
}
.field-wrap__search-members .field-wrap__input{
  padding-right: 38px;
}
.field-wrap.field-wrap__search-members.field-wrap__search-members-dropdown .field-wrap__input{
  height: 33px;
  font-size: 14px;
}
.field-wrap__search-icon{
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  z-index: 10;
  width: 17px;
  height: 18px;
  stroke: #626E77;
  margin-top: -7px;
}
.question-members-wgt{
  padding: 5px 0;
  position: relative;
}
.question-members-wgt__result{
  padding-top: 20px;
}
.question-members-wgt__label{
  font-size: 14px;
  color: #808a8e;
  margin-bottom: 10px;
}
.question-members-wgt__item{
  display: flex;
  align-items: center;
  margin: 5px 0;
  cursor: pointer;
  width: 100%;
}
.question-members-wgt__item-inner{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.question-members-wgt__item.checked{
  background-color: #f4f4f8;
  border-radius: 20px;
}
.question-members-wgt__item.checked .question-members-wgt__name{
  font-weight: bold;
}
.question-members-wgt__all{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #e8e9f0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.question-members-wgt__all-icon{
  width: 17px;
  height: 19px;
  stroke: #364ED4;
}
.question-members-wgt__name{
  margin-left: 15px;
  font-size: 16px;
}
.question-members-wgt__img img{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: block;
}
.question-members-wgt__checked{
  width: 20px;
  height: 20px;
  background-color: #00ae4c;
  margin-right: 8px;
  border-radius: 50%;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
}
.select-modal{
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  padding: 4px;
}
.select-modal__input{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
}
@media(min-width: 768px){
  .select-modal__input{
    padding: 0 10px;
  }
}
.select-modal__input input{
  border: none;
  background-color: transparent;
  display: block;
}
.select-modal__item{
  margin: 0 2px;
  color: #364ed4;
  border-radius: 2px;
  background-color: #e8ecff;
  padding: 5px 8px;
}
.select-modal__item span{
  margin-left: 8px;
  cursor: pointer;
}
.question-list-type{
  position: relative;
  display: flex;
  align-items: center;
}
.dropdown__questions{
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  position: static;
}
.dropdown__questions .dropdown-content{
  width: 100%;
}
.dropdown__questions .dropdown__btn-name{
  color: #364ed4;
}
.dropdown__questions .dropdown__icon{
  stroke: #364ed4;
}
.field-wrap__label-questions{
  margin-left: 15px;
  margin-right: 5px;
}

.dropdown__questions .tooltip-info{
  margin-top: 0;
}
.question-form__points-item-nav{
  display: flex;
  align-items: center;
  padding-left: 15px;
}
.question-form__points-item{
  display: flex;
  align-items: center;
  justify-content: center;
}
.question-form__points-item-option{
  flex: 1;
}
.question-form__points-item-radio{
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #000;
  margin-right: 10px;
}
.question-form__points-item-radio span{
  display: block;
  width: 8px;
  height: 8px;
  background-color: #000;
  border-radius: 50%;
}
.question-form__points-item-checked{
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #000;
  margin-right: 10px;
}
.question-form__points-item-checked-icon{
  width: 20px;
  height: 20px;
  margin-right: 10px;
  display: block;
}
.question-form__points-item-checked span{
  color: #000;
  font-size: 12px;
}
.question-wgt-item__nav-icon_close{
  width: 14px;
  height: 14px;
  display: block;
  fill: #364ed4;
}
.users-tab{
  padding-top: 5px;
  border-bottom: 1px solid #dde1e4;
  margin-bottom: 10px;
}
.users-tab__inner{
  display: flex;
  align-items: center;
  padding-bottom: 12px;
}
.users-tab,
.team-nav{
  padding-left: 10px;
  padding-right: 10px;
}
@media(min-width: 992px){
  .users-tab,
  .team-nav{
    padding-left: 30px;
    padding-right: 30px;
  }
}
.users-tab__border{
  position: relative;
}
.users-tab__border::after{
  content: "";
  border-bottom: 1px solid #dde1e4;
  position: absolute;
  left: 0;
  bottom: 12px;
  width: 100%;
}
.users-tab__link{
  margin-right: 25px;
  color: #000;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  position: relative;
  background: none;
  border: none;
  padding: 0;
}
.users-tab__link.disabled{
  color: #717171;
}
.users-tab__link-amount{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #edeff6;
  padding: 2px 6px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  margin-left: 9px;
  min-width: 20px;
  min-height: 20px;
}
.users-tab__link.active{
  color: #364ed4;
}
.users-tab__link.active::after{
  content: "";
  position: absolute;
  bottom: -12px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #364ed4;
  z-index: 1;
}
.team-table__name-team{
  width: 75%;
}
.team-list-modal{
  padding-top: 10px;
  padding-right: 10px;
}
.team-list-modal__thumb{
  padding-right: 10px;
}
.team-list-modal__label{
  font-size: 14px;
  color: #808a8e;
  margin-bottom: 5px;
  font-weight: 500;
  cursor: pointer;
}
.team-list-modal__item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
  position: relative;
  cursor: pointer;
}
.team-list-modal__item .loader{
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  justify-content: flex-end;
  padding: 0;
}
.team-list-modal__item .loader svg{
  height: 20px;
}
.team-list-modal__info{
  display: flex;
  align-items: center;
}
.team-list-modal__avatar-member{
  margin-right: 15px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
}
.team-list-modal__checked{
  width: 20px;
  height: 20px;
  background-color: #00ae4c;
  margin-right: 8px;
  border-radius: 50%;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
}
.choose-type-question.active .choose-type-question__icon{
  border: 2px solid #364ed4;
}
.choose-type-question.active .choose-type-question__field{
  border: 2px solid #364ed4;
  color: #364ed4;
}
.choose-type-question__quick .choose-type-question__inner{
  padding-top: 8px;
}
.choose-type-question__inner{
  display: flex;
  align-items: center;
  cursor: pointer;
}
.choose-type-question__field{
  display: flex;
  align-items: center;
  padding: 0 15px;
  width: 100%;
  border-radius: 2px;
  border: 1px solid #e1e1e1;
  margin-right: 10px;
  height: 42px;
  font-size: 16px;
  font-weight: 500;
}
.choose-type-question__icon{
  width: 42px;
  height: 42px;
  min-width: 42px;
  min-height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e1e1e1;
  border-radius: 2px;
}
.choose-type-question__icon svg{
  width: 18px;
  height: 29px;
}
.quick-question-points{
  padding-top: 20px;
}
.quick-question-points__header{
  display: flex;
  align-items: ;
}
.field-wrap__label-wrap-list-question{
  width: 65%;
  flex: 0 0 auto;
}
.field-wrap__label-wrap-type-question{
  width: 35%;
  flex: 0 0 auto;
  margin-left: 14px;
}
.quick-question-points__inner{
  border: 2px solid #e1e1e1;
  border-radius: 10px;
  padding: 15px;
}
.quick-question-points__headline{
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}
.quick-question-points__description{
  color: #808a8e;
  font-size: 14px;
  margin-bottom: 10px;
}
.quick-question-points__point{
  padding: 5px 0;
}

@media(min-width: 768px){
  .quick-question-points__point{
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }
}

.quick-question-points__add-btn{
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.quick-question-points__max-item{
  color: #e74426;
  font-size: 14px;
  margin-left: 15px;
}
.btn-default{
  background-color: #e8ecff;
  border: none;
  border-radius: 15px;
  color: #364ed4;
  padding: 5px 15px;
}
.quick-question-points__nav .field-wrap{
  padding-bottom: 0;
}

.quick-question-points__nav{
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}

  .quick-question-points__nav .field-wrap{
    flex: 1
  }

  .quick-question-points__field{
    padding-bottom: 5px;
  }

  @media (min-width: 768px){
    .quick-question-points__nav{
      width: 35%;
    }
    .quick-question-points__field{
      width: 65%;
      flex: 0 0 auto;
      padding-bottom: 0;
    }
    .quick-question-points__nav .field-wrap{
      width: 100%;
      padding-left: 14px;
    }
  }

@media (min-width: 992px){
  .quick-question-points__inner{
      padding: 25px;
  }
}
.account-content__result-avatar{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-left: 10px;
}
.questions-user-result{
  padding: 15px 30px;
}
.questions-user-result__header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
}
.questions-user-result__headline{
  font-size: 21px;
  font-weight: bold;
}
.questions-user-result-wgt{
  padding: 18px 0;
  border-bottom: 1px solid #dde1e4;
}
.questions-user-result-wgt:first-child{
  border-top: 1px solid #dde1e4;
}
.questions-user-result-wgt__header{
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 18px;
}
.questions-user-result-wgt__num{
  padding-right: 19px;
}
.questions-user-result-wgt__answer{
  padding: 9px 17px;
  border-radius: 2px;
}
.questions-user-result-wgt__answer.active{
  background-color: #ededed;
}
.user-result-table{
  position: relative;
  border-top: 1px solid #dde1e4;
}
.user-result-table__inner{
  padding: 20px 0;
}
.user-result-table__gradient{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: 30px;
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
}
.user-result-table__row{
  display: flex;
  flex-wrap: nowrap;
}
.user-result-table__row:first-child .user-result-table__cell{
  padding-top: 0;
}
.user-result-table__cell{
  min-width: 195px;
  width: 195px;
  border-right: 1px solid #ededed;
  padding: 20px 25px 7px 25px;
}
.user-result-table__cell:last-child{
  border-right: none;
}
.user-result-table__headline{
  min-width: 280px;
  width: 280px;
  padding-left: 0;
}
.user-result-table__cell-answer{
  border-radius: 5px;
  background-color: #ededed;
  padding: 5px 10px;
  font-size: 13px;
}
.questions-result__report{
  padding: 20px 0;
}
.questions-result__report-date-wrap{
  font-size: 18px;
  font-weight: 600;
  color: #000;
}
.questions-result__report-date-value{
  color: #364ed4;
  position: relative;
  margin-left: 5px;
}
.questions-result__report-date-value::after{
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  z-index: 10;
  width: 100%;
  height: 2px;
  background-color: #364ed4;
}
.questions-result-option__line{
  height: 2px;
}
@media(max-width: 767px){
  .dashboard-content{
    padding-top: 20px;
  }
}
.dashboard-content__statistics{
  padding: 20px 20px 10px 20px;
}
.dashboard-content__statistics-inner{
  border: 2px solid #000;
  border-radius: 5px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.dashboard-content__statistics-wgt{
  padding: 15px 0;
  width: 50%;
  flex: 0 0 auto;
}
.dashboard-content__statistics-wgt_border{
  border-top: 1px solid #dde1e4;
}
@media(min-width: 768px){
  .dashboard-content__statistics-inner{
    padding: 0 10px;
  }
  .dashboard-content__statistics-wgt{
    padding: 15px;
    width: 25%;
  }
  .dashboard-content__statistics-wgt_border{
    border-bottom: none;
  }
}
.dashboard-content__statistics-value{
  font-size: 30px;
  font-weight: bold;
}
.dashboard-content__statistics-name{
  font-size: 14px;
  color: #808a8e;
  line-height: 1.29;
}
.dashboard-content__statistics-icon{
  width: 20px;
  height: 21px;
  margin-left: 10px;
}
.dashboard-wgts{
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px 10px 10px;
}
.dashboard-wgt{
  width: 50%;
  flex: 0 0 auto;
  padding: 10px;
  flex-shrink: 0;
}
.dashboard-mob-nav{
  padding-bottom: 15px;
  border-bottom: 1px solid #dde1e4;
  margin: 0 20px;
}
.dashboard-mob-nav__list{
  display: flex;
  align-items: center;
  list-style-type: none;
}
.dashboard-mob-nav__item{
  padding: 0 10px;
}
.dashboard-mob-nav__item:first-child{
  padding-left: 0;
}
.dashboard-mob-nav__item:last-child{
  padding-right: 0;
}
.dashboard-mob-nav__button{
  background: none;
  border: none;
  font-size: 16px;
  font-weight: bold;
  color: #000;
}
.dashboard-mob-nav__button.active{
  color: #364ed4;
  position: relative;
}
.dashboard-mob-nav__button.active::after{
  content: '';
  position: absolute;
  left: 0;
  bottom: -15px;
  z-index: 10;
  width: 100%;
  height: 2px;
  background-color: #364ed4;
}
@media(min-width: 768px){
  .dashboard-mob-nav{
    display: none;
  }
  .dashboard-wgt{
    width: 25%;
  }
}
@media(max-width: 767px){
  .account-content__headline-dashboard{
    display: none;
  }
  .dashboard-notifications,
  .dashboard-content__inner{
    display: none;
  }
  .dashboard-notifications.active,
  .dashboard-content__inner.active{
    display: block;
  }
}
.dashboard-wgt:hover .dashboard-wgt__inner{
  border: 1px solid #364ed4;
}
.dashboard-wgt__inner{
  padding: 15px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  height: 100%;
  position: relative;
}
.dashboard-wgt__label{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 15px;
  right: 15px;
  width: 24px;
  height: 24px;
  background-color: #22ca7b;
  color: #fff;
  font-size: 13px;
  border-radius: 50%;
  z-index: 10;
}
.dashboard-wgt__icon{
  width: 30px;
  height: 30px;
}
.dashboard-wgt__content{
  padding-top: 20px;
}
.dashboard-wgt__headline{
  font-size: 18px;
  margin-bottom: 3px;
  color: #000;
}
.dashboard-wgt__value{
  font-size: 13px;
  line-height: 1.38;
  color: #808a8e;
}
.dashboard-wgt__btn{
  display: block;
  width: 100%;
  border-radius: 7px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #e5e5e5;
  background-color: #fff;
  padding: 10px 25px;
  font-size: 14px;
  font-weight: 700;
}
.dashboard-notifications{
  padding: 15px 20px;
  display: none;
}
.dashboard-notifications__link{
  margin-left: 8px;
}
.dashboard-notifications__icon{
  width: 17px;
  height: 17px;
  stroke: #364ed4;
  display: block;
}
@media(min-width: 768px){
  .dashboard-notifications{
    border-top: 1px solid #dde1e4;
  }
}
.dashboard-notifications__header{
  display: none;
  padding-bottom: 10px;
}
.account-content__headline-notification{
  font-size: 18px;
}
.dashboard-notifications__header-inner{
  display: flex;
  align-items: center;
}
@media(min-width: 768px){
  .dashboard-notifications__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.dashboard-notifications__header-amount{
  padding: 2px 6px;
  background-color: #ededed;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  margin-left: 8px;
  color: #000;
}
.dashboard-notifications__clear{
  color: #364ed4;
  font-size: 14px;
  background: none;
  border: none;
  display: none;
}
@media(min-width: 768px){
  .dashboard-notifications__clear{
    display: block;
  }
}
.dashboard-notifications-wgt{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -10px;
  padding: 7px 10px;
  border-radius: 2px;
  cursor: pointer;
}
.dashboard-notifications-wgt:hover{
  background-color: #f4f4f5;
}
.dashboard-notifications-wgt__name{
  font-size: 14px;
}
.dashboard-notifications-wgt__date{
  color: #a7a7a7;
  font-size: 14px;
}
.user-statistic__info-left{
  text-align: center;
}
.user-statistic__info-avatar{
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
@media(min-width: 992px){
  .user-statistic__info-left{
    display: flex;
    align-items: center;
    text-align: left;
  }
  .user-statistic__info-avatar{
    margin-right: 15px;
  }
}
.user-statistic__info-name{
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}
.user-statistic__info-wrap{
  display: flex;
  align-items: center;
}
.user-statistic__status-label{
  border-radius: 5px;
  background-color: #ededed;
  padding: 2px 8px 2px 8px;
  position: relative;
}
.user-statistic__status-label-inner{
  display: flex;
  align-items: center;
  cursor: pointer;
}
.user-statistic__status-label-icon{
  width: 17px;
  height: 17px;
  stroke: #808a8e;
  vertical-align: middle;
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
  display: block;
}
.user-statistic__status-label-txt{
  display: block;
  padding-left: 5px;
  font-size: 14px;
  color: #808a8e;
  line-height: 0.8;
}
.user-statistic__status-label-content{
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  z-index: 100;
  background-color: #fff;
  padding: 8px 16px 8px 16px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px #e1e1e1;
  border-radius: 5px;
}
.user-statistic__status-label-content-headline,
.user-statistic__status-label-content-link{
  white-space: nowrap;
  font-size: 14px;
  line-height: 1.29;
}
.user-statistic__status-label-content-headline{
  color: #000;
}
.user-statistic__status-label-content-link{
  color: #364ed4;
  text-decoration: underline;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.user-statistic__status-label-content-link .loader{
  height: 15px;
  padding: 0;
  margin: 0;
}
.user-statistic__status-label-content-link .loader svg{
  width: 20px;
  height: 20px;
}
.user-statistic__status-complated-icon{
  width: 18px;
  height: 18px;
  display: block;
  margin-left: 5px;
}
.user-statistic__info-position{
  display: flex;
  justify-content: center;
  list-style-type: none;
}
.user-statistic__info-position li{
  position: relative;
  padding: 0 12px;
  color: #626570;
}
.user-statistic__info-position li::after{
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: #808a8e;
  border-radius: 50%;
  left: -2px;
  top: 50%;
  transform: translateY(-50%);
  display: block;
}
.user-statistic__info-position li:first-child{
  padding-left: 0;
}
.user-statistic__info-position li:first-child::after{
  display: none;
}
.user-statistic__info-right{
  padding-top: 10px;
  padding-left: 75px;
}
.user-statistic__info-right-desktop{
  display: none;
}
.user-statistic-wgt{
  cursor: pointer;
}
.user-statistic-wgt__name{
  font-weight: 400;
  margin-bottom: 5px;
}
.user-statistic-wgt__content{
  font-weight: 500;
  color: #808a8e;
}
.user-statistic-wgt__content-flex{
  display: flex;
  align-items: center;
}
.user-statistic-wgt__content-order-date{
  padding-right: 15px;
}
@media(min-width: 992px){
  .user-statistic-wgt__name{
    font-weight: 400;
    margin-bottom: 0;
  }
  .user-statistic-wgt__content{
    color: #000;
  }
  .user-statistic__info-right-desktop{
    display: block;
  }
  .user-statistic__info-right-mob{
    display: none;
  }
  .user-statistic__info-position{
    justify-content: flex-start;
  }
  .user-statistic-wgt{
    display: flex;
    justify-content: space-between;
  }
  .user-statistic__info{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .user-statistic__info-name{
    font-size: 26px;
    margin-bottom: 10px;
  }
  .user-statistic__info-right{
    padding-left: 0;
    padding-top: 0;
  }
  .user-statistic-table{
    display: flex;
  }
  .user-statistic-table__left,
  .user-statistic-table__right{
    width: 50%;
    flex: 0 0 auto;
  }
  .user-statistic-wgt__name{
    padding-bottom: 0;
  }
  .user-statistic-activity-wgt{
    display: flex;
    justify-content: space-between;
  }
}
.user-statistic-table__add-btn{
  background: none;
  border: none;
  padding: 0;
  color: #364ed4;
  cursor: pointer;
  font-size: 14px;
}
.user-statistic-balance__inner{
  display: flex;
  align-items: flex-end;
}
.user-statistic-balance__wrap{
  display: flex;
  align-items: flex-end;
}
@media(max-width: 991px){
  .user-statistic-table__left table tr td{
    display: block;
    padding-bottom: 10px;
  }
  .user-statistic-table__left table tr:last-child td:last-child{
    padding-bottom: 12px;
  }
  .user-statistic-balance{
    justify-content: space-between;
  }
  .user-statistic-activity-wgt{
    padding-bottom: 15px;
  }
  .user-statistic-activity-wgt:last-child{
    padding-bottom: 0;
  }
  .user-statistic-activity-wgt__name{
    margin-bottom: 7px;
  }
  .user-statistic-activity-wgt__date{
    color: #808a8e;
    font-size: 13px;
  }
}
.edit-btn{
  width: 39px;
  height: 39px;
  background-color: #364ed4;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}
.edit-btn__icon{
  stroke: #fff;
  width: 15px;
  height: 17px;
}
.remove-btn{
  width: 39px;
  height: 39px;
  background-color: #d01a36;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}
.remove-btn__icon{
  stroke: #fff;
  width: 15px;
  height: 17px;
}
.user-statistic__info-nav{
  display: flex;
  align-items: center;
}
.user-statistic__info-nav-item{
  margin-right: 11px;
}
.user-statistic__info-nav .tg-list-item{
  padding-left: 11px;
}
.user-statistic__info{
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 16px;
}
.user-statistic__info-wgt-wrap{
  background-color: #fff;
  border-radius: 8px;
  padding: 10px 15px;
}
.user-statistic__info-wgt{
  padding: 15px 0 20px 0;
  border-bottom: 1px solid #e2e2e2;
}
.user-activites__headline{
  font-size: 18px;
  font-weight: bold;
  padding: 15px 10px;
}
.user-statistic__info-wgt-activites{
  padding: 0 10px 10px 10px;
  border: 0;
}
@media(min-width: 992px){
  .user-activites__headline{
    padding: 15px 30px;
  }
  .user-statistic__info-wgt-activites{
    padding: 0 30px 15px 30px;
  }
}
.user-statistic__info-wgt:last-child{
  border-bottom: none;
}
.user-statistic__info-wgt-headline{
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}
.user-statistic-table__left table td:first-child{
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #626570;
  padding-right: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.user-statistic-table__left table td:last-child{
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}
.user-statistic-table__right .user-info__label{
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #808a8e;
}
.user-statistic-table__contacts-wrap{
  display: flex;
  align-items: stretch;
  padding-top: 15px;
}
.user-statistic-table__contacts,
.user-statistic-table__networks{
  width: 50%;
  flex: 0 0 auto;
}
.user-info-contact{
  display: flex;
  align-items: center;
}
.user-info-contact__icon{
  margin-right: 7px;
  width: 15px;
  height: 15px;
  display: block;
}
.user-info-contact__val{
  font-size: 14px;
  line-height: 1.71;
  color: #000;
}
.user-statistic-balance{
  display: flex;
}
.user-statistic-balance__right{
  display: flex;
  align-items: center;
}
.user-statistic-balance__value{
  font-size: 18px;
  font-weight: bold;
  margin-right: 5px;
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
}
.user-statistic-balance__balance-icon{
  width: 14px;
  height: 15px;
  margin-left: 5px;
  display: block;
}
.user-statistic-balance__label{
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #808a8e;
}
.user-statistic-balance__right{
  padding-left: 30px;
}
.user-statistic-balance__balance-add-btn{
  height: 39px;
}
.user-statistic__info-wgt-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user-statistic__info-wgt-link{
  color: #364ed4;
  font-size: 14px;
}
.user-statistic-wgt{
  padding: 10px 0;
  font-size: 14px;
  border-top: 1px solid #ededed;
}
.user-statistic-wgt__items span{
  margin-left: 30px;
}
.user-statistic-wgt__items span:first-child{
  margin-left: 0;
}
.user-statistic-wgt__items-balance svg{
  width: 9px;
  height: 10px;
}
.user-statistic-wgt__items-status_new{
  color: #00ae4c;
}
.user-statistic-wgt__content-day{
  margin-left: 13px;
  color: #808a8e;
}
.user-statistic-activity-wgt{
  padding: 4px 0;
}
.user-statistic-activity-wgt{
  font-size: 14px;
}
.user-statistic-activity-wgt__name a{
  color: #364ed4;
  text-decoration: underline;
  margin-left: 3px;
}
.bonuses-info-content{
  padding: 0 10px;
}
@media(min-width: 992px){
  .bonuses-info-content{
    padding: 0 30px;
  }
}
.bonuses-info__content{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dde1e4;
  padding-bottom: 15px;
}
.bonuses-info__content-inner{
  width: 100%;
  flex: 0 0 auto;
}
.bonuses-info__headline{
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 3px;
  word-break: break-all;
}
.bonuses-info__content-header{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.bonuses-info__logo{
  width: auto;
  max-height: 60px;
  display: block;
  margin: 0 auto;
}
@media(min-width: 768px){
  .bonuses-info__content-header-inner{
    display: flex;
    align-items: center;
  }
  .bonuses-info__content-header{
    text-align: left;
    justify-content: space-between;
  }
  .bonuses-info__headline{
    margin-right: 10px;
    margin-bottom: 0;
  }
  .bonuses-info__logo-wrap{
    padding-right: 10px;
  }
  .bonuses-info__logo{
    margin: 0;
  }
}
.bonuses-info__loyalty{
  background-color: #364ed4;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  padding: 4px 8px;
  display: inline-block;
}
.bonuses-info__description{
  line-height: 1.5;
  margin-bottom: 5px;
  word-break: break-all;
}
.bonuses-info__description ul,
.bonuses-info__description ol{
  padding-left: 30px;
  margin: 10px 0;
}
.bonuses-info__address{
  color: #808a8e;
  line-height: 1.5;
}
.bonuses-info__logo,
.bonuses-info__logo-mob{
  max-width: 100%;
}
@media(min-width: 992px){
  .bonuses-info__content-inner{
    width: 75%;
  }
  .bonuses-info__logo{
    display: block;
  }
  .bonuses-info__logo-mob{
    display: none;
  }
}
.bonuses-info__users{
  padding: 12px 0;
  border-bottom: 1px solid #dde1e4;
}
.bonuses-info__users-content{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 12px;
}
.bonuses-info__users .btn{
  width: 100%;
}
@media(min-width: 768px){
  .bonuses-info__users{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .bonuses-info__users-content{
    text-align: right;
    justify-content: flex-end;
    padding-top: 0;
  }
  .bonuses-info__users .btn{
    width: auto;
  }
}
.bonuses-info__users-content-wgt{
  font-size: 21px;
  font-weight: bold;
  padding-left: 50px;
}
.bonuses-info__users-content-wgt:first-child{
  padding-left: 0;
}
.bonuses-info__user{
  display: flex;
  align-items: center;
}
.bonuses-info__user_no-active{
  color: #fff;
  display: inline-block;
  margin-left: 10px;
  border-radius: 3px;
  padding: 2px 5px;
  font-size: 12px;
  background-color: #d80000;
}
.bonuses-info__user img{
  margin-right: 12px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.bonuses-info__user-nav{
  display: flex;
  align-items: center;
}
.bonuses-info__days{
  color: #808a8e;
  margin-left: 10px;
}
.standart-table__bonuses tr{
  cursor: pointer;
}
.standart-table__bonuses-status{
  font-weight: 600;
}
.standart-table__bonuses-title{
  width: 75%;
}
.standart-table__bonuses-status_active{
  color: #00ae4c !important;
}
.standart-table__bonuses-status_not-active{
  color: #d01a36 !important;
}
.standart-table__bonuses{
  display: none;
  width: 100%;
  text-align: left;
  border-spacing: 0px;
  border-collapse: collapse;
}
.standart-table__bonuses-users{
  display: none;
}
.standart-table__translation{
  max-width: 300px;
}
@media(min-width: 576px){
  .standart-table__bonuses,
  .standart-table__bonuses-users{
    display: table;
  }
  .bonuses-mob,
  .bonuse-users-mob{
    display: none;
  }
}
.bonuse-users-mob{
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
}
.bonuse-users-mob__name{
  display: flex;
  align-items: center;
}
.bonuse-users-mob__name img{
  width: 23px;
  height: 23px;
  margin-left: 10px;
  border-radius: 50%;
}
.item-list-wgt{
  border: 1px solid #dde1e4;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 5px;
}
.item-list-wgt__header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}
.item-list-wgt__name{
  font-size: 16px;
  color: #000;
}
.item-list-wgt__content-row{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.item-list-wgt__content-row_padding{
  padding-bottom: 10px;
}
.item-list-wgt__content-wgt:first-child .item-list-wgt__content-wgt-value{
  font-weight: 600;
}
.item-list-wgt__content-wgt_right{
  text-align: right;
}
.item-list-wgt__content-wgt-label{
  font-size: 12px;
  color: #626e77;
  margin-bottom: 5px;
}
.item-list-wgt__content-wgt-value{
  font-size: 14px;
  color: #000;
}
.item-list-wgt__nav{
  display: flex;
  align-items: center;
}
.item-list-wgt__nav-btn{
  background: none;
  border: none;
}
.item-list-wgt__nav-btn:first-child{
  margin-right: 15px;
}
.item-list-wgt__nav-icon{
  width: 16px;
  height: 17px;
  stroke: #364ed4;
}
.item-list-wgt-nav .tg-list-item{
  padding-left: 15px;
}
.item-list-wgt__content-wgt-value.status-active{
  color: #00ae4c;
}
.item-list-wgt__content-wgt-value.status-ended{
  color: #808a8e;
}
.add-bonuse-user{
  padding-top: 20px;
}
.response-submit{
  position: fixed;
  top: 80px;
  right: 20px;
  z-index: 999999;
  width: 300px;
}
.response-submit__item{
  width: 100%;
  padding: 15px 22px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  margin-bottom: 5px;
}
.response-submit__item_success{
  background-color: #3bcb64;
  animation: viewLabel 0.5s ease-in-out forwards;
  opacity: 0;
  box-shadow: 0 1px 7px 0 rgba(59, 203, 100, 0.5);
}
.response-submit__item_error{
  background-color: #cb3b43;
  animation: viewLabel 0.5s ease-in-out forwards;
  opacity: 0;
  box-shadow: 0 1px 7px 0 rgba(203, 59, 67, 0.5);
}
.response-submit__close{
  background: none;
  border: none;
}
.response-submit__close svg{
  fill: #fff;
  width: 18px;
  height: 18px;
}
@keyframes viewLabel {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.modal-questions-ended{
  width: 100%;
}
.modal-questions-ended tr td{
  padding: 5px 0;
  font-size: 14px;
}
.modal-questions-ended__type{
  font-weight: 600;
}
.modal-questions-ended__type_active{
  color: #00ae4c;
}
.modal-questions-ended__type_ended{
  color: #757f87;
}
.price{
  padding-top: 74px;
  padding-bottom: 0;
}
.price__headline{
  font-size: 24px;
  font-weight: 900;
  font-family: 'SF-Pro-Display-Black';
}
.price__description{
  font-size: 18px;
  font-weight: 500;
  margin: 20px 0 30px 0;
}
@media(min-width: 768px){
  .price__header{
    text-align: center;
  }
}
@media(min-width: 992px){
  .price__headline{
    font-size: 55px;
  }
  .price__description{
    font-size: 24px;
  }
}
.price__info{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -15px;
}
.price-wgt{
  width: 100%;
  padding: 0 15px;
}
.price-wgt__inner{
  background-color: #f0f3f5;
  border-radius: 5px;
  display: flex;
  height: 100%;
  flex-direction: column;
}
.price-wgt_center .price-wgt__inner{
  background-color: #eaf6ff;
}
.price-wgt__header{
  text-align: center;
  flex: 0 0 auto;
  padding-bottom: 10px;
}
.price-wgt__content{
  padding: 0 12px;
}
.price-wgt__headline{
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 2px;
  flex: 0 0 auto;
  padding: 10px;
  font-family: 'SF-Pro-Display-Black';
}
.price-wgt__header-inner{
  display: block;
  /* align-items: center;
  border-bottom: 1px solid #d5d5d5; */
}
@media(min-width: 992px){
  .price-wgt{
    width: 50%;
  }
  .price-wgt__headline-basic,
  .price-wgt__headline-bussines{
    display: none;
  }
  .price-wgt__inner{
    border-radius: 20px;
    padding: 15px 30px;
  }
  .price-wgt_center .price-wgt__inner{
    padding: 30px;
  }
  .price-wgt__content{
    padding: 0;
  }
  .price-wgt__header-inner{
    display: block;
    border-bottom: none;
  }
  .price-wgt__headline{
    font-size: 40px;
    width: 100%;
    flex: none;
    padding: 0;
  }
}
@media(min-width: 1200px) {
  .price-wgt{
    width: 33.333333%;
  }
}
@media(max-width: 991px){
  .price-wgt.active{
    display: block;
  }
  .price-wgt__headline.active{
    color: #364ed4;
    position: relative;
  }
  .price-wgt__headline.active::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #364ed4;
    left: 0;
    bottom: -2px;
  }
}
.price-wgt__description{
  font-size: 16px;
  font-weight: 500;
  line-height: 1.44;
  color: #7b7b7b;
}
.price-wgt__business .price-wgt__label,
.price-wgt__basic .price-wgt__label{
  font-size: 21px;
}
.price-wgt__label{
  background-color: #fff;
  font-weight: 900;
  line-height: 1;
  text-align: center;
  padding: 15px 0;
  border-radius: 8px;
  min-height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'SF-Pro-Display-Black';
}
.price-wgt__content{
  flex: 1 0 auto;
}
.price__list{
  list-style: none;
  padding-top: 15px;
}
.price__list li{
  padding: 7px 0;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.price__disabled{
  opacity: 0.2;
}
.price__icon-wrap{
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  border-radius: 50%;
  display: block;
  background-color: #d5dfe9;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
.price__icon{
  max-width: 12px;
  height: 14px;
  stroke-width: 1px;
  stroke: #000;
}
.price-wgt__footer{
  padding: 20px 0;
  flex: 0 0 auto;
}
.contacts{
  padding-top: 74px;
  padding-bottom: 80px;
  position: relative;
  overflow: hidden;
  height: 100%;
}
.contacts__headline{
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 10px;
  font-family: 'SF-Pro-Display-Black';
}
.contacts__description{
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 15px;
}
@media(min-width: 992px){
  .contacts__description{
    margin-bottom: 38px;
  }
}
.contacts__inner{
  display: flex;
  align-items: center;
}
.contacts__form{
  width: 100%;
  flex: 0 0 auto;
}
.contacts-submit{
  display: flex;
}
@media(min-width: 768px){
  .contacts-submit{
    display: inline-flex;
  }
}
.contacts__img{
  position: absolute;
  width: 50%;
  top: 80px;
  right: -100px;
  display: none;
}
.contacts__img img{
  max-height: 850px;
}
.contact-form{
  background-color: #f0f3f5;
  border-radius: 10px;
  padding: 15px;
}
@media(min-width: 992px){
  .contact-form{
    padding: 23px 30px 38px 30px;
  }
}
.contact-form__header{
  padding-bottom: 23px;
}
.contact-form__headline{
  font-size: 19px;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 10px;
  font-family: 'SF-Pro-Display-Black';
}
.contact-form__description{
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: #3f5057;
}
.contact-form textarea{
  height: 140px;
}
.form__headline{
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  margin-bottom: 15px;
}
@media(min-width: 992px){
  .contacts__form{
    width: 50%;
  }
  .contacts__img{
    display: block;
  }
}
@media(min-width: 1200px){
  .contacts__headline{
    font-size: 55px;
  }
  .contacts__description{
    font-size: 24px;
  }
  .contact-form__headline{
    font-size: 24px;
  }
  .contact-form__description{
    font-size: 21px;
  }
}
.about-page{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  position: relative;
}
@media(min-width: 768px){
  .about-page{
    min-height: 850px;
  }
}
.about-page__img{
  top: -50%;
  left: 0;
  position: absolute;
  width: 100%;
}
.about-page__content{
  text-align: left;
  position: relative;
  z-index: 10;
  padding-top: 95px;
  margin: 0 auto;
}
.about-page__headline{
  font-size: 24px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  margin-bottom: 15px;
  font-family: 'SF-Pro-Display-Black';
}
.about-page__description{
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  margin-bottom: 30px;
}
@media(min-width: 768px){
  .about-page__headline{
    font-size: 32px;
  }
  .about-page__description{
    font-size: 18px;
  }
  .about-page__content{
    text-align: center;
  }
}
@media(min-width: 992px){
  .about-page__headline{
    font-size: 55px;
  }
  .about-page__description{
    font-size: 24px;
  }
}
.about-page__phones{
  position: absolute;
  z-index: 10;
  bottom: -50%;
  left: 50%;
  transform: translate(-50%, -150px);
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 750px;
}
.about-page__phones_left{
  transform: translateX(130px);
  position: relative;
  z-index: 5;
  display: block;
}
.about-page__phones_center{
  position: relative;
  z-index: 10;
  display: block;
  max-width: 520px;
  margin: 0 auto;
}
@media(min-width: 1200px){
  .about-page__phones{
    max-width: 1000px;
  }
  .about-page__phones_center{
    max-width: 630px;
  }
}
.about-page__phones_right{
  transform: translateX(-130px);
  position: relative;
  z-index: 5;
  display: block;
}
.about-page__face{
  display: none;
  position: absolute;
  z-index: 10;
  max-width: 130px;
}
.home-wgt__add-company-btn-about{
  display: flex;
}
@media(min-width: 768px){
  .home-wgt__add-company-btn-about{
    display: inline-flex;
  }
}
@media(min-width: 992px){
  .about-page__face{
    display: block;
  }
}
@media(max-width: 991px){
  .about-page__phones{
    position: static;
    transform: translate(0, 50px);
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
  }
  .about-page__phones_center{
    width: 100%;
  }
}
.about-page__face:nth-child(1){
  top: 80px;
  left: 0;
}
.about-page__face:nth-child(2){
  top: 50%;
  left: -150px;
  transform: translateY(-50%);
}
.about-page__face:nth-child(3){
  bottom: 80px;
  left: 0;
}
.about-page__face:nth-child(4){
  top: 80px;
  right: 0;
}
.about-page__face:nth-child(5){
  top: 50%;
  right: -150px;
  transform: translateY(-50%);
}
.about-page__face:nth-child(6){
  bottom: 80px;
  right: 0;
}
.about-apps{
  padding-top: 75px;
}
@media(min-width: 992px){
  .about-apps{
    padding-top: 340px;
  }
}
.about-apps__list{
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.about-apps__list li{
  padding: 0 9px 9px 9px;
}
.about-service{
  padding-bottom: 100px;
  overflow: hidden;
}
.about-tools-motivation{
  overflow: hidden;
  padding-top: 20px;
}
@media(min-width: 768px){
  .about-tools-motivation__header{
    text-align: center;
  }
}
.about-tools-motivation__people{
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media(min-width: 992px){
  .about-tools-motivation{
    padding-top: 60px;
  }
}
@media(max-width: 991px){
  .about-tools-motivation__people li:nth-child(1),
  .about-tools-motivation__people li:nth-child(2),
  .about-tools-motivation__people li:nth-last-child(1),
  .about-tools-motivation__people li:nth-last-child(2){
    display: none;
  }
  .about-tools-motivation__people:last-child{
    justify-content: space-around;
  }
}
.about-tools-motivation__people_top{
  padding-top: 50px;
  margin: 0 -17px;
  position: relative;
  left: -40px;
}
@media(min-width: 992px){
  .about-tools-motivation__people_top{
    left: -80px;
  }
}
.about-tools-motivation__people_bottom{
  padding: 0 10px;
}
.about-tools-motivation__people img{
  min-width: 45px;
  max-width: 45px;
  min-height: 45px;
  max-height: 45px;
}
@media(min-width: 768px){
  .about-tools-motivation__people img{
    min-width: 60px;
    max-width: 60px;
    min-height: 60px;
    max-height: 60px;
  }
  .about-tools-motivation__people_bottom{
    padding: 0 50px;
  }
}
@media(min-width: 1200px){
  .about-tools-motivation__people img{
    min-width: 100px;
    max-width: 100px;
    min-height: 100px;
    max-height: 100px;
  }
  .about-tools-motivation__people_bottom{
    padding: 0 80px;
  }
}


.about-opportunities__header{
  text-align: center;
}


.about-personal-card{
  padding-top: 30px;
  padding-bottom: 30px;
}
@media(min-width: 992px){
  .about-personal-card{
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.about-personal-card__content{
  padding: 30px;
  border-radius: 20px;
  margin-top: 150px;
  background: #f2f2f8;
  position: relative;
}
.about-personal-card__list{
  list-style-type: none;
  width: 100%;
}
.about-personal-card__list li{
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  display: flex;
  padding-bottom: 10px;
}
.about-personal-card__list li::before{
  content: " – ";
  margin-right: 5px;
}
@media(min-width: 992px){
  .about-personal-card__list li::before{
    display: none;
  }
}
.about-personal-card__icon{
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  margin-right: 20px;
  display: none;
}
.about-personal-card__content_left{
  width: 100%;
  order: 12;
}
.about-personal-card__content_right{
  width: 100%;
  order: 13;
}
.about-personal-card__content{
  display: flex;
  flex-direction: column;
}
.about-personal-card__content-phone{
  order: 1;
  margin: -120px auto 0 auto;
  padding-bottom: 50px;
  text-align: center;
}
.about-personal-card__content-phone img{
  max-width: 52%;
}
@media(min-width: 992px){
  .about-personal-card__content-phone{
    margin: 0 auto;
  }
  .about-personal-card__list li{
    font-size: 18px;
    padding-bottom: 40px;
  }
  .about-personal-card__content{
    padding: 70px 50px 30px 50px;
    flex-direction: row;
    justify-content: space-between;
  }
  .about-personal-card__icon{
    display: block;
  }
  .about-personal-card__content-phone{
    padding-bottom: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 600px;
  }
  .about-personal-card__content_left{
    width: 25%;
    flex: 0 0 auto;
  }
  .about-personal-card__content_right{
    width: 25%;
    flex: 0 0 auto;
  }
}
.about-advantages__wgt{
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
}
.about-advantages__wgt:nth-child(even) .about-advantages__img{
  order: 1;
}
.about-advantages__wgt:nth-child(even) .about-advantages__content{
  order: 13;
}
.about-advantages__img{
  display: flex;
  justify-content: center;
}
.about-advantages__img img{
  max-width: 50%;
}
@media(min-width: 768px){
  .about-advantages__img img{
    max-width: 100%;
  }
}
@media(min-width: 992px){
  .about-advantages__content{
    padding: 0 50px;
  }
}
.about-advantages__title{
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  margin-bottom: 15px;
}
.about-advantages__description{
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}
@media(min-width: 768px){
  .about-advantages{
    padding-top: 50px;
  }
  .about-advantages__img{
    width: 20%;
  }
  .about-advantages__content{
    width: 80%;
  }
  .about-advantages__wgt{
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .about-advantages__wgt:nth-child(even) .about-advantages__img{
    order: 13;
  }
  .about-advantages__wgt:nth-child(even) .about-advantages__content{
    order: 1;
  }
}
@media(min-width: 992px){
  .about-advantages{
    padding-top: 100px;
  }
  .about-advantages__title{
    font-size: 36px;
  }
  .about-advantages__description{
    font-size: 21px;
  }
}
@media(min-width: 1200px){
  .about-advantages__wgt{
    padding-bottom: 0;
  }
}
.about-features{
  padding-bottom: 100px;
}
.about-features__header{
  padding-bottom: 50px;
}
@media(min-width: 768px){
  .about-features__header{
    text-align: center;
  }
}
.about-features__wgt{
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 30px;
}
.about-features__icon{
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  margin-right: 23px;
  display: none;
}
.about-features__content{
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 15px;
}
.about-features__img{
  width: 100%;
  display: flex;
  justify-content: center;
}
.about-features__wgt:nth-child(odd) .about-features__content{
  order: 1;
}
.about-features__wgt:nth-child(odd) .about-features__img{
  order: 13;
}
.about-features__img img{
  max-width: 50%;
  height: auto;
  display: block;
}
.about-features__title{
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  margin-bottom: 10px;
}
.about-features__description{
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  margin-bottom: 10px;
}
@media(min-width: 992px){
  .about-features__wgt{
    flex-direction: row;
    padding-bottom: 0;
  }
  .about-features__content{
    width: 60%;
    padding-bottom: 0;
  }
  .about-features__img{
    width: 40%;
  }
  .about-features__wgt:nth-child(odd) .about-features__content{
    order: 13;
  }
  .about-features__wgt:nth-child(odd) .about-features__img{
    order: 1;
  }
  .about-features__icon{
    display: block;
  }
  .about-features__title{
    font-size: 36px;
  }
  .about-features__description{
    font-size: 21px;
  }
}
.about-manage{
  padding-bottom: 100px;
}
@media(min-width: 768px){
  .about-manage__header{
    max-width: 90%;
    text-align: center;
    margin: 0 auto;
  }
}
.about-manage__wgt{
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 24px 0;
}
.about-manage__img{
  width: 100%;
  flex: 0 0 auto;
}
.about-manage__img img{
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  z-index: 2;
}
.about-manage__left{
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1;
  max-width: 40%;
  display: none;
}
.about-manage__right{
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 1;
  max-width: 40%;
  display: none;
}
.about-manage__title{
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  margin-bottom: 15px;
}
.about-manage__content{
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  width: 100%;
  flex: 0 0 auto;
  padding: 0 15px 30px 15px;
}
.about-manage__wgt:nth-child(even) .about-manage__content{
  order: 1;
}
.about-manage__wgt:nth-child(even) .about-manage__img{
  order: 13;
}
@media(min-width: 992px){
  .about-manage__wgt{
    flex-direction: row;
    padding: 100px 0;
  }
  .about-manage__img{
    width: 60%;
  }
  .about-manage__content{
    width: 40%;
    padding-bottom: 0;
  }
  .about-manage__left,
  .about-manage__right{
    display: block;
  }
  .about-manage__wgt:nth-child(even) .about-manage__content{
    order: 13;
    padding-left: 30px;
    padding-right: 100px;
  }
  .about-manage__wgt:nth-child(even) .about-manage__img{
    order: 1;
  }
  .about-manage__title{
    font-size: 36px;
  }
  .about-manage__content{
    font-size: 18px;
  }
  .about-manage__wgt:nth-child(odd) .about-manage__content{
    padding-left: 100px;
    padding-right: 30px;
  }
}
.about-manage__content p{
  margin-bottom: 15px;
}
.about-manage__content ul{
  list-style-type: none;
}
.about-manage__content ul li::before{
  content: " - ";
}
.about-manage__bottom-wgt-inner img{
  display: none;
}
.about-manage__bottom-wgt:first-child{
  padding-bottom: 30px;
}
@media(min-width: 992px){
  .about-manage__row{
    display: flex;
    align-items: stretch;
    margin: 0 -15px;
  }
  .about-manage__bottom-wgt{
    width: 50%;
    flex: 0 0 auto;
    padding: 0 15px;
  }
  .about-manage__bottom-wgt:first-child{
    padding-bottom: 0;
  }
  .about-manage__bottom-wgt-inner{
    padding: 30px;
    border-radius: 10px;
    border: 1px solid #e1e1e1;
    background-color: #fff;
    display: flex;
    align-items: flex-start;
    height: 100%;
  }
  .about-manage__bottom-wgt-inner img{
    display: block;
  }
  .about-manage__bottom-wgt-content{
    padding-left: 25px;
  }
}
.about-manage__bottom-wgt-content{
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
}
.about-manage__bottom-wgt-title{
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  margin-bottom: 10px;
}
.about-stock__wgt{
  border-radius: 10px;
  background-color: #ffdfa8;
  display: flex;
  flex-direction: column;
}
@media(min-width: 992px){
  .about-stock__wgt{
    padding: 0 65px;
  }
}
.about-stock__wgt-title{
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  margin-bottom: 15px;
}
@media(min-width: 1200px){
  .about-stock__wgt-title{
    font-size: 36px;
  }
}
.about-stock__wgt-untitle{
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.62;
  letter-spacing: normal;
  margin-bottom: 10px;
}
.about-stock__wgt-content{
  order: 13;
  text-align: center;
  padding: 0 15px 30px 15px;
}
.about-stock__wgt-img{
  order: 1;
}
@media(min-width: 992px){
  .about-stock__wgt{
    flex-direction: row;
    align-items: center;
  }
  .about-stock__wgt-content{
    width: 50%;
    flex: 0 0 auto;
    order: 1;
    text-align: left;
    padding: 30px 0;
  }
  .about-stock__wgt-img{
    width: 50%;
    flex: 0 0 auto;
    order: 13;
  }
  .about-stock__wgt-content-inner{
    width: 80%;
    padding-right: 50px;
  }
}
.about-stock__wgt-img img{
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  position: relative;
  top: -75px;
  margin: 0 auto;
}
@media(min-width: 768px){
  .about-stock__wgt-img img{
    max-width: 75%;
  }
}
@media(min-width: 992px){
  .about-stock__wgt-img img{
    max-width: 100%;
    top: -65px;
  }
}
.header__menu-nav{
  display: flex;
  align-items: center;
}
.header__main-menu{
  padding-left: 40px;
  display: none;
}
.header__main-menu-btn-dropdown{
  display: flex;
  align-items: center;
  font-family: 'SF-Pro-Display-Regular', sans-serif;
}
.header__main-menu-btn-dropdown-icon{
  width: 12px;
  height: 7px;
  stroke: #000;
  margin-left: 5px;
  stroke-width: 2px;
  margin-top: 2px;
}
@media(min-width: 992px){
  .header__main-menu{
    display: block;
  }
}
.header__main-menu ul{
  display: flex;
  align-items: center;
  list-style-type: none;
}
.header__main-menu ul li{
  padding: 0 20px;
  position: relative;
}
.header__main-menu ul li:first-child{
  padding-left: 0;
}
.header__main-menu ul li:last-child{
  padding-right: 0;
}
.header__main-menu ul li a,
.header__main-menu ul li button{
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #011626;
  display: flex;
  position: relative;
  padding: 0;
  border: none;
  background: none;
}
.header__main-menu ul li a.active{
  color: #364ed4;
}
.header__main-menu ul li a.active::after{
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 2px;
  background-color: #364ed4;
}
.header__main-menu .header__main-menu-dropdown{
  transition: 200ms all;
  position: absolute;
  top: calc(100% + 27px);
  left: -20px;
  display: block;
  background-color: #fff;
  border-radius: 15px;
  padding: 5px;
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
  visibility: hidden;
  opacity: 0;
}
.header__main-menu .header__main-menu-dropdown li{
  padding: 0;
}
.header__main-menu .header__main-menu-dropdown li a{
  white-space: nowrap;
  padding: 10px;
  border-radius: 10px;
  font-weight: 400;
}
.header__main-menu .header__main-menu-dropdown li a:hover{
  background-color: #efefef;
}
.header__main-menu-dropdown.active{
  visibility: visible;
  opacity: 1;
}
.tooltips-wrap{
  display: flex;
  align-items: center;
  position: relative;
}
.tooltips-wrap__users{
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.tooltips-wrap__users:hover{
  z-index: 100;
}
.tooltips-wrap__users:hover .tooltip-info__content{
  display: block;
}
.event-form .field-wrap__tooltip,
.library-form .field-wrap__tooltip,
.questions-form .field-wrap__tooltip{
  padding-top: 0;
}
.library-form .field-wrap__tooltip .tooltip-info__content{
  top: calc(100% - 40px);
}
.field-wrap__tooltip{
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 20px;
  padding-right: 50px;
}
.field-wrap__tooltip-align{
  top: -10px;
}
.field-wrap__tooltip-align .tooltip-info__content{
  top: calc(100% - 20px);
}
.tooltip-info{
  margin-top: 3px;
  margin-left: 5px;
}
.tooltip-info span{
  width: 19px;
  height: 19px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #808a8e;
  background-color: #e5e5e5;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.tooltip-info__icon:hover ~ .tooltip-info__content{
  display: block;
}
.tooltip-info__content{
  display: none;
  padding: 2px 12px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  position: absolute;
  left: 0;
  right: auto;
  top: 100%;
  z-index: 11;
  background-color: #fff;
  max-width: 360px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}
.tooltip-info__content p {
  margin: 10px 0;
}
.tooltip-info__content .arrow{
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
}
.account-wgt__edit-btn {
   cursor: pointer;
   font-family: 'SF-Pro-Display-Regular', sans-serif;
}
.phone-file {
   opacity: 0;
   position: absolute;
   z-index: -1;
}
.account-wgt__edit-visual{
  display: flex;
}
.account-wgt__edit-visual-inner{
  padding-right: 10px;
}
.account-wgt__remove-btn{
  color: #e41928;
  font-size: 14px;
  background: none;
  border: none;
  font-family: 'SF-Pro-Display-Regular', sans-serif;
}
.select-users{
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  min-height: 42px;
  padding: 0 4px;
  cursor: pointer;
}
.select-users__input{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.select-users__input .select-users__label{
  margin: 2px;
}
.select-users__input .select-users__remove{
  cursor: pointer;
}
.select-users__placeholder{
  color: #A7A7A7;
  cursor: default;
  font-size: 16px;
  padding: 6.5px 6px;
}
.users-modal{
  padding-top: 10px;
  max-height: 500px;
  overflow-y: scroll;
  margin: 0 -4px;
}
.users-modal__item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  cursor: pointer;
  border-radius: 30px;
  margin-bottom: 3px;
}
.users-modal__item-wrap{
  width: calc(100% - 15px);
}
.users-modal__item.active{
  background-color: #f4f4f8;
}
.users-modal__item:hover{
  background-color: #f4f4f8;
}
.users-modal__name,
.team-list-modal__name-member{
  font-weight: 500;
  display: flex;
  align-items: center;
  word-break: break-word;
}
.team-list-modal__name-member-position,
.users-modal__name-position{
  padding-left: 5px;
  color: #a7a7a7;
  font-size: 16px;
}
.users-modal__info {
  display: flex;
  align-items: center;
}
.users-modal__avatar{
  margin-right: 15px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
}
.users-modal__avatar-icon-wrap{
  margin-right: 15px;
  border-radius: 50%;
  background: #e8e9f0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.users-modal__avatar-icon{
  width: 15px;
  height: 17px;
  stroke: #364ED4;
}
.users-modal__check-wrap{
  width: 21px;
  height: 21px;
  min-width: 21px;
  background-color: #00ae4c;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.users-modal__check{
  width: 21px;
  height: 21px;
  display: block;
}
.tooltip-wgt{
  position: relative;
  cursor: pointer;
  z-index: 1000;
}
.tooltip-wgt__content{
  display: none;
  padding: 2px 12px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  position: absolute;
  left: auto;
  right: calc(100% + 5px);
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background-color: #fff;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}
.tooltip-wgt:hover .tooltip-wgt__content{
  display: block;
}
.users-modal__check-wrap .users-modal__check{
  width: 12px;
  height: 12px;
  fill: #fff;
  margin-right: 0;
  position: relative;
}
.users-modal__check-wrap__waiting .users-modal__check{
  stroke: #fff;
  width: 15px;
  height: 15px;
}
.select-users__label{
  border-radius: 2px;
  background-color: #e8ecff;
  padding: 0 4px 0 8px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #364ed4;
  margin-right: 2px;
}
.select-users__label span{
  display: block;
  padding: 10px 8px 10px 12px;
}
.select-users__remove{
  width: 10px;
  height: 10px;
  fill: #364ed4;
  display: block;
}
.account-item__avatar-input{
  display: none;
}
.account-item__avatar-remove{
  position: relative;
  font-family: 'SF-Pro-Display-Regular', sans-serif;
}
.account-item__avatar-remove .loader{
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  right: -50px;
  transform: translateY(-50%);
}
.account-item__avatar_exists{
  position: relative;
}
.account-item__avatar_exists .account-item__avatar-img{
  cursor: pointer;
}
.account-item__avatar-img-scale{
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  top: 100%;
  left: -20px;
  z-index: 10;
}
.account-item__avatar-label{
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #364ed4;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}
.account-item__avatar-label .loader{
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  right: -50px;
  transform: translateY(-50%);
}
.account-item__avatar{
  display: flex;
  align-items: center;
}
.account-item__avatar-img{
  margin-right: 10px;
  width: 23px;
  height: 23px;
  border-radius: 50%;
}
.register-form__login{
  padding-top: 22px;
  text-align: center;
  font-size: 15px;
}
.register-form__login-label{
  margin-right: 5px;
}
.register-form__login-link{
  color: #364ed4;
}
.editor-field{
  display: flex;
  flex-direction: column;
  border: 1px solid #e1e1e1;
  background-color: #fff;
  font-size: 16px;
  color: #000;
  border-radius: 5px;
  font-family: sans-serif;
}
.editor-toolbar{
  background-color: #efefef;
  order: 1;
  margin-bottom: 0;
}
.editor-class{
  min-height: 100px;
  cursor: text;
}
.rdw-option-wrapper{
  background: none;
  width: 30px;
  height: 30px;
}
.rdw-option-wrapper:hover{
  background-color: #fff;
  box-shadow: none;
  border-radius: 3px;
}
.rdw-dropdown-wrapper{
  border-radius: 3px;
}
.rdw-dropdown-wrapper:hover{
  box-shadow: none;
}
.account-item__upload-photo{
  display: none;
}
.account-item__value-logo{
  color: #364ed4;
  cursor: pointer;
}
.account-item__value-name{
  margin-bottom: 15px;
  word-wrap: break-word;
}
.account-item__avatar-remove{
  color: #e74426;
  background: none;
  border: none;
  padding: 0;
  font-size: 14px;
  margin-left: 10px;
}
#set-logo{
    display: none;
}
.edit-logo-nav{
  padding-top: 8px;
}
.edit-logo-nav__edit{
  color: #364ed4;
  font-size: 14px;
  cursor: pointer;
  position: relative;
}
.edit-logo-nav__exists .edit-logo-nav__edit{
  margin-right: 10px;
}
.account-wgt__remove-btn{
  position: relative;
}
.edit-logo-nav__edit .loader,
.account-wgt__remove-btn .loader,
.account-wgt__edit-btn_color .loader{
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  right: -50px;
  transform: translateY(-50%);
}
.modal-image-edit__preview{
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
.modal-image-edit__preview_icon{
  max-width: 200px;
}
.modal-image-edit__main{
  width: 100%;
  height: 180px;
  border-radius: 2px;
}
.modal-image-edit__main-preview{
  width: 440px;
  height: 240px;
  position: relative;
}
.modal-image-edit__main-preview-inner{
  width: 300px;
  height: 160px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  padding: 20px 30px;
}
.modal-image-edit__main-preview-inner .modal-image-edit__main-preview-name{
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ebebeb;
  text-transform: uppercase;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.modal-image-edit__main-preview-inner .modal-image-edit__main-preview-name img{
  width: 36px;
  height: 36px;
  display: block;
  border-radius: 50%;
}
.modal-image-edit__main-preview-inner .modal-image-edit__main-preview-value{
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ebebeb;
  padding-bottom: 2px;
}
.modal-image-edit__main-preview-inner .modal-image-edit__main-preview-balance{
  font-size: 27px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ebebeb;
  padding-top: 10px;
}
.modal-image-edit_empty{
  background-color: #ededed;
}
.modal-image-edit__nav{
  display: flex;
  align-items: center;
  padding-top: 25px;
  padding-left: 7.5px;
  padding-right: 7.5px;
}
.modal-image-edit__nav-btn-wrap{
  width: 50%;
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
}
.modal-image-edit__nav-btn{
  font-size: 16px;
  font-weight: 500;
  color: #000;
  background: none;
  border: none;
  font-weight: 600;
  position: relative;
}
.modal-image-edit__nav-btn.active{
  color: #364ed4;
}
.modal-image-edit__nav-btn.active::after{
  position: absolute;
  content: "";
  z-index: 10;
  width: 100%;
  height: 3px;
  background-color: #364ed4;
  left: 0;
  bottom: -5px;
}
.modal-image-edit__content{
  padding: 15px 0;
}
.modal-image-edit__imgs{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  max-height: 200px;
  overflow-y: auto;
}
.modal-image-edit__img{
  width: 33.33333%;
  padding: 5px;
  border: 2px solid #fff;
  border-radius: 11px;
}
.modal-image-edit__img.active,
.modal-image-edit__img:hover{
  border-color: #364ed4;
}
.modal-image-edit__img img{
  display: block;
  max-width: 100%;
  height: auto;
  cursor: pointer;
}
.modal-image-edit__img-inner{
  background-color: #d8d8d8;
  border-radius: 2px;
  height: 100%;
}
.modal-image-edit__nav{
  padding-top: 30px;
  position: relative;
}
.modal-image-edit__nav::after{
  content: '';
  border-bottom: 1px solid #d7d9e4;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
}
.modal-image-edit__upload-label{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px 20px;
    background-color: #f1f3ff;
    border-radius: 5px;
    color: #364ed4;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    cursor: pointer;
}
#upload-image-edit{
  display: none;
}
.modal-image-edit__card{
  width: 100%;
  height: 180px;
  border-radius: 15px;
}
.modal-image-edit__color{
  padding-top: 15px;
  padding-bottom: 15px;
}
.modal-image-edit__color-label{
  font-size: 12px;
  color: #808a8e;
}
.modal-image-edit__color-items{
  display: flex;
  align-items: center;
  padding-top: 10px;
}
.modal-image-edit__color-item{
  border: 2px solid #e5e5e5;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 3px;
  margin-right: 10px;
  cursor: pointer;
}
.modal-image-edit__color-item.active{
  border-color: #364ed4;
}
.modal-image-edit__color-item-inner{
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.modal-image-edit__icon{
  width: 130px;
  height: 130px;
  border-radius: 15px;
  margin: 0 auto;
}
.modal-image-edit__content-icon .modal-image-edit__img{
  height: 145px;
}
@media(max-width: 767px){
  .answer-modal,
  .message-wgt__answer-btn{
    display: none !important;
  }
}
.faq-add-btn{
  background: none;
  border: 1px solid #364ed4;
  border-radius: 2px;
  color: #364ed4;
  padding: 10px 15px;
  font-size: 15px;
}
.editor-class{
  padding-left: 10px;
  padding-right: 10px;
}
.choose-type-question__icon{
  position: relative;
}
.tooltip-img-wrap{
  position: absolute;
  z-index: 10;
  top: 0;
  right: -180px;
  padding: 5px;
  border-radius: 7px;
  border: 1px solid #d7d7d7;
  background-color: #fff;
}
.tooltip-img-wrap img{
  max-width: 150px;
  display: block;
}
.validation-error{
  font-size: 13px;
  color: #ff0000;
  padding-top: 3px;
}
.validation-error__emoji{
  margin-left: 15px;
}
.validation-error__option{
  padding-bottom: 15px;
}
.validation-error__center{
  text-align: center;
}
.field-wrap__input_error{
  border-color: #ff0000 !important;
}
.question-members-wgt__checked_all{
  margin-right: 25px;
}
.modal__order-content-col-change .track-vertical{
  background-color: #fff;
}
.modal__order-content-col-change .track-vertical.track-vertical__active{
  background-color: #f1f3ff;
}
.modal__order-link{
  color: #364ed4;
  font-size: 14px;
  font-weight: 400;
}
.modal-order-set-form{
  position: relative;
  display: inline-block;
  text-align: left;
}
.modal-order-set-form__button{
  padding: 0;
  font-size: 14px;
  font-weight: normal;
  color: #364ed4;
  background: none;
  border: none;
}
.modal-order-set-form__content{
  border-radius: 11px;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.18);
  background-color: #fff;
  padding: 25px;
  position: absolute;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  min-width: 250px;
  display: none;
}
.modal-order-set-form__content.active{
  display: block;
}
.track-vertical {
                        top: 2px;
                        bottom: 2px;
                        right: 2px;
                        background: #e5e5e5;
                    }

                    .track-vertical .thumb-vertical {
                        position: relative;
                        display: block;
                        width: 100%;
                        cursor: pointer;
                        border-radius: inherit;
                        background-color: rgba(125, 149, 255, 0.993);
                    }
                    .track-horizontal {
                        position: absolute;
                        height: 6px;
                        right: 2px;
                        bottom: 2px;
                        left: 2px;
                        border-radius: 3px;
                    }
                    .track-horizontal .thumb-horizontal {
                        position: relative;
                        display: block;
                        height: 100%;
                        cursor: pointer;
                        border-radius: inherit;
                        background-color: rgba(125, 149, 255, 0.993);
                    }

                    .view {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        right: 0px;
                        bottom: 0px;
                        overflow: scroll;
                        margin-right: -15px;
                    }
.react-datepicker-popper{
  z-index: 99999;
}
.react-datepicker__triangle{
  margin-left: 15px !important;
  transform: none !important;
}
.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer{
  color: inherit !important;
}
.edit-user-upload label{
  padding: 10px;
  text-align: center;
}
.edit-user-upload-error label{
  border: 1px solid #ff0000;
}
.edit-user-upload-logo{
  display: flex;
  align-items: center;
  width: 100%;
}
.upload-logo-company{
  display: block;
  max-height: 140px;
}
.not-found{
  display: flex;
  align-items: center;
}
.not-found__headline{
  font-size: 28px;
  font-weight: bold;
  line-height: 1.2;
}
.not-found__description{
  padding-top: 20px;
  padding-bottom: 30px;
  font-size: 16px;
  font-weight: 500;
}
.not-found__nav{
  list-style-type: none;
}
@media(min-width: 768px){
  .not-found__headline{
    font-size: 40px;
  }
  .not-found__description{
    font-size: 18px;
  }
  .not-found__nav{
    display: flex;
    margin: 0 -5px;
  }
}
.not-found__nav li{
  padding: 5px;
}
.not-found__img{
  max-width: 170px;
  height: auto;
  display: block;
  margin-bottom: 30px;
}
@media(min-width: 992px){
  .account-wgt__attention-wrap{
    padding: 0 15px;
  }
}
.account-wgt__attention{
  background-color: #f6f3eb;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.account-wgt__attention span{
  font-weight: bold;
}
.account-wgt__attention-icon{
  background-color: #e8e0ca;
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  font-weight: bold;
  border-radius: 50%;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-settings th:first-child,
.table-settings td:first-child{
  width: 90%;
}
.table-settings th:last-child,
.table-settings td:last-child{
  width: 10%;
}
.table-settings .team-table__nav{
  margin-left: auto;
}
.field-wrap__currency-name{
  position: relative;
}
.field-wrap__currency-name-item{
  position: absolute;
  top: 13px;
  right: 10px;
  z-index: 9;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}
.order-product{
  border-bottom: 1px solid #dde1e4;
  padding-top: 10px;
  margin-bottom: 30px;
}
.order-product__inner{
  display: flex;
  padding-bottom: 20px;
}
.order-product__col{
  width: 50%;
  flex: 0 0 auto;
}
.order-product__col-info{
  padding-right: 15px;
}
@media(min-width: 992px){
  .order-product__col-info{
    padding-right: 50px;
  }
}
.order-product__col-description{
  padding-top: 10px;
  font-size: 14px;
  line-height: 1.5;
}
.order-product__img{
  width: 100px;
  max-height: auto;
  display: block;
  border-radius: 3px;
}
.chrome-picker{
  box-shadow: none !important;
}

.library-review-wgt{
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #e1e3eb;
  margin-bottom: 15px;
}
.library-review-wgt_new{
  border-color: #364ed4;
}
.library-review-wgt__header{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 5px;
}
.library-review-wgt__header-info,
.library-review-wgt__header-right{
  width: 100%;
  flex: 0 0 auto;
}
.library-review-wgt__header-info{
  order: 13;
  display: flex;
  align-items: flex-start;
}
.library-review-wgt__header-right{
  order: 1;
  display: flex;
  align-items: center;
}
.library-review-wgt__thumbnail{
  width: 35px;
  height: auto;
  display: block;
  order: 13;
  margin-left: auto;
}
.library-review-wgt__header-info-inner{
  order: 1;
}
.library-reviw-wgt__header-rating{
  padding-left: 15px;
}
.library-reviw-wgt__header-rating ul {
  display: flex;
  align-items: center;
  list-style-type: none;
}
.library-reviw-wgt__header-rating ul li{
  padding-left: 5px;
}
@media(min-width: 576px){
  .library-review-wgt__thumbnail{
    margin-left: 0;
    order: 1;
  }
  .library-review-wgt__header-info-inner{
    padding-left: 15px;
    order: 13;
  }
}
@media(min-width: 768px){
  .library-review-wgt__header-info,
  .library-review-wgt__header-right{
    width: 50%;
  }
  .library-review-wgt__header-info{
    order: 1;
  }
  .library-review-wgt__header-right{
    order: 13;
  }
}
.library-review-wgt__user-name{
  color: #808a8e;
  font-size: 13px;
  padding-bottom: 5px;
}
.library-review-book-name{
  font-size: 15px;
  font-weight: 600;
  color: #000;
}
.library-review-wgt__header-right{
  display: flex;
  justify-content: flex-end;
}
.library-review-wgt__header-date{
  color: #808a8e;
  font-size: 13px;
}
.library-review-wgt__content{
  font-size: 14px;
  line-height: 1.5;
  padding-bottom: 10px;
}
.library-review-wgt__nav{
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 -5px;
}
.library-review-wgt__nav-col{
  width: 50%;
  flex: 0 0 auto;
  padding: 0 5px;
}
@media(min-width: 768px){
  .library-review-wgt__nav{
    width: 40%;
  }
}
.dropdown__review-book{
  display: inline-block;
}
.dropdown__btn-book-review{
  color: #364ed4;
  font-size: 14px;
}
.dropdown__review-book .dropdown__icon{
  stroke: #364ed4;
  margin-left: 5px;
}
.dropdown__review-book .dropdown-content{
  background-color: #fff;
  border: 1px solid #ebebeb;
  bottom: auto;
  top: 100%;
  left: 0;
  right: auto;
}
.dropdown__review-book .dropdown-nav__link{
  color: #000000;
  font-size: 14px;
  background: none;
  border: none;
  padding: 5px 10px;
}
.standart-table__library-name{
  width: 65%;
}
.standart-table__status{
  font-weight: 500;
}
.standart-table .standart-table__status_new,
.standart-table__request-status.standart-table__request-status_new{
  color: #00ae4c;
}
.standart-table .standart-table__status_progress,
.standart-table__request-status.standart-table__request-status_progress{
  color: #f19110;
}
.standart-table .standart-table__status_done,
.standart-table__request-status.standart-table__request-status_done{
  color: #000;
}
.standart-table__request-status.standart-table__request-status_canceled{
  color: ##ff0000;
}
.library-wgt__content-wgt-value_status{
  font-weight: 500;
}
.library-wgt__content-wgt-value_new{
  color: #00ae4c;
}
.library-wgt__content-wgt-value_progress{
  color: #f19110;
}
.library-wgt__content-wgt-value_done{
  color: #000;
}
.field-wrap__input_textarea{
  height: 100px;
  resize: none;
  font-family: sans-serif;
}
.library-form__categories-edit{
  padding-top: 5px;
}
.edit-category__add-category{
  padding-bottom: 15px;
}
.request-modal__content{
  padding-bottom: 20px;
}
.request-modal-wgt{
  padding-bottom: 15px;
}
.request-modal-wgt:last-child{
  padding-bottom: 0;
}
.request-modal-wgt__name{
  color: #626570;
  font-size: 14px;
  padding-bottom: 5px;
}
.request-modal-wgt__value{
  font-size: 14px;
}
.request-modal-wgt__value_lg{
  font-size: 16px;
  font-weight: bold;
}
.request-modal-wgt__value-link{
  color: #364ed4;
}
.request-modal__status{
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  padding: 15px 0;
}
.request-modal__message-btn,
.request-modal__message-wrap{
  border-bottom: 1px solid #e2e2e2;
  padding: 15px 0;
}
.request-modal__submit{
  padding-top: 15px;
}
.field-wrap__input_request-textarea{
  height: 350px;
  resize: none;
  font-family: sans-serif;
}
.request-modal__message{
  border-radius: 2px;
  background-color: #ededed;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 1.5;
}
.errors-content{
  padding: 0 10px;
}
@media(min-width: 992px){
  .errors-content{
    padding: 0 30px;
  }
}
.forgot-submit-success{
  text-align: center;
  display: flex;
  justify-content: center;
}
.forgot-submit-success__icon{
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
}
.forgot-submit-success__headline{
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 15px;
}
.completed-status{
  position: sticky;
  top: 46px;
  z-index: 9999;
}
@media(min-width: 768px){
  .completed-status{
    top: 67px;
  }
}
@media(min-width: 992px){
  .completed-status{
    top: 0;
  }
}
.completed-status__inner{
  background-color: #364ed5;
  padding: 10px 20px;
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.completed-status__send{
  background: none;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 0;
  font-size: 14px;
  color: #fff;
  border-bottom: 1px solid #fff;
  font-weight: 900;
  margin-left: 15px;
}
.modal-library-content__img{
  display: flex;
  padding: 5px 0 30px 0;
}
.modal-ad-content__img{
  width: 100px;
}
.modal-ad-content__info{
  flex: 1;
  padding-right: 30px;
}
.modal-ad-content__img img,
.modal-event-content__img img{
  max-width: 100%;
  height: auto;
  display: block;
}
.modal-library-content__img img{
  width: auto;
  max-width: 100%;
  max-height: 300px;
}
.modal-event-content__img{
  display: flex;
  padding-top: 15px;
}
.modal-event-content__inner{
  padding-top: 15px;
}
.modal-event-content__img img{
  width: auto;
  max-height: 200px;
  display: block;
  border-radius: 10px;
}
.modal-ad-content__inner{
  display: flex;
  align-items: flex-start;
}
.modal-library-content__headline,
.modal-ad-content__headline,
.modal-event-content__headline{
  color: #626570;
  font-size: 14px;
  margin-bottom: 5px;
}
.modal-library-content__value,
.modal-ad-content__value,
.modal-event-content__value{
  color: #000;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  word-wrap: break-word;
}
.modal-library-content__value-rating{
  display: flex;
  align-items: center;
}
.modal-library-content__rating{
  list-style-type: none;
  display: flex;
  margin-left: 10px;
}
.modal-library-content__rating li{
  padding-right: 5px;
}
#modal-container.two .modal.modal__order{
  max-width: 800px;
}
.modal__order-content{
  display: flex;
  align-items: flex-start;
  margin: 0 -15px;
}
.modal__order-content-col{
  width: 50%;
  flex: 0 0 auto;
  padding: 0 15px;
}
.modal__order-content-col-change .modal__order-content-history{
  overflow-y: hidden;
}
.modal__order-info-headline{
  color: #000;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid #dde1e4;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.modal__order-info-headline_bn{
  border-bottom: none;
  padding-right: 10px;
  margin-bottom: 0;
}
.modal__order-info-headline-inner{
    display: flex;
  align-items: center;
  border-bottom: 1px solid #dde1e4;
  padding-bottom: 8px;
  width: 100%;
}
.modal__order-info-headline span{
  margin-left: 10px;
}
.modal__order-info-product{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #e2e2e2;
}
.modal__order-info-product_no_flex{
  display: block;
}
.modal__order-info-product-name{
  margin-right: 30px;
  font-size: 18px;
  font-weight: bold;
}
.modal__order-info-product-img{
  width: auto;
  max-height: 50px;
}
.modal__order-info-product-table{
  padding: 10px 0;
  border-bottom: 1px solid #e2e2e2;
}
.modal__order-info-product-table:last-child{
  border-bottom: none;
}
.modal.modal__order table{
  width: 100%;
}
.modal.modal__order table td{
  padding: 5px 0;
}
.modal.modal__order table td:first-child{
  color: #626570;
  font-size: 14px;
}
.modal.modal__order table td:last-child{
  text-align: right;
  color: #000;
  font-size: 14px;
  font-weight: bold;
}
.modal__order-info-comment{
  padding-top: 15px;
}
.modal__order-info-comment-headline{
  font-size: 14px;
  color: #626570;
  margin-bottom: 10px;
}
.modal__order-info-comment-text{
  padding: 10px 15px;
  border-radius: 5px;
  background-color: #ededed;
  color: #000;
  font-size: 14px;
}
.modal__order-content-history{
  padding: 0 20px 15px 20px;
  border-radius: 5px;
  height: 100%;
}
.modal__order-content-history-title{
  font-size: 16px;
  color: #808a8e;
  margin-bottom: 15px;
}
.modal__order-content-history-row{
  padding: 7px 0;
}
.modal__order-content-history-date{
  font-size: 12px;
  color: #626570;
  padding-bottom: 3px;
}
.modal__order-content-history-value{
  font-size: 14px;
  color: #000;
  word-wrap: break-word;
  padding-right: 10px;
}
.modal__order-nav{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 15px;
}
.modal__order-nav-button{
  background-color: #f1f3ff;
  border: none;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  margin-right: 10px;
}
.modal__order-nav-button .loader{
  margin: 0;
  width: 50px;
  margin: 1em auto;
}
.modal__order-nav-button-dropdown{
  color: #364ed4;
  font-size: 15px;
  font-weight: 400;
  width: auto;
  padding: 0 15px;
}
.modal__order-nav-button-icon{
  width: 16px;
  height: 17px;
}
.modal__order-nav-dropdown{
  position: relative;
}
.modal__order-nav-dropdown ul li button{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal__order-nav-dropdown ul li button .loader{
  width: 17px;
  height: 17px;
  margin: 0;
  padding: 0;
}
.modal__order-nav-content-dropdown{
  display: none;
  position: absolute;
  left: 0;
  right: auto;
  top: calc(100% + 10px);
  z-index: 100;
  padding: 5px 10px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.21);
  min-width: 100%;
  white-space: nowrap;
}
.modal__order-nav-content-dropdown_new{
  background-color: #F1F3FE;
  color: #364ED4;
}
.modal__order-nav-content-dropdown_in_progress{
  background-color: #FFF0E1;
  color: #D87000;
}
.modal__order-nav-content-dropdown_done{
  background-color: #DFF9EA;
  color: #009D45;
}
.modal__order-nav-content-dropdown_declined{
  background-color: #FFE7EB;
  color: #D01A36;
}
.modal__order-nav-content-dropdown.active{
  display: block;
}
.modal__order-nav-content-dropdown ul{
  list-style-type: none;
}
.modal__order-nav-content-dropdown li{
  padding: 5px 0;
}
.modal__order-nav-content-dropdown li button{
  font-size: 15px;
  padding: 8px 10px;
  border-radius: 2px;
  border: none;
  display: block;
  width: 100%;
  cursor: pointer;
  text-align: left;
}
.modal__order-nav-content-dropdown li button:hover{
  background-color: #f1f3ff;
  color: #364ed4;
}
.modal__order-nav-button-dropdown.active{
  background-color: #364ed4;
  color: #fff;
}
.modal__order-comment-field{
  display: block;
  width: 100%;
  min-width: 320px;
  min-height: 125px;
  border: 1px solid #e1e1e1;
  background-color: #fff;
  resize: none;
  font-family: sans-serif;
  padding: 10px;
  font-size: 14px;
}
.modal__order-nav-content-dropdown-comment{
  padding: 15px;
  max-width: 500px;
}
.modal__order-send-message{
  padding: 15px 0;
  word-wrap: break-word;
}
.modal__order-send-message .styled-checkbox + label{
  align-items: flex-start;
}
.modal__order-send-message .styled-checkbox + label:before{
  margin-right: 10px;
  min-width: 20px;
  min-height: 20px;
}
.modal__order-content-headline{
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000;
  margin-bottom: 10px;
}
.review-count-wrap{
  display: flex;
  align-items: center;
}
.review-count{
  padding-right: 5px;
}
.new-review-count{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #22ca7b;
  border-radius: 5px;
  font-size: 10px;
  font-weight: bold;
  min-width: 15px;
  height: 15px;
}
.users-modal-wgt{
  padding-bottom: 10px;
}
.users-modal-wgt__nav{
  display: flex;
  align-items: center;
}
.users-modal-wgt__inner{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.users-modal-wgt__info{
  display: flex;
  align-items: center;
}
.users-modal-wgt__avatar{
  width: 32px;
  height: 32px;
  display: block;
  margin-right: 15px;
  border-radius: 50%;
}
.users-modal-wgt__name{
  font-size: 16px;
  color: #000;
}
.comment-modal-wgt{
  padding-bottom: 16px;
}
.comment-modal-wgt__avatar{
  width: 34px;
}
.comment-modal-wgt__avatar-img{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: block;
}
.comment-modal-wgt__inner{
  display: flex;
}
.comment-modal-wgt__content-name{
  font-size: 14px;
  font-weight: bold;
}
.comment-modal-wgt__content-link{
  color: #000;
  margin-bottom: 5px;
  display: inline-block;
}
.comment-modal-wgt__content-date{
  font-size: 12px;
   font-weight: 500;
   font-stretch: normal;
   font-style: normal;
   line-height: normal;
   letter-spacing: normal;
   color: #a2a2a2;
   margin-bottom: 5px;
}
.comment-modal-wgt__content-txt{
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}
.modal__order-content-nav-row{
  display: flex;
}
.btn-modal{
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  border: none;
  margin-right: 7px;
  margin-bottom: 10px;
}
.btn-modal:last-child{
  margin-right: 0;
}
.btn-modal_theme_blue{
  color: #364ed4;
  background-color: #f1f3ff;
}
.btn-modal_theme_red{
  color: #d01a36;
  background-color: #fae6ed;
}
.btn-modal-icon{
  width: 36px;
  height: 36px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-right: 7px;
  margin-bottom: 10px;
}
.btn-modal-icon_theme_blue{
  background-color: #f1f3ff;
}
.btn-modal-icon_theme_blue svg{
  fill: #364ed4;
  width: 16px;
  height: 17px;
  display: block;
}
.btn-modal-icon_theme_red{
  background-color: #fae6ed;
}
.btn-modal-icon_theme_red svg{
  fill: #d01a36;
  width: 16px;
  height: 17px;
  display: block;
}
.modal__order-content-nav-label{
  border-radius: 5px;
  font-size: 14px;
  margin-right: 7px;
  margin-bottom: 10px;
}
.modal__order-content-nav-label_theme_red{
  background-color: #fae6ed;
  padding: 5px 5px 5px 15px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal__order-content-nav-label_theme_red span{
  color: #d01a36;
  margin-right: 15px;
}
.modal__order-content-nav-label_theme_red button{
  background-color: #fff;
  color: #364ed4;
  font-size: 14px;
  font-weight: 500;
  border: none;
  padding: 5px 10px;
  border-radius: 2px;
}
.modal__tab-nav{
  border-bottom: 1px solid #dde1e4;
  padding-bottom: 5px;
}
.modal__tab-nav-users-wrap{
  display: flex;
  align-items: center;
}
.modal__tab-nav-users{
  display: flex;
  align-items: center;
  padding-right: 15px;
}
.modal__tab-nav-users .modal__tab-btn{
  margin-right: 0;
}
.modal__tab-btn{
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000;
  border: none;
  padding: 0;
  background: none;
  margin-right: 15px;
  position: relative;
  display: flex;
  font-family: 'SF-Pro-Display-Regular', sans-serif;
}
.modal__tab-btn.active{
  color: #364ed4;
}
.modal__tab-btn.active::after{
  content: "";
  position: absolute;
  left: 0;
  bottom: -6px;
  width: 100%;
  height: 2px;
  background-color: #364ed4;
}
.modal__tab-btn-count{
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  height: 20px;
  min-width: 21px;
  border-radius: 5px;
  background-color: #f3f3f3;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal__scroll-content.modal__scroll-content-thumb{
  padding: 15px 10px 15px 0;
}
.modal__tab-btn.active .modal__tab-btn-count{
  background-color: #edeff6;
  color: #364ed4;
}
.modal__tab{
  display: none;
}
.modal__tab.active{
  display: block;
}
#modal-container.two .modal.modal__posts {
    max-width: 800px;
}
.account-preview{
  padding: 10px 20px;
}
.account-preview__headline{
  margin-bottom: 15px;
  font-size: 21px;
  font-weight: bold;
}
.account-preview__inner{
  display: flex;
  align-items: flex-start;
}
.account-preview__inner_preview{
  padding-bottom: 30px;
}
.account-preview__info{
  width: 70%;
  flex: 0 0 auto;
  word-wrap: break-word;
}
.account-preview__info-nav-between{
  display: flex;
  justify-content: space-between;
}
.account-preview__info strong{
  font-family: 'SF-Pro-Display-Bold', sans-serif;
}
.account-preview__img{
  width: 30%;
  flex: 0 0 auto;
}
.account-preview__img-description{
  font-size: 14px;
  color: #808a8e;
  padding-bottom: 10px;
  text-align: center;
}
.account-preview__img img{
  display: block;
  max-width: 100%;
  max-height: 750px;
  margin: 0 auto;
}
.account-preview__wgt{
  background-color: #fff;
  border-radius: 5px;
  padding: 25px;
  margin-bottom: 15px;
  word-wrap: break-word;
}
.account-content__inner.account-preview{
  background-color: #f2f4f5;
}
.account-preview__wgt_row{
  display: flex;
  align-items: flex-start;
}
.account-preview__wgt_col-info{
  width: 70%;
  flex: 0 0 auto;
  word-break: break-all;
}
.account-preview__wgt_col-info-survey,
.account-preview__wgt_col-info-product{
  width: 100%;
}
.account-preview__wgt_col-logo{
  max-width: 30%;
  flex: 1;
}

.account-preview__wgt_col-logo img{
  max-width: 115px;
  height: auto;
  display: block;
  margin-left: auto;
}

.account-preview__info-imgs{
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 0 -5px;
}

.account-preview__info-imgs-thumb{
  width: 92px;
  height: 92px;
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.account-preview__info-imgs-thumb img{
  display: block;
  max-width: 100%;
  height: auto;
  cursor: pointer;
}

.account-preview__wgt-headline{
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}
.account-preview__wgt-smile{
  margin-right: 10px;
  font-size: 18px;
}
.account-preview__wgt-headline-text{
  font-size: 18px;
  font-weight: bold;
}
.account-preview__info-row{
  display: flex;
  flex-wrap: wrap;
}
.account-preview__info-row_space_between{
  justify-content: space-between;
}
.account-preview__info-wgt{
  width: 100%;
  flex: 0 0 auto;
  padding-bottom: 15px;
}
.account-preview__info-wgt_width_auto{
  width: auto;
  max-width: 100%;
}
.account-preview__info-wgt_width_50{
  width: 50%;
}
.account-preview__info-wgt_width_33{
  width: 33.333333%;
}
.account-preview__info-wgt-label{
  font-size: 14px;
  color: #808a8e;
  margin-bottom: 5px;
}
.account-preview__info-wgt-value{
  font-size: 16px;
}
.account-preview__info-wgt-value_editor ol,
.account-preview__info-wgt-value_editor ul{
  margin-left: 15px !important;
}
.account-preview__info-wgt-link{
   color: #364ed4;
   font-size: 16px;
   text-decoration: underline;
}
.button-standart{
  padding: 12px 20px;
  border-radius: 5px;
  border: none;
  margin-right: 15px;
  font-size: 16px;
  font-weight: 500;
}
.button-standart_theme_blue{
  background-color: #f1f3ff;
  color: #364ed4;
}
.button-standart_theme_red{
  background-color: #fae6ed;
  color: #d01a36;
}
.account-preview__wgt_col-logo .thumb{
  max-width: 70%;
  display: block;
  margin: 0 auto;
}
.preview-submit-btn{
  border-radius: 5px;
  border: none;
  background-color: #00ae4c;
  color: #fff;
  display: block;
  padding: 12px 20px;
  text-align: center;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  position: relative;
}
.preview-submit-btn .loader{
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  height: 37px;
  width: 60px;
  text-align: center;
  padding: 1em;
  margin: 0 auto 0;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  vertical-align: top;
}
.preview-submit-btn svg path,
.preview-submit-btn svg rect{
  fill: #fff;
}
.button-back{
  background-color: #fff;
  border: none;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  color: #000;
  padding: 10px 0;
}
.button-back__txt{
  display: none;
}
.button-back svg{
  width: 16px;
  height: 12px;
  stroke: #000;
  display: block;
  margin-right: 12px;
}
@media(min-width: 768px){
  .button-back{
    padding: 10px 15px;
  }
  .button-back__txt{
    display: inline-block;
  }
  .button-back_theme_gray{
    background-color: #f1f3ff;
  }
}
.account-content__header-preview{
  padding: 0 0 10px 0;
}
.account-preview__info-wgt-contacts ul{
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
}
.account-preview__info-wgt-contacts ul li{
  padding-right: 20px;
  display: flex;
  align-items: center;
}
.account-preview__info-wgt-contacts ul li img{
  width: 18px;
  height: 18px;
  display: block;
  margin-right: 8px;
}
.account-preview__info-wgt-description h1,
.account-preview__info-wgt-description h2,
.account-preview__info-wgt-description h3,
.account-preview__info-wgt-description h4,
.account-preview__info-wgt-description h5,
.account-preview__info-wgt-description h6{
  font-size: 16px !important;
}
.account-preview__survey-wgt{
  border-radius: 5px;
  background-color: #f2f4f5;
  padding: 15px;
  margin-bottom: 7px;
}
.account-preview__survey-wgt-question{
  padding-bottom: 5px;
  display: flex;
  align-items: flex-start;
}
.account-preview__survey-wgt-num{
  min-width: 20px;
  font-size: 14px;
  font-weight: bold;
  padding-left: 3px;
  line-height: 1.4;
}
.account-preview__survey-wgt-text{
  font-size: 16px;
  font-weight: bold;
  color: #000;
}
.account-preview__survey-wgt-description{
  width: 100%;
}
.account-preview__survey-wgt-option-name{
  font-size: 14px;
  color: #000;
  word-break: break-all;
}
.account-preview__survey-wgt-option-type{
  width: 20px;
  min-width: 20px;
  display: flex;
  margin-top: 3px;
}
.account-preview__survey-wgt-option-type .checkbox{
  width: 13px;
  height: 13px;
  border: 1px solid #000;
  display: inline-block;
}
.account-preview__survey-wgt-option-type .radio{
  width: 13px;
  height: 13px;
  border: 1px solid #000;
  border-radius: 50%;
  display: inline-block;
}
.account-preview__survey-wgt-options{
  list-style-type: none;
}
.account-preview__survey-wgt-options li{
  padding: 5px 0;
  display: flex;
  align-items: flex-start;
}
.account-preview__survey-wgt-option-free{
  color: #808a8e;
  font-style: italic;
}
.account-preview__survey-wgt-question-quick{
  font-size: 14px;
  color: #000;
}
.ads-delete-contact{
  background: none;
  border: none;
  padding: 0;
  margin-top: 14px;
}
.ads-delete-contact svg{
  width: 16px;
  height: 17px;
  display: block;
  margin-left: 10px;
}
.field-wrap__remove{
  display: flex;
  align-items: flex-start;
}
.field-wrap__remove-inner{
  width: 100%;
}
.status-label{
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  padding: 4px 10px;
  color: #364ED4;
  background-color: #F1F3FE;
  display: inline-block;
  white-space: nowrap;
}
.status-label_theme_yellow{
  background-color: #FFF0E1;
  color: #D87000;
}
.status-label_theme_green{
  background-color: #DFF9EA;
  color: #009D45;
}
.status-label_theme_red{
  background-color: #FFE7EB;
  color: #D01A36 ;
}
.status-label_theme_booking{
  border-color: #e3e6f0;
  color: #000;
}
.status-label_theme_booking span{
  color: #364ed4;
}
.status-label-txt{
  color: #364ED4;
  font-size: 12px;
}
.status-label-txt_theme_yellow{
  color: #D87000;
}
.status-label-txt_theme_green{
  color: #01ae4c;
}
.status-label-txt_theme_red{
  color: #D01A36;
}
.table-wrap-with-tab{
  flex: 1;
}
.standart-table__bonuses-wgt{
  padding: 0 15px;
}
.label-status{
  background-color: #f1f3ff;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  padding: 5px 15px;
  margin-bottom: 15px;
}
.bonuses-info__general-label{
  padding: 20px 0;
}
.bonuses-info__general-label-inner{
  border-radius: 5px;
  border: 2px solid #364ed4;
  padding: 10px 15px;
  font-size: 16px;
  line-height: 1.5;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.bonuses-info__general-label-txt{
  padding-right: 30px;
}
.user-activites{
  background-color: #f2f4f5;
}
.control-wgt{
  position: relative;
  margin-left: auto;
}
.control-wgt.control-wgt__pos{
  position: absolute;
  bottom: 5px;
  right: 0;
}
.control-wgt.control-wgt__faq{
  position: absolute;
  top: 13px;
  right: 0;
}
@media(min-width: 992px){
  .control-wgt.control-wgt__pos{
    position: relative;
    bottom: auto;
  }
}
.control-wgt_align_left{
  margin-left: 0;
}
.control-wgt__message{
  display: inline-block;
}
.control-btn{
  background-color: #fff;
  border-radius: 7px;
  padding: 2px 5px;
  border: none;
  position: relative;
  z-index: 1;
}
.control-btn.control-btn__message{
  padding: 0;
  margin-left: 0;
  position: relative;
  z-index: 1000;
}
.control-content{
  position: absolute;
  top: 100%;
  left: auto;
  right: 0;
  z-index: 9999;
  padding: 5px;
  border-radius: 5px;
  display: none;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.07);
  border: 1px solid #e1e1e1;
}
.control-content__odd .control-content__list{
  border-bottom: 1px solid #ededed;
}
.control-content__odd .control-content__list:last-child{
  border-bottom: none;
}
.control-wgt__message .control-content{
  left: 0;
  right: auto;
}
.control-content.active{
  display: block;
}
.control-content__list{
  list-style-type: none;
}
.control-content__btn {
  border: none;
  background: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  padding: 10px;
  border-radius: 2px;
  font-size: 14px;
  color: #000;
  font-family: 'SF-Pro-Display-Regular', sans-serif;
}
.control-btn__icon{
  width: 37px;
  height: 22px;
  display: block;
  fill: none;
}
.control-content__btn:hover{
  background-color: #f1f2f6;
}
.control-content__btn:hover .control-btn__icon{
  fill: #fff;
}
.control-content__btn .loader{
  width:  17px;
  height: 17px;
  padding: 0;
  margin: 0 10px 0 0;
}
.control-content__btn_delete .loader svg path,
.control-content__btn_delete .loader svg rect{
  fill: #d01a36;
}
.control-content__icon{
  width: 20px;
  height: 20px;
  stroke: #000;
  fill: none;
  display: block;
  margin-right: 10px;
}
.control-content__btn_delete{
  color: #d01a36;
}
.header-nav{
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin:  0 -5px;
}
.header-nav__col{
  padding: 0 5px;
}
.account-content__header-title{
  padding: 0 10px;
}
.header-nav-products{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 0 10px;
}
@media(min-width: 992px){
  .header-nav{
    padding: 0 30px;
  }
  .account-content__header-title{
    padding: 0 30px;
  }
  .header-nav-products{
    padding: 10px 30px 0 30px;
  }
}
.control-btn__icon_product{
  fill: #ededed;
}
.label-status-txt{
  text-align: right;
  color: #364ED4;
  font-size: 14px;
  font-weight: bold;
}
.label-status-txt_theme_green{
  color: #009D45;
}
.label-status-txt_theme_orange{
  color: #D87000;
}
.label-status-txt_theme_red{
  color: #D01A36;
}
.modal__order-nav-button_new{
  color: #364ED4;
  background-color: #F1F3FE;
}
.modal__order-nav-button_done{
  background-color: #FFF0E1;
  color: #D87000;
}
.modal__order-nav-button_in_progress{
  background-color: #FFF0E1;
  color: #D87000;
}
.modal__order-nav-button_done{
  background-color: #DFF9EA;
  color: #009D45;
}
.modal__order-nav-button_declined{
  background-color: #FFE7EB;
  color: #D01A36;
}
.team-nav_space_between{
  display: flex;
  justify-content: space-between;
}
.team-nav_space_end{
  justify-content: flex-end;
}
.control-btn-orders{
  background-color: #F1F3FE;
  color: #364ED4;
  border: none;
  border-radius: 5px;
  height: 44px;
}
.control-content_align_right{
  left: 0;
  right: auto;
}
.control-btn-orders{
  display: flex;
  align-items: center;
  padding: 5px 10px;
}
.control-btn-orders__icon{
  stroke: #364ED4;
  width: 18px;
  height: 18px;
  display: block;
  margin-right: 10px;
}
.team-nav{
  display: flex;
  align-items: center;
}
.btn-header__inner{
  display: none;
}
.btn-header__icon-mob{
  display: block;
  width: 24px;
  height: 24px;
}
@media(min-width: 992px){
  .btn-header__inner{
    display: flex;
    align-items: center;
  }
  .btn-header__icon-mob{
    display: none;
  }
}
@media(max-width: 991px){
  .btn-header{
    width: 36px;
    height: 36px;
    padding: 0;
  }
}
.modal__scroll-content{
  padding: 15px 10px 15px 0;
}
@media(max-width: 991px){
  .account-content__header-user{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .btn-header.btn_size_lg {
    width: 44px;
    height: 44px;
  }
  .btn-header.btn_size_lg .btn-header__icon-mob{
    width: 26px;
    height: 26px;
  }
}
.track-vertical{
  border-radius: 10px;
}
.track-vertical .thumb-vertical-scroll{
  background-color: #364ed4;
}
.track-vertical.track-vertical-scroll{
  background-color: #f7f7f7;
  border-radius: 2px;
}
.modal-image-edit__main-avatar .reactEasyCrop_CropArea{
  border-radius: 50%;
}
.modal-image-edit__main-avatar{
  height: 440px;
}
.reactEasyCrop_CropArea{
  border: 2px solid #0f6 !important;
  color: #808080 !important;
  border-radius: 15px;
}
.modal-image-edit__main-preview-empty{
  height: 100%;
  background-color: #808080;
}
.modal-image-edit__main-preview-empty .modal-image-edit__main-preview-inner{
  border-radius: 15px;
  background-color: #fff;
}
.upload-image-info{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #979797;
  border-radius: 2px;
  height: 39px;
  padding: 0 12px;
}
.upload-image-info__btn{
  background: none;
  border: none;
  padding: 0;
}
.upload-image-info__delete{
  width: 14px;
  height: 16px;
  display: block;
}
.modal-nav{
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}
.modal-nav__item{
  padding-right: 7px;
}
.modal-status{
  background-color: #f1f3ff;
  border-radius: 5px;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 15px;
}
.modal-status_theme_red{
  background-color: #fae6ed;
}
.modal-status__label{
  color: #00ae4c;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
}
.modal-status__label_theme_red{
  color: #d01a36;
}
.modal-status__label_theme_black{
  color: #000;
}
.modal-status__btn{
  background-color: #fff;
  color: #364ed4;
  font-size: 14px;
  font-weight: 500;
  border: none;
  border-radius: 2px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
}
.modal-status__btn .loader{
  margin-left: 5px;
}
.user-modal-wgt{
  display: flex;
  align-items: center;
  padding: 5px 0;
}
.user-modal-wgt__info{
  display: flex;
  align-items: center;
  position: relative;
}
.user-modal-wgt__info .loader{
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  z-index: 10;
  width:  17px;
  height: 17px;
  padding: 0;
  margin: 0;
  vertical-align: unset;
}
.user-modal-wgt__img{
  width: 25px;
  height: 25px;
  display: block;
  border-radius: 50%;
  margin-right: 12px;
}
.user-modal-wgt__name{
  color: #000000;
  font-size: 14px;
  flex: 1;
  word-break: break-word;
}
.account-wgt__catalog-notes{
  padding: 5px 0 5px 0;
}
.team-nav__shop{
  display: block;
}
.header-content-nav__btn-add-shop{
  margin-right: 15px;
}
.account-wgt__catalog-notes-wgt{
  border: 1px dashed #d7d9e4;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  border-radius: 8px;
}
.account-wgt__catalog-notes-wgt-content{
  padding-right: 30px;
}
.account-wgt__catalog-notes-wgt-headline{
  font-size: 12px;
  color: #808a8e;
  margin-bottom: 5px;
}
.account-wgt__catalog-notes-wgt-description{
  font-size: 14px;
  color: #000;
}
.account-wgt__catalog-notes-wgt-nav{
  display: flex;
  align-items: center;
}
.account-wgt__catalog-notes-wgt-nav button{
  margin-left: 7px;
}
.modal__description{
  padding: 10px 0;
}
.add-notes-btn{
  display: flex;
  align-items: center;
}
.add-notes-btn__phone-wrap{
  position: relative;
}
.add-notes-btn__phone{
  width: 18px;
  height: 29px;
  display: block;
  margin-left: 10px;
}
.add-notes-btn__phone-img{
  position: absolute;
  top: 0;
  right: -180px;
  left: auto;
  transform: none;
  z-index: 100;
  box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px #d7d7d7;
  border-radius: 7px;
  padding: 5px;
}
.add-notes-btn__phone-img img{
  max-width: 150px;
  display: block;
}
.event-edit-status-deleted{
  background-color: #FFE7EB;
  color: #D01A36;
  padding: 10px 15px;
  border-radius: 5px;
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.event-edit-status-deleted__txt{
  color: #D01A36;
  margin-right: 10px;
}
.event-edit-status-deleted__btn{
  background-color: #fff;
  color: #364ed4;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
}
.tr-disabled td{
  color: #a7a7a7 !important;
}
.header-content-nav{
  padding-bottom: 15px;
  display: block;
}
.header-content-nav .header-content-nav__btn{
  margin-right: 15px;
  font-size: 14px;
  line-height: normal;
  font-weight: normal;
  font-family: sans-serif;
}
.header-content-nav .header-content-nav__btn:last-child{
  margin-right: 0;
}
.header-content-nav{
  padding-left: 10px;
  padding-right: 10px;
}
@media(min-width: 992px){
  .header-content-nav{
    padding-left: 30px;
    padding-right: 30px;
  }
}
.modal-item-status{
  font-size: 14px;
  font-weight: 700;
}
.modal-item-status_theme_blue{
  color: #364ed4;
}
.modal-item-status_theme_yellow{
  color: #d87000;
}
.modal-item-status_theme_green{
  color: #009d45;
}
.modal-item-status_theme_red{
  color: #D01A36;
}
.styled-checkbox + .checkbox-label__disabled::before{
  background: #f7f8ff;
}
.data-table__sort{
  cursor: pointer;
}
.data-table__sort span{
  display: flex;
  align-items: center;
}
.data-table__sort_active span{
  color: #000;
  font-weight: 600;
}
.data-table__sort-icon{
  width: 10px;
  height: 10px;
  display: block;
  fill: #626E77;
  margin-top: 2px;
  margin-left: 5px;
}
.data-table__sort_active .data-table__sort-icon{
  fill: #000;
}
.data-table__control-inner{
  display: flex;
  align-items: center;
  margin-left: auto;
}
.data-table__hide{
  position: relative;
  cursor: pointer;
}
.data-table__hide-product{
  position: static;
}
.data-table__hide-icon{
  width: 18px;
  height: 16px;
  fill: #808a8e;
  display: block;
  margin-right: 10px;
}
.data-table__hide-content{
  display: none;
  width: max-content;
  padding: 5px 12px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  position: absolute;
  left: auto;
  top: calc(100% + 5px);
  z-index: 10;
  background-color: #fff;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  border-radius: 5px;
  color: #000;
}
@media(min-width: 620px){
  .data-table__hide-content{
    left: auto;
  }
  .data-table__hide-product{
    position: relative;
  }
}
@media(max-width: 1199px){
  .product-wgt:nth-child(even) .data-table__hide-content{
    left: auto;
    right: 0;
  }
}
@media(min-width: 1200px){
  .product-wgt:nth-child(4n) .data-table__hide-content{
    left: auto;
    right: 0;
  }
}
@media(min-width: 1440px){
  .product-wgt:nth-child(5n) .data-table__hide-content{
    left: auto;
    right: 0;
  }
}
.data-table__hide-content-product{
  left: 0;
}
.data-table__hide:hover .data-table__hide-content{
  display: block;
}
.data-table__count-wrap{
  display: flex;
  align-items: center;
}
.data-table__count{
  margin-left: 5px;
  background-color: #22ca7b;
  border-radius: 5px;
  padding: 2px 5px;
  min-width: 15px;
  min-height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 10px;
}
#modal-container.two .modal.modal__imgs{
  max-width: 60%;
  width: 60%;
}
.modal__img-setting{
  width: 325px !important;
  height: 325px !important;
}
#modal-container.two .modal.modal__product-img{
  width: 605px;
  max-width: 605px;
}
.modal__img-setting-inner{
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  height: 325px;
  display: flex;
  align-items: center;
}
.modal__img-setting-inner .reactEasyCrop_CropArea{
  border: none !important;
}
.modal__img-setting-wrap{
  height: 100%;
  padding-bottom: 10px;
}
.modal__img-row{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -5px;
  padding-bottom: 20px;
}
.modal__col{
  width: 83px;
  height: 83px;
  flex: 0 0 auto;
  padding: 5px;
}
.modal__col-inner{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 5px;
  cursor: pointer;
}
.modal__col.active .modal__col-inner{
  border-color:#364ed4;
  border-width: 3px;
}
.modal__col-inner:hover .edit-product-img__remove{
  display: block;
}
.modal__col-inner img{
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.modal__img-nav{
  display: flex;
  align-items: center;
  margin: 0 -5px;
}
.modal__img-nav-col{
  width: 50%;
  flex: 0 0 auto;
  padding: 0 5px;
}

.calendar-field-row{
  display: flex;
  align-content: center;
}
.calendar-field__col{
  width: 50%;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  position: relative;
}
.calendar-field__col.calendar-field__col_full{
  width: 100%;
}
.calendar-field__col:first-child{
  padding-right: 10px;
}
.calendar-field__col:last-child{
  padding-left: 10px;
}
.calendar-field__col:first-child.calendar-field__col_full{
 padding-right: 0;
}
.calendar-field__col:first-child .calendar-field__input-calendar{
  padding-right: 10px;
}
.calendar-field__col:first-child::after{
  content: "–";
  position: absolute;
  top: 50%;
  right: -4.5px;
  transform: translateY(-50%);
}
.calendar-field__col-endoff{
  padding-left: 0 !important;
  width: auto !important;
}
.calendar-field__col-endoff .calendar-field__input-calendar{
  width: auto !important;
}
.calendar-field__col-endoff.calendar-field__col .calendar-field__input-calendar{
  padding-left: 0 !important;
}
.calendar-field__col-endoff.calendar-field__col::after{
  display: none;
}
.calendar-field__col:last-child .calendar-field__input-calendar{
  padding-right: 10px;
}
.calendar-field__col-off-end-date{
  width: 60%;
}
.calendar-field__col-off-end-date::after{
  display: none;
}
.calendar-field__col-off-end-date .calendar-field__input-calendar{
  width: 100%;
  padding-right: 0 !important;
}
.calendar-field__col-once-time{
  width: 40%;
}
.calendar-field__col-once-time .calendar-field__input-calendar{
  width: 100%;
}
.calendar-field__col-once-time::after{
  right: 5.5px !important;
}
.calendar-field__input-calendar{
  width: 60%;
  flex: 0 0 auto;
}
.calendar-field__input-calendar-inner{
  position: relative;
}
.calendar-field__input-time{
  width: 40%;
  flex: 0 0 auto;
}
.calendar-field__input-time-once{
  width: 20%;
  padding-left: 10px;
}
.calendar-field__value{
  height: 44px;
  border: 1px solid #e1e1e1;
  background-color: #fff;
  font-size: 16px;
  color: #000;
  border-radius: 5px;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
}
.calendar-field__value.disabled{
  color: #808a8e;
}
.calendar-field__value_error{
  border-color: #ff0000;
}
@media(min-width: 1200px) and (max-width: 1439px){
  .calendar-field__value{
    padding: 0 5px;
  }
  .calendar-field__col:first-child .calendar-field__input-calendar{
    padding-right: 5px;
  }
  .calendar-field__col:last-child .calendar-field__input-calendar{
    padding-left: 5px;
  }
}
.calendar-field__input-time-inner{
  position: relative;
}
.calendar-field__time-content{
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.07);
  border: 1px solid #e1e1e1;
}
.calendar-field__time-content.dropdown-top{
  top: auto;
  bottom: calc(100% + 10px);
}
.calendar-field__time-content ul{
  list-style-type: none;
}
.calendar__dropdown{
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  right: auto;
  z-index: 9999;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.07);
  border: 1px solid #e1e1e1;
  padding: 15px;
}
.calendar__dropdown tr td{
  min-width: 39px;
  min-height: 39px;
}
.calendar-field__value.active{
  border-color: #364ed4;
}
.calendar-field__time-content .thumb-vertical{
  background-color: #364ed4;
}
.calendar-field__list-time{
  padding: 5px 0;
}
.calendar-field__list-time li{
  cursor: pointer;
  padding: 5px 10px;
}
.tab-items__inner{
  overflow-x: auto;
}
.tab-items__inner::-webkit-scrollbar {
  display: none;
}
.tab-items__inner {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.tab_theme_green .tab-items__link-amount,
.tab_theme_green .users-tab__link-amount{
  background-color: #22ca7b;
  color: #fff;
}
.tab-items__link{
  white-space: nowrap;
  background: none;
  border: none;
  padding: 0;
}
.tab-navs{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}
.tab-navs__inner{
  display: flex;
  align-items: center;
}
.tab-navs__inner .btn{
  margin-right: 10px;
}
.tab-navs__inner .btn:last-child{
  margin-right: 0;
}
@media(min-width: 992px){
  .tab-navs{
    padding: 0 30px;
  }
}
.filter-product-wrap{
  display: flex;
  align-items: center;
}
.library-filter-dropdown .btn{
  padding: 0;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.library-filter-dropdown .btn svg{
  margin-right: 0;
}
.user-modal-wgt__notification{
  background: none;
  border: none;
  padding: 0;
  margin-right: 5px;
}
.user-modal-wgt__notification-icon{
  stroke: #D7D9E4;
  display: block;
  width: 21px;
  height: 21px;
}
.user-modal-wgt__notification:hover .user-modal-wgt__notification-icon{
  stroke: #364ed4;
}
.modal__img-zoom-btns{
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 10;
}
.modal__img-zoom-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #364ed4;
  border-radius: 3px;
  width: 30px;
  height: 30px;
  color: #fff;
  font-size: 22px;
  margin: 2px 0;
}
.tooltip-reccuring-info{
  position: absolute;
  top: calc(50% + 5px);
  left: calc(100% + 5px);
  transform: translateY(-50%);
  z-index: 10;
  background-color: #ffe4bd;
  min-width: 300px;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 14px;
}
.tooltip-reccuring-info::before{
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #ffe4bd;
  top: 50%;
  left: -3px;
  transform: translateY(-50%) rotate(45deg);
}
.modal__img-setting-inner .reactEasyCrop_CropArea{
  color: rgba(0, 0, 0, 0.3) !important;
}
.modal-info__nav{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -5px;
}
.modal-info__nav-col{
  width: 50%;
  flex: 0 0 auto;
  padding: 0 5px;
}
.event-form__row .field-wrap__members{
  width: 66.666667%;
  flex: 0 0 auto;
}
.event-form__row .tooltips-wrap__event{
  width: 33.333333%;
  flex: 0 0 auto;
}
.about-banner{
  padding-bottom: 100px;
  background-color: #fff;
  position: relative;
}
.about-banner__inner{
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  padding: 70px 0 215px 0;
}
.about-banner__content{
  margin: 0 auto;
}
.about-banner__headline{
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 15px;
  font-family: 'SF-Pro-Display-Black';
}
.about-banner__description{
  font-weight: 500;
  line-height: 1.5;
  padding-bottom: 18px;
  font-size: 16px;
}
.about-banner__img{
  max-width: calc(100% - 30px);
  height: auto;
  display: block;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}
@media(min-width: 768px){
  .about-banner__inner{
    padding: 100px 0 400px 0;
  }
  .about-banner__img{
    max-width: 970px;
  }
}
@media(min-width: 992px){
  .about-banner__content{
    max-width: 80%;
    text-align: center;
  }
  .about-banner__headline{
    font-size: 55px;
    margin-bottom: 15px;
  }
  .about-banner__description{
    font-size: 24px;
    padding-bottom: 40px;
  }
}
@media(max-width: 767px){
  .about-banner__btn{
    display: block;
    width: 100%;
  }
}
.about-info-wgt{
  background-color: #fff;
}
.about-info-wgts{
  padding-top: 55px;
}
.about-info-wgts__inner{
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.about-info-wgt{
  padding: 10px;
  width: 50%;
  flex: 0 0 auto;
}
.about-info-wgt__inner{
  padding: 15px 20px;
  border: 1px solid #ececec;
  border-radius: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  height: 100%;
  transition: 200ms all;
}
.about-info-wgt__inner:hover{
  box-shadow: 0px 10px 15px 0px rgba(0,0,0,0.05);
}
.about-info-wgt__content{
  flex: 1;
  order: 13;
}
.about-info-wgt__headline{
  font-size: 15px;
  font-weight: 600;
  padding-bottom: 8px;
  font-family: 'SF-Pro-Display-Semibold';
}
.about-info-wgt__description{
  line-height: 1.31;
  padding-bottom: 8px;
  display: none;
}
.about-info-wgt__icon-wrap{
  flex: 0 0 auto;
  order: 1;
  padding-bottom: 10px;
}
.about-info-wgt__icon{
  width: 28px;
  height: 28px;
  fill: #000;
  display: block;
}
.about-info-wgt__line{
  width: 100%;
  height: 4px;
  border-radius: 5px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}
.about-info-wgts__footer{
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
@media(min-width: 768px){
  .about-info-wgt__inner{
    flex-direction: row;
  }
  .about-info-wgt__icon-wrap{
    width: 18px;
    padding-bottom: 0;
  }
  .about-info-wgt__content{
    order: 1;
  }
  .about-info-wgt__icon-wrap{
    order: 13;
  }
  .about-info-wgt__icon{
    width: 18px;
    height: 18px;
  }
  .about-info-wgt__headline{
    font-size: 18px;
  }
  .about-info-wgt__description{
    display: block;
  }
}
@media(max-width: 767px){
  .about-info-wgt .main-link{
    display: none;
  }
  .about-info-wgts__footer .btn{
    width: 100%;
  }
}
@media(min-width: 992px){
  .about-info-wgt{
    width: 33.333333%;
  }
}
.about-info-services{
  padding: 55px 0;
  background-color: #fff;
}
.about-info-service-wgt{
  padding: 25px 0;
}
.about-info-service-wgt__inner{
  margin: 0 -15px;
}
.about-info-service-wgt__content,
.about-info-service-wgt__thumbnail{
  padding: 0 15px;
}
.about-info-service-wgt__thumbnail-mob{
  padding-bottom: 15px;
}
.about-info-service-wgt__thumbnail-mob img{
  display: block;
  max-width: 100%;
  height: auto;
}
.about-info-service-wgt__thumbnail{
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-info-service-wgt__thumbnail img{
  display: none;
  max-width: 100%;
  max-height: 500px;
}
.about-info-service-wgt__headline{
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  font-family: 'SF-Pro-Display-Black';
}
.about-info-service-wgt__description{
  font-size: 16px;
  line-height: 1.43;
  padding-bottom: 20px;
}
.about-info-service-wgt__description ul{
  list-style-type: "- ";
  padding-left: 15px;
}
.about-info-service-wgt__description ul li{
  padding-bottom: 10px;
}
.about-info-service-wgt__description a{
  color: #364ed4;
}
.about-info-service-wgt__description p {
  margin: 10px 0;
}
@media(min-width: 992px){
  .about-info-service-wgt__inner{
    display: flex;
    align-items: center;
  }
  .about-info-service-wgt__headline{
    font-size: 36px;
  }
  .about-info-service-wgt__description{
    font-size: 21px;
  }
  .about-info-service-wgt__content,
  .about-info-service-wgt__thumbnail{
    width: 50%;
    flex: 0 0 auto;
  }
  .about-info-service-wgt__thumbnail img{
    display: block;
  }
  .about-info-service-wgt__thumbnail-mob img{
    display: none;
  }
}
.main-btn-v2{
  display: inline-flex;
  align-items: stretch;
  background-color: #f0f2ff;
  border-radius: 5px;
  overflow: hidden;
}
.main-btn-v2__content{
  padding: 0 30px;
  color: #364ed4;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.main-btn-v2__icon{
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e7eaff;
}
.main-btn-v2__icon svg{
  width: 8px;
  height: 13px;
  display: block;
  stroke: #364ed4;
  fill: none;
}
.about-include-company.relative .about-include-company__inner{
  padding-top: 0;
  padding-bottom: 0;
}
.about-info-service-wgt__content.reverse{
  order: 13;
}
.about-info-service-wgt__thumbnail.reverse{
  order: 1;
}
.about-include-company{
  padding-bottom: 15px;
}
.about-include-company .wrapper{
  position: relative;
  padding-top: 50px;
}
.about-include-company.reverse .about-include-company__col-thumb{
  order: 13;
}
.about-include-company.reverse .about-include-company__col-content{
  order: 1;
}
.about-include-company__inner{
  background-color: #364ed4;
  padding: 15px;
  border-radius: 30px;
}
.about-include-company__thumbnail{
  max-width: 100%;
  height: auto;
  display: block;
  position: relative;
  top: -50px;
}
.about-include-company__content{
  position: relative;
  top: -15px;
}
.about-include-company__navs-col:first-child{
  padding-bottom: 10px;
}
@media(min-width: 768px){
  .about-include-company__navs{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -7.5;
  }
  .about-include-company__navs-col{
    width: 50%;
    flex: 0 0 auto;
    padding: 0 7.5px;
  }
  .about-include-company__navs-col:first-child{
    padding-bottom: 0;
  }
}
@media(max-width: 767px){
  .about-include-company__content .btn{
    width: 100%;
  }
}
.about-include-company__headline{
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 15px;
  font-family: 'SF-Pro-Display-Black';
}
.about-include-company__headline span{
  color: #ffdf00;
}
.about-include-company__description{
  font-size: 16px;
  font-weight: 500;
  line-height: 1.33;
  color: #fff;
  padding-bottom: 30px;
}
@media(min-width: 992px){
  .about-include-company{
    padding-bottom: 40px;
  }
  .about-include-company__inner{
    margin: 0 -30px;
    padding: 30px;
  }
  .about-include-company__col{
    padding: 0 15px;
  }
  .about-include-company__headline{
    font-size: 40px;
  }
  .about-include-company__description{
    font-size: 18px;
  }
  .about-include-company__inner{
    display: flex;
    align-items: center;
  }
  .about-include-company__col{
    width: 50%;
    flex: 0 0 auto;
  }
  .about-include-company__thumbnail{
    position: static;
    top: 0;
  }
  .about-include-company__col-content_absolute{
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .about-include-company__thumbnail_absolute{
    position: absolute;
    right: 0;
    bottom: -1px;
    top: auto;
    max-width: 50%;
  }
  .about-include-company__content{
    position: static;
    top: 0;
  }
}
.about-info-app__inner{
  background-color: #f5f5f5;
  border-radius: 30px;
  padding: 15px;
}
.about-info-app__inner_theme_dark{
    background-image: linear-gradient(to bottom, #141426 0%, #38384d);
}
.about-info-app__col-thumbnail{
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-info-app__headline{
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  font-family: 'SF-Pro-Display-Black';
}
.about-info-app__thumbnail{
  max-width: 325px;
  height: auto;
  display: none;
}
.about-info-app__thumbnail.about-info-app__thumbnail-about-app{
  max-width: 100%;
}
.about-info-app__list{
  list-style-type: none;
}
.about-info-app__list-item{
  padding: 10px 0;
}
.about-info-app__list-btn{
  background: none;
  border: none;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  display: flex;
  align-items: flex-start;
  text-align: left;
}
.about-info-app__list-btn-desctop{
  display: none;
}
@media(min-width: 992px){
  .about-info-app__list-btn-desctop{
    display: flex;
  }
  .about-info-app__list-btn-mob{
    display: none;
  }
}
.about-info-app__list-btn span{
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
  background-color: #dee0e1;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-info-app__list-btn-icon{
  width: 20px;
  height: 20px;
  fill: #808a8e;
  display: block;
}
.about-info-app__list-btn.active{
  color: #364ed4;
}
.about-info-app__list-btn.active span{
  background-color: #364ed4;
}
.about-info-app__list-btn.active .about-info-app__list-btn-icon{
  fill: #fff;
}
.about-info-app__footer{
  padding-top: 40px;
}
.about-info-app__list-item__thumbnail{
  max-width: 100%;
  max-height: 290px;
  display: block;
  margin: 0 auto;
}
.about-info-app__list-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}
.about-info-app__list-content.active {
  max-height: 9999px;
  transition: max-height 0.3s ease-out;
}
.swiper-bonuses-wgts{
  display: none;
}
@media(max-width: 767px){
  .about-info-app__footer .btn{
    width: 100%;
  }
}
@media(min-width: 992px){
  .swiper-bonuses-wgts{
    display: block;
  }
  .about-info-app__headline{
    font-size: 36px;
  }
  .about-info-app__inner{
    display: flex;
    align-items: center;
    padding: 30px 45px;
    margin: 0 -30px;
  }
  .about-info-app__col{
    width: 50%;
    flex: 0 0 auto;
  }
  .about-info-app__thumbnail{
    display: block;
  }
  .about-info-app__list-item-thumbnail-wrap{
    display: none;
  }
  .about-info-app__list-btn{
    font-size: 18px;
  }
}
.about-info-app__inner_theme_dark .about-info-app__headline{
  color: #fff;
}
.about-info-app__inner_theme_dark .about-info-app__list-btn span{
  background-color: #4d4d69;
}
.about-info-app__inner_theme_dark .about-info-app__list-btn .about-info-app__list-btn-icon{
  fill: #8282ba;
}
.about-info-app__inner_theme_dark .about-info-app__list-btn.active span{
  background-color: #fff;
}
.about-info-app__inner_theme_dark .about-info-app__list-btn.active .about-info-app__list-btn-icon{
  fill: #000;
}
.about-info-app__inner_theme_dark .about-info-app__list-btn.active{
  color: #fff;
}
.about-info-app__inner_theme_dark .about-info-app__list-btn{
  color: #9797af;
}
.service-banner__inner{
  padding-top: 60px;
  padding-bottom: 20px;
}
.service-banner__content{
  margin: 0 -15px;
}
.service-banner__col{
  padding: 0 15px;
}
.service-banner__headline-wrap{
  padding-bottom: 15px;
}
.service-banner__headline{
  font-size: 24px;
  font-weight: 900;
  line-height: 1.28;
  color: #000;
}
.service-banner__description-wrap{
  padding-bottom: 30px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}
.service-banner__img img{
  max-width: 100%;
  height: auto;
  display: block;
}
.breadcrumbs{
  padding: 10px 0;
  display: none;
}
.breadcrumbs__item{
  font-size: 14px;
  font-weight: 500;
  color: #000;
}
.breadcrumbs__sep{
  display: block;
  padding: 0 5px;
}
.breadcrumbs__sep-icon{
  width: 7px;
  height: 11px;
  display: block;
}
.service-banner__btn .btn{
  display: none;
}
.service-banner__btn-mob{
  padding-top: 22px;
}
.service-banner__btn-mob .btn{
  display: block;
  width: 100%;
}
@media(min-width: 768px){
  .service-banner__content{
    padding-top: 25px;
  }
}
@media(min-width: 992px){
  .service-banner__headline{
    font-size: 40px;
  }
  .service-banner__description-wrap{
    font-size: 24px;
  }
  .breadcrumbs{
    display: flex;
    align-items: center;
  }
  .service-banner__content{
    display: flex;
    align-items: center;
    padding-top: 45px;
  }
  .service-banner__col{
    width: 50%;
    flex: 0 0 auto;
  }
  .service-banner__inner{
    padding-top: 65px;
    padding-bottom: 125px;
  }
  .service-banner__btn .btn{
    display: inline-block;
  }
  .service-banner__btn-mob{
    display: none;
  }
}
.service-info{
  background-color: #fff;
}
.service-info-wgt{
  padding: 22px 0;
}
.service-info-wgt__inner{
  margin: 0 -15px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.service-info-wgt__col{
  padding: 0 15px;
}
.service-info-wgt__headline-wrap{
  padding-bottom: 20px;
}
.service-info-wgt__headline{
  font-size: 24px;
  font-weight: bold;
}
.service-info-wgt__description-wrap{
  font-size: 16px;
  line-height: 1.43;
  padding-bottom: 18px;
}
.service-info-wgt__image img{
  max-width: 100%;
  height: auto;
  display: block;
}
.service-info-wgt.reverse .service-info-wgt__col-image{
  order: 13;
}
.service-info-wgt.reverse .service-info-wgt__col-content{
  order: 1;
}
@media(max-width: 991px){
  .service-info-wgt .service-info-wgt__col-image{
    order: 13;
  }
  .service-info-wgt .service-info-wgt__col-content{
    order: 1;
  }
}
@media(min-width: 992px){
  .service-info-wgt{
    padding: 70px 0;
  }
  .service-info-wgt__inner{
    flex-direction: row;
  }
  .service-info-wgt__col{
    width: 50%;
    flex: 0 0 auto;
  }
  .service-info-wgt__headline{
    font-size: 36px;
  }
  .service-info-wgt__description-wrap{
    font-size: 21px;
    padding-bottom: 0;
  }
}
.service-questions__inner{
  background-color: #f7ebd6;
  padding: 20px 15px;
  border-radius: 15px;
}
.service-questions__headline-wrap{
  padding-bottom: 5px;
}
.service-questions__headline{
  font-size: 24px;
  font-weight: bold;
  line-height: 2;
}
.service-questions__description-wrap{
  font-size: 16px;
  font-weight: 500;
  line-height: 1.33;
  padding-bottom: 17px;
}
.service-questions__nav-col{
  padding-bottom: 13px;
}
.service-questions__nav-col:last-child{
  padding-bottom: 0;
}
.swiper-services-functions{
  cursor: move;
}
.section-service-functions{
  background-color: #fff;
  padding-top: 30px;
  padding-bottom: 15px;
}
.section-service-functions__headline-wrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}
.section-service-functions__headline-wrap button{
  background-color: #f5f5f5;
  border: none;
  padding: 0;
  margin-left: 8px;
  border-radius: 10px;
  padding: 5px 10px;
}
.section-service-functions-nav__icon{
  width: 30px;
  height: 13px;
  display: block;
  stroke: #000;
}
.section-service-functions__headline{
  font-size: 24px;
  font-weight: bold;
  line-height: 1.67;
}
.service-function-wgt__inner{
  background-color: #f5f5f5;
  padding: 14px 18px;
  border-radius: 10px;
}
.service-function-wgt__col{
  padding: 0 10px;
}
.service-function-wgt__headline-wrap{
  padding-bottom: 7px;
}
.service-function-wgt__headline{
  font-size: 21px;
  font-weight: bold;
  line-height: 1.33;
}
.service-function-wgt__excerpt{
  font-size: 16px;
  line-height: 1.31;
}
.service-function-wgt__thumb{
  display: none;
}
.service-function-wgt__thumb img{
  max-width: 100%;
  height: auto;
  display: block;
}
.service-function-wgt__thumb-mob img{
  max-width: 100%;
  height: auto;
  display: block;
}
.swiper-services-functions {
  overflow: hidden;
}
@media(max-width: 767px){
  .swiper-services-functions .swiper-slide {
    max-width: 300px !important;
  }
}
@media(min-width: 768px){
  .service-function-wgt__thumb-mob{
    display: none;
  }
  .service-function-wgt__thumb{
    display: block;
  }
}
@media(min-width: 992px){
  .service-questions__inner{
    display: flex;
    align-items: center;
  }
  .service-questions__col{
    width: 50%;
    flex: 0 0 auto;
    padding: 0 15px;
  }
  .service-questions__headline{
    font-size: 36px;
  }
  .service-questions__description-wrap{
    font-size: 21px;
    padding-bottom: 0;
  }
  .service-questions__nav{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  .service-questions__nav-col{
    width: 50%;
    flex: 0 0 auto;
    padding: 0 15px;
  }
  .service-function-wgt__inner{
    display: flex;
    align-items: center;
  }
  .service-function-wgt__col{
    width: 50%;
    flex: 0 0 auto;
    padding: 0 10px;
  }
}
.swiper-blog-wgts{
  overflow: hidden;
}
.section-service-notes{
  background-color: #fff;
  padding: 17px 0 30px 0;
  overflow: hidden;
}
.section-service-notes__inner{
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 0 -7px;
}
.section-service-notes__headline-wrap{
  padding-bottom: 10px;
}
.section-service-notes__headline{
  font-size: 24px;
  font-weight: bold;
  line-height: 1.67;
}
.service-note{
  padding: 0 7px;
}
.service-note__thumb{
  padding-bottom: 15px;
}
.service-note__thumb img{
  max-width: 100%;
  height: auto;
  display: block;
}
.service-note__headline{
  font-size: 16px;
  font-weight: normal;
  line-height: 1.31;
  color: #000;
}
@media(max-width: 767px){
  .swiper-notes-wgts .swiper-slide {
    max-width: 300px !important;
  }
}
.demo-modal{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999;
}
.demo-modal_active{
  display: block;
}
.demo-modal__overlay{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99997;
}
.demo-modal-content{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99998;
  background-color: #fff;
  overflow: auto;
  width: 100%;
}
.demo-modal-content__form{
  padding: 15px;
  display: flex;
  flex-direction: column;
}
.demo-modal-content__thumb{
  background-color: #364ed4;
  width: 100%;
  display: none;
}
.demo-modal-content__thumb img{
  width: 320px;
  height: 247px;
  display: block;
}
.demo-modal-content__info-content{
  width: 100%;
  height: 100%;
  padding: 15px;
  background-color: #fff;
}
.demo-modal-content__headline{
  font-size: 30px;
  font-weight: bold;
  line-height: 1.2;
  color: #000;
}
.demo-modal-content__description{
  font-size: 16px;
  line-height: 1.31;
  display: none;
}
.demo-modal-content__form-header{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.demo-modal-content__form-header .close-modal{
  display: none;
}
.demo-modal-content__form-headline{
  font-size: 21px;
  font-weight: bold;
  color: #000;
}
.demo-modal-content__headline-wrap{
  display: flex;
  justify-content: space-between;
}
.demo-modal-content__form-header{
  padding-bottom: 30px;
}
.demo-modal-wgt .field-wrap__label{
  color: #000;
}
@media(min-width: 992px){
  .demo-modal-wgt .field-wrap__label{
    color: #808A8E;
  }
  .demo-modal-content__headline-wrap{
    padding-bottom: 15px;
  }
  .demo-modal-content{
    border-radius: 11px;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
  }
  .demo-modal-content__info-content{
    padding: 30px;
  }
  .demo-modal-content__headline-wrap .close-modal{
    display: none;
  }
  .demo-modal-content__form-header .close-modal{
    display: block;
  }
  .demo-modal-content{
    width: 725px;
  }
  .demo-modal-content__inner{
    display: flex;
    align-items: stretch;
  }
  .demo-modal-content__info{
    width: 50%;
    flex: 0 0 auto;
  }
  .demo-modal-content__form{
    width: 50%;
    flex: 0 0 auto;
  }
  .demo-modal-content__thumb{
    display: block;
  }
  .demo-modal-content__info-content{
      background-color: #536ae6;
  }
  .demo-modal-content__headline{
    color: #fff;
  }
  .demo-modal-content__description{
    display: block;
  }
  .demo-modal-content__form{
    padding: 15px 30px;
  }
}
.fade-in-out-image {
  opacity: 1;
  transition: opacity 0.3s ease; /* Увеличьте или уменьшите значение времени (в секундах) для контроля времени затухания и появления */
}

.fade-in-out-image.fade-out {
  opacity: 0;
  visibility: hidden; /* Скрываем элемент, но сохраняем его место на странице */
}

.fade-in-out-image.fade-in {
  opacity: 1;
}
.swiper-dashboard-wgts,
.swiper-bonuses-wgts {
  width: 100%;
  overflow: hidden;
  max-height: 500px;
}
.swiper-dashboard-wgts{
  display: none;
}
@media(min-width: 992px){
 .swiper-dashboard-wgts{
   display: block;
 }
}
.swiper-dashboard-wgts .swiper-slide,
.swiper-bonuses-wgts .swiper-slide{
  text-align: center;
  font-size: 18px;
  background: none;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-dashboard-wgts .swiper-slide img,
.swiper-bonuses-wgts .swiper-slide img {
  max-height: 500px;
}

.infinite-scroll-container {
  overflow: hidden;
  width: 100%;
}

.infinite-scroll-wrapper {
  display: flex;
}

.infinite-scroll-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  will-change: transform; /* Оптимизация для анимации */
}

.infinite-scroll-list li {
  padding: 5px 15px; /* Можно регулировать отступы по вашему усмотрению */
  width: 80px; /* Явно задаем ширину элементов списка */
}

.infinite-scroll-list li img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.btn_size_large{
  padding: 20px 30px;
  font-size: 16px;
  font-weight: bold;
}
@media(min-width: 992px){
  .infinite-scroll-list li {
    padding: 25px; /* Можно регулировать отступы по вашему усмотрению */
    width: 150px; /* Явно задаем ширину элементов списка */
  }
  .infinite-scroll-list li img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
}

.home-possibilities__cards-left-top-left{
  transform: translate(-50px, -50px); /* Изменили значения смещения */
  opacity: 0;
}

.home-possibilities-cards-wgt_animation-left {
  animation: slide-in-left 0.3s ease forwards;
  transform: translate(-50px, -50px); /* Изменили значения смещения */
  opacity: 0;
}

.home-possibilities-cards-wgt_h_50_left{
  transform: translate(50px, -50px); /* Изменили значения смещения */
  opacity: 0;
}

.home-possibilities-cards-wgt_animation_right{
  transform: translate(50px, -50px); /* Изменили значения смещения */
  opacity: 0;
}
.home-possibilities-cards-wgt_animation-right {
  animation: slide-in 0.3s ease 0.4s forwards;
  transform: translate(50px, -50px); /* Изменили значения смещения */
  opacity: 0;
}

.home-possibilities-cards-wgt_bottom_35-left{
  animation: slide-in-left-bottom 0.5s ease 0.3s forwards;
  transform: translate(-50px, 50px); /* Изменили значения смещения */
  opacity: 0;
  width: 35%;
}

.home-possibilities-cards-wgt_h_50-left{
  animation: slide-in-left-top 0.7s ease 0.3s forwards;
  transform: translate(0, -50px); /* Изменили значения смещения */
  opacity: 0;
}


.home-possibilities-cards-wgt-v2{
  transform: translate(0, -50px); /* Изменили значения смещения */
  opacity: 0;
}
.home-possibilities-cards-wgt-left-v2{
  animation: slide-in-left-top-v2 0.7s ease 0.3s forwards;
  transform: translate(0, -50px); /* Изменили значения смещения */
  opacity: 0;
}


.home-possibilities-cards-wgt-v3{
  transform: translate(0, -50px); /* Изменили значения смещения */
  opacity: 0;
}
.home-possibilities-cards-wgt-left-v3{
  animation: slide-in-left-top-v3 0.7s ease 0.3s forwards;
  transform: translate(0, -50px); /* Изменили значения смещения */
  opacity: 0;
}

.home-possibilities-cards-wgt_bottom_20-v1{
  animation: slide-in-left-20-v1 0.7s ease 0.3s forwards;
  transform: translate(0, -50px); /* Изменили значения смещения */
  opacity: 0;
}
.home-possibilities-cards-wgt_bottom_25-v2{
  animation: slide-in-left-20-v2 0.7s ease 0.3s forwards;
  transform: translate(0, -50px); /* Изменили значения смещения */
  opacity: 0;
}
.home-possibilities-cards-wgt_bottom_20-v3{
  animation: slide-in-left-20-v3 1s ease 0.3s forwards;
  transform: translate(0, -50px); /* Изменили значения смещения */
  opacity: 0;
}

.home-possibilities-cards-wgt_bottom_20-v3 .home-possibilities-cards-wgt__content{
  padding-top: 15px;
}

.home-possibilities-cards-wgt-top-right{
  transform: translate(0, -50px); /* Изменили значения смещения */
  opacity: 0;
}
.home-possibilities-cards-wgt_top_right{
  animation: slide-in-left-top-right 1.2s ease 0.3s forwards;
  transform: translate(0, -50px); /* Изменили значения смещения */
  opacity: 0;
}
.home-possibilities__cards{
  opacity: 0;
}
.home-possibilities__cards-view{
  opacity: 1;
}
@keyframes slide-in {
  0% {
    transform: translate(50px, -50px); /* Изменили значения смещения */
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes slide-in-left {
  0% {
    transform: translate(-50px, -50px); /* Изменили значения смещения */
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes slide-in-left-bottom {
  0% {
    transform: translate(-50px, 50px); /* Изменили значения смещения */
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes slide-in-left-top {
  0% {
    transform: translate(0, -50px); /* Изменили значения смещения */
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes slide-in-left-top-v2 {
  0% {
    transform: translate(0, -50px); /* Изменили значения смещения */
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes slide-in-left-top-v3 {
  0% {
    transform: translate(0, -50px); /* Изменили значения смещения */
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes slide-in-left-20-v1 {
  0% {
    transform: translate(-20px, 50px); /* Изменили значения смещения */
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes slide-in-left-20-v2 {
  0% {
    transform: translate(0, 80px); /* Изменили значения смещения */
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes slide-in-left-20-v3 {
  0% {
    transform: translate(20px, 50px); /* Изменили значения смещения */
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes slide-in-left-top-right {
  0% {
    transform: translate(20px, 50px); /* Изменили значения смещения */
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
.text-center{
  text-align: center;
}
.demo-modal-success{
  background-color: #eaedf0;
  padding: 30px;
  border-radius: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.demo-modal-success__icon-wrap{
  padding-bottom: 15px;
  display: flex;
  justify-content: center;
}
.demo-modal-success__icon{
  width: 84px;
  height: 84px;
}
.demo-modal-success__headline{
  font-size: 24px;
  font-weight: bold;
  line-height: 1.25;
  text-align: center;
  color: #000;
  font-family: 'SF-Pro-Display-Black';
  padding-bottom: 20px;
}
.demo-modal-success__description{
  font-size: 16px;
  line-height: 1.38;
  text-align: center;
  color: #000;
  padding-bottom: 20px;
}
.contact-form-success{
  text-align: center;
  height: 100%;
}
.contact-form-success__inner{
  border-radius: 10px;
  background-color: #f0f3f5;
  height: 100%;
  padding: 100px;
}
.contact-form-success__icon-wrap{
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
}
.contact-form-success__icon{
  width: 84px;
  height: 84px;
}
.contact-form-success__headline{
  padding-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.25;
  text-align: center;
  color: #000;
  font-family: 'SF-Pro-Display-Black';
}
.contact-form-success__description{
  padding-bottom: 20px;
  font-size: 16px;
  line-height: 1.38;
  text-align: center;
  color: #000;
}
.blog-list{
  padding-top: 45px;
}
@media(min-width: 992px){
  .blog-list{
    padding-top: 74px;
  }
}
.blog-page-header__headline{
  font-size: 24px;
  margin-bottom: 15px;
}
.blog-page-header__inner{
  display: flex;
  justify-content: space-between;
}
.blog-page-header__list{
  list-style-type: none;
  display: none;
}
.blog-page-header__list li{
  padding-left: 5px;
}
.blog-page-header__list li button{
  display: inline-block;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.27;
  color: #00c059;
  text-transform: uppercase;
  font-family: 'SF-Pro-Display-Medium';
  border-radius: 5px;
  background-color: #e5faef;
  padding: 5px 8px;
  border: none;
}
.blog-page__inner{
  margin: 0 auto;
}
@media(min-width: 992px){
  .blog-page__inner{
    width: 75%;
  }
  .blog-page-header__list{
    display: flex;
    align-items: center;
  }
  .blog-page-content__row{
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  .blog-page-content__row .blog-other-wgt{
    width: 25%;
    flex: 0 0 auto;
    padding: 10px;
  }
  .blog-page-content__row_lg .blog-other-wgt{
    width: 50%;
  }
}
@media(min-width: 1440px){
  .blog-page__inner{
    width: 50%;
  }
}
.pagination-nav__blog{
  padding-top: 15px;
  padding-bottom: 0;
}
.blog-page-breadrumbs{
  text-align: center;
}
.blog-page-breadrumbs{
  padding-top: 50px;
  padding-bottom: 15px;
}
.breadcrumbs__blog-list{
  display: flex;
}
.breadcrumbs__blog{
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  flex-wrap: wrap;
}
.breadcrumbs__blog .breadcrumbs__active{
  color: #808a8e;
}
.blog-page-info__list{
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0 15px;
}
.blog-page-info__list li{
  font-size: 12px;
  line-height: 1.75;
  color: #808a8e;
  display: flex;
  align-items: center;
}
.blog-page-info__list li::after{
  content: "";
  width: 3px;
  height: 3px;
  background-color: #808a8e;
  border-radius: 50%;
  display: block;
  margin: 0 7px;
}
.blog-page-info__list li:last-child::after{
  display: none;
}
.blog-page-thumb{
  padding: 15px 0;
}
.blog-page-thumb__img{
  border-radius: 10px;
  width: 100%;
  height: auto;
  display: block;
}
.blog-page-content__headline{
  font-size: 24px;
  font-weight: bold;
  color: #000;
  font-family: 'SF-Pro-Display-Bold';
}
.blog-page-content__post{
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #000;
  padding-top: 15px;
  padding-bottom: 10px;
  border-bottom: 4px solid #eaedf0;
}
.blog-page-content__post h2{
  margin-top: 10px;
  margin-bottom: 10px;
}
.blog-page-content__post ul,
.blog-page-content__post ol{
  margin-left: 30px;
  margin-bottom: 15px;
}
.blog-page-content__post ul li,
.blog-page-content__post ol li{
  margin-left: 0 !important;
  font-size: 16px;
}
.blog-page-content__post p{
  font-size: 16px;
  margin-bottom: 20px;
  margin-left: 0 !important;
}
@media(min-width: 992px){
  .blog-page-content__headline{
    font-size: 36px;
  }
  .blog-page-content__post p{
    font-size: 18px;
  }
  .blog-page-content__post ul li,
  .blog-page-content__post ol li{
    font-size: 18px;
  }
  .blog-page-breadrumbs{
    padding-top: 74px;
    padding-bottom: 15px;
  }
  .breadcrumbs__blog{
    justify-content: center;
    padding-bottom: 0;
  }
  .blog-page-info__list{
    justify-content: center;
  }
}
.blog-page-others{
  padding-top: 50px;
}
.blog-page-others__headline{
  font-size: 24px;
  font-weight: bold;
  line-height: 1.67;
  color: #000;
  font-family: 'SF-Pro-Display-Bold';
}
.blog-other-wgt__inner{
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #e1e1e1;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.blog-other-wgt__thumb{
  max-width: 100%;
  height: auto;
  display: block;
}
.blog-other-wgt__info{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  line-height: 1.75;
  color: #808a8e;
}
.blog-other-wgt__content{
  padding: 5px 15px 15px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}
.blog-other-wgt__headline{
  font-size: 16px;
  line-height: 1.31;
  color: #000;
  margin-bottom: 10px;
}
.blog-other-wgt__label{
  display: inline-block;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.27;
  color: #00c059;
  text-transform: uppercase;
  font-family: 'SF-Pro-Display-Medium';
  border-radius: 5px;
  background-color: #e5faef;
  padding: 5px 8px;
}
@media(min-width: 992px){
  .breadcrumbs{
    padding: 30px 0;
  }
  .breadcrumbs__blog{
    padding-bottom: 0;
  }
}
.modal-add-user{
  position: relative;
}
.modal-add-user__content{
  position: absolute;
  top: calc(100% + 10px);
  left: -40px;
  z-index: 9999;
  background-color: #fff;
  display: none;
  min-width: 270px;
  max-height: 350px;
  border-radius: 10px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.16);
}
.modal-add-user__content .user-modal-wgt__info{
  padding: 4px 15px;
  cursor: pointer;
}
.modal-add-user__content .field-wrap__search-members-wrap{
  background-color: #fff;
  padding: 15px 15px 10px 15px;
  position: sticky;
  top: 0;
}
.modal-add-user__content .field-wrap__search-icon{
  margin-top: 0;
}
.modal-add-user__content .field-wrap{
  padding-bottom: 0;
}
.modal-add-user__content.active{
  display: block;
}
.modal-add-user__btn{
  background-color: #364ed4;
  border: none;
  border-radius: 50%;
  color: #fff;
  width: 21px;
  height: 20px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}
span.modal-add-user__icon{
  margin: 0;
}
.modal-add-user__icon svg{
  fill: #fff;
  width: 11px;
  height: 11px;
  display: block;
}
.cookies-accept{
  background-color: #f0f1f8;
  padding: 18px 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000;
}
.cookies-accept__inner{
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
.cookies-accept__info{
  width: 100%;
  flex: 0 0 auto;
}
.cookies-accept__nav{
  width: 100%;
  flex: 0 0 auto;
  padding-top: 15px;
}
.cookies-accept-nav{
  display: flex;
  align-items: center;
  list-style-type: none;
}
.cookies-accept-nav__item{
  width: 50%;
  flex: 0 0 auto;
  padding-left: 10px;
}
.cookies-accept-nav__item:first-child{
  padding-left: 0;
}
@media(min-width: 768px){
  .cookies-accept__inner{
    flex-direction: row;
  }
  .cookies-accept__info{
    width: 70%;
  }
  .cookies-accept__nav{
    width: 30%;
    padding-top: 0;
  }
}
.cookies-modal__overlay{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100000;
  background-color: rgba(44,47,63, 0.3);
}
.cookies-modal__inner{
  padding: 30px;
  background-color: #fff;
  border-radius: 11px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100001;
  width: 100%;
}
.cookies-modal__header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cookies-modal__headline{
  font-size: 18px;
  font-weight: bold;
  line-height: 1.17;
  color: #000;
}
.cookies-modal__content{
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
.cookies-modal__nav{
  width: 100%;
  flex: 0 0 auto;
  position: relative;
}
.cookies-modal-nav{
  display: none;
  list-style-type: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
}
.cookies-modal-nav.active{
  display: block;
}
.cookies-modal-nav__btn{
  padding: 10px 15px;
  border-left: none;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid #dedede;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  display: block;
  width: 100%;
  text-align: left;
  background-color: #fff;
}
.cookies-modal__info{
  width: 100%;
  flex: 0 0 auto;
  font-size: 16px;
  line-height: 1.5;
  color: #000;
  padding-top: 15px;
}
.cookies-modal__tab{
  display: none;
}
.cookies-modal__tab.active{
  display: block;
}
.cookies-modal__headline{
  font-size: 24px;
  font-weight: bold;
  color: #000;
  margin-bottom: 15px;
}
.cookies-modal__dropdown-btn{
  width: 100%;
  padding: 12px 15px;
  border-radius: 2px;
  border: 1px solid #e1e1e1;
  background-color: #fff;
  text-align: left;
  font-size: 16px;
  color: #000;
}
@media(min-width: 992px){
  .cookies-modal__inner{
    width: 920px;
  }
  .cookies-modal__content{
    flex-direction: row;
  }
  .cookies-modal__nav{
    padding-right: 15px;
    width: 35%;
  }
  .cookies-modal-nav__item{
    padding: 3px 0;
  }
  .cookies-modal-nav__btn{
    padding: 15px 20px;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    border-radius: 5px;
    display: block;
    width: 100%;
    text-align: left;
    border: 1px solid #cecece;
  }
  .cookies-modal__info{
    width: 65%;
    padding-top: 0;
    padding-left: 15px;
  }
  .cookies-modal-nav{
    display: block;
    position: static;
    bottom: auto;
    left: auto;
    width: auto;
    background: none;
    border: none;
  }
  .cookies-modal-nav__btn.active{
    background-color: #364ed4;
    border-color: #364ed4;
    color: #fff;
  }
  .cookies-modal__dropdown-btn{
    display: none;
  }
}
.filter-flex-start{
  margin-left: 0;
}
.field-wrap__input-contacts{
  display: flex;
  align-items: center;
}
.field-wrap__input-contacts-icon{
  width: 30px;
  height: 30px;
  display: block;
  margin-left: 15px;
}
.view-page-nav{
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 10px;
  top: 50px;
}
@media(min-width: 768px){
  .account-wgt.bonuses-info{
    position: relative;
  }
  .view-page-nav{
    top: 0;
    right: 0;
  }
}
@media(max-width: 767px){
  .account-wgt.bonuses-info .btn-edit{
    width: 36px;
    height: 36px;
  }
  .account-wgt.bonuses-info .btn-edit svg{
    width: 15px;
    height: 16px;
  }
}
.field-wrap.field-wrap__sep{
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 15px;
  padding-bottom: 20px;
}

.modal-img{
  position: fixed;
  z-index: 99997;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.modal-img__overlay{
  position: fixed;
  background-color: rgba(44,47,	63, 0.3);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99998;
}

.modal-img__inner{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  max-width: 680px;
}

.modal-img__thumb{
  z-index: 99999;
  max-width: 100%;
  height: auto;
  display: block;
}
.modal-img__button{
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: #fff;
  border: none;
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-img__icon{
  width: 17px;
  height: 17px;
  fill: #000;
  display: block;
}
.edit-info-label{
  color: #fb1717;
  font-size: 14px;
  padding: 5px 10px 0 10px;
}
.bonuses-info__wgts-info{
  display: flex;
  align-items: stretch;
}
.bonuses-info__wgt{
  width: 25%;
  flex: 0 0 auto;
}
.bonuses-info__wgt-label{
  color: #808a8e;
  font-size: 14px;
  line-height: 1.71;
}
.modal-reward-icons .modal-image-edit__preview{
  max-width: 170px;
  height: auto;
}
.modal-reward-icons .modal-image-edit__img{
  width: 25%;
}
.user-statistic__info-rewards{
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}
.user-statistic-rewards{
  width: 100px;
  flex: 0 0 auto;
}
.user-statistic-rewards__inner{
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}
.user-statistic-rewards__icon{
  max-width: 75%;
  height: auto;
  display: block;
  margin-bottom: 5px;
  margin: 0 auto;
}
.user-statistic-rewards__headline{
  font-size: 13px;
  color: #000;
  word-break: break-word;
}
.modal-reward-user__inner{
  display: flex;
  align-items: center;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 10px;
}
.modal-reward-user__avatar{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: block;
}
.modal-reward-user__name{
  padding-left: 15px;
  color: #000;
  font-size: 14px;
}
.modal-reward-user__dates{
  padding-top: 5px;
}
.modal-reward-user__submit{
  padding-top: 10px;
}
.team-table__rewards-photo-wrap{
  position: relative;
}
.team-table__rewards-photo{
  width: 32px;
  height: auto;
  display: block;
  margin-right: 10px;
}
.team-table__rewards-photo-hover-wrap{
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background-color: #f8f8f8;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.14);
  padding: 5px;
}
.team-table__rewards-photo-hover{
  max-width: 83px;
  height: auto;
  display: block;
}
.modal-reward-user__date{
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ededed;
  display: flex;
  align-items: center;
}
.modal-reward-user__date:last-child{
  border-bottom: none;
}
.modal-reward-user__date .tg-list-item{
  margin-left: auto;
}
.modal-reward-user__date-inner{
  display: flex;
  align-items: center;
}
.modal-reward-user__date-icon{
  width: 20px;
  height: 20px;
  fill: #808a8d;
  display: block;
  margin-left: 10px;
}
.account-content__header-add-button{
  padding-left: 20px;
}
.add-button{
  background-color: #364ed4;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  position: relative;
}
.add-button__icon{
  fill: #fff;
  width: 14px;
  height: 14px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.reward-date-tooltip{
  color: #364ed4;
}
.reward-date-tooltip{
  position: relative;
  display: inline-block;
}
.reward-date-tooltip__content{
  display: none;
  position: absolute;
  top: 0;
  left: calc(100% + 10px);
  z-index: 100;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px #eaedf0;
  background-color: #fff;
  padding: 5px 0;
  border-radius: 5px;
}
.reward-date-tooltip:hover .reward-date-tooltip__content{
  display: block;
}
.reward-date-tooltip__content-item{
  padding: 5px 10px;
  color: #000;
  display: flex;
  align-items: center;
}
.reward-date-tooltip__content-item_disabled{
  color: #808a8e;
}
.reward-date-tooltip__content-item-icon{
  width: 20px;
  height: 20px;
  fill: #808a8d;
  display: block;
  margin-left: 10px;
}
.empty-filter-content{
  padding-bottom: 20px;
}
.empty-filter-content__inner{
  background-color: #f1f2f6;
  padding: 20px;
  border-radius: 2px;
  text-align: center;
}
.empty-filter-content__headline{
  color: #808a8e;
  font-size: 14px;
  padding-bottom: 5px;
}
.empty-filter-content__restore{
  background: none;
  border: none;
  padding: 0;
  text-align: center;
  color: #364ed4;
}
.catalog-table__date{
  white-space: nowrap;
}
.modal-forgot-success{
  text-align: center;
}
.modal-forgot-success{
  text-align: center;
}
.modal-forgot-success__img-wrap{
  padding-bottom: 20px;
}
.modal-forgot-success__img{
  display: block;
  margin: 0 auto;
  width: 120px;
  height: auto;
}
.modal-forgot-success__headline{
  color: #000;
  font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
  padding-bottom: 5px;
}
.modal-forgot-success__mail{
  font-size: 16px;
  color: #364ed4;
}
.benefits-statistics{
  padding-left: 15px;
  padding-right: 15px;
}
.benefits-statistics__inner{
  padding: 7px 9px 7px 8px;
  border-radius: 5px;
  background-color: #edeef4;
  display: flex;
  align-items: stretch;
  margin-left: -3px;
  margin-right: -3px;
}
.benefits-statistics-wgt{
  width: 25%;
  flex: 0 0 auto;
  padding-left: 3px;
  padding-right: 3px;
}
.benefits-statistics-wgt__inner{
  background-color: #fff;
  padding: 4px 8px;
  border-radius: 2px;
}
.benefits-statistics-wgt__header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}
.benefits-statistics-wgt__headline{
  color: #808a8e;
  font-size: 14px;
}
.benefits-statistics-wgt__setting-link{
  background: none;
  border: none;
  padding: 0;
}
.benefits-statistics-wgt__setting-link-icon{
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
  display: block;
}
.benefits-statistics-wgt__content-value-wrap{
  display: flex;
  align-items: center;
}
.benefits-statistics-wgt__content{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.benefits-statistics-wgt__content-icon{
  margin-right: 10px;
  min-width: 18px;
  min-height: 18px;
  max-width: 18px;
  max-height: 18px;
  display: block;
}
.benefits-statistics-wgt__content-value{
  font-weight: bold;
  color: #000;
  font-size: 24px;
  display: flex;
  align-items: center;
}
.benefits-statistics-wgt__content-value_theme_yellow{
  color: #fcb100;
}
.benefits-statistics-wgt__content-headline{
  font-size: 12px;
  color: #808a8e;
}
.massages-table{
  width: 100%;
}
.massages-table th{
  text-align: left;
  font-size: 12px;
  color: #626e77;
  font-weight: 400;
}
.massages-table tr{
  position: relative;
}
.massages-table tr td{
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
.massages-table tr th, .massages-table tr td{
  padding: 10.5px 15px;
}
.massages-table tr{
  position: relative;
  cursor: pointer;
}
.massages-table tr::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 30px);
    height: 1px;
    background-color: #EDEDED;
  }
.massages-table__response{
  border: 1px solid #c9d3d8;
  border-radius: 3px;
  z-index: 10;
  padding: 15px 10px;
}
.massages-table__response-name{
  font-size: 13px;
  color: #808a8e;
}
.massages-table__response-txt{
  font-size: 14px;
  color: #000;
  word-break: break-all;
}
.massages-table tr.active td{
  padding-bottom: 0;
}
.massages-table tr.active::after {
  display: none;
}
.benefits-nav-wrapper__inner{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
}
.benefits-nav-wrapper__nav{
  display: flex;
  align-items: stretch;
}
.benefits-nav-wrapper__nav-item{
  padding-right: 8px;
}
.benefits-nav-wrapper{
  position: relative;
}
.history-balance-wrap__benefits{
  overflow-y: visible;
}
.date-modal-dropdown{
  position: absolute;
  top: 100%;
  left: 0;
  right: auto;
  transform: none;
}
.date-modal-dropdown .date-modal__content{
  position: absolute;
  top: calc(100% + 3px);
  left: 15px;
  right: auto;
  transform: none;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px #e1e1e1;
}
.calendar-field__col-once.calendar-field__col:last-child{
  padding-left: 0;
}
.calendar-field__col-once.calendar-field__col:last-child .calendar-field__input-calendar{
  padding-left: 0;
}
.calendar-field__col-once.calendar-field__col:first-child::after{
  display: none;
}
.btn-close-icon{
  width: 10px;
  height: 10px;
  display: block;
  fill: #364ed4;
  margin-left: 10px;
}
.current-filter{
  padding-left: 5px;
}
.current-filter__inner{
  background-color: #fff;
  border-radius: 5px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
}
.filter-current_new{
  color: #364ed4;
}
.filter-current_new .current-filter__inner{
  color: #364ed4;
}
.filter-current_in_progress .current-filter__inner{
  color: #d87000;
}
.filter-current_done .current-filter__inner{
  color: #009d45;
}
.filter-current_declined .current-filter__inner{
  color: #d01a36;
}
.current-filter__restore{
  width: 8px;
  height: 8px;
  display: block;
  fill: #808a8e;
  margin-left: 5px;
}
.super-coin-disabled{
  display: block;
  color: #808a8d;
  background-color: #e9edf0;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  margin-left: 10px;
}
.benefits-statistics-wgt__content-value-wrap_disabled .benefits-statistics-wgt__content-icon{
  opacity: 0.7;
}
.benefits-statistics-wgt__content-value-wrap_disabled .benefits-statistics-wgt__content-value_theme_yellow{
  color: #808a8d;
}
.modal.modal_pr_n .modal__order-content-history{
  padding: 0 0 15px 20px;
}
.cropper-wrapper-product{
  display: none;
}
.cropper-wrapper-product.active{
  display: block;
}
.modal-date-select__inner{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -5px;
  margin-right: -5px;
}
.modal-date-select__field{
  padding-left: 5px;
  padding-right: 5px;
  flex: 1;
}
.pagination__input{
  max-width: 30px;
  height: 30px;
  border: 1px solid #e1e3eb;
    border-radius: 5px;
    text-align: center;
}
